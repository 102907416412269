import config from '../config/config';

function getAuthHeader(auth) {
  const { accessToken } = auth;
  if (accessToken) {
    return {
      Authorization: `Bearer ${accessToken}`
    };
  }
}

function getAnonymousURL(auth, url) {
  const { isAuthenticated } = auth;
  if (isAuthenticated) {
    return url;
  } else {
    return `${config.api.anonymousUrl}`;
  }
}

function safeParse(item) {
  try {
    return JSON.parse(item);
  } catch (err) {
    return undefined;
  }
}

export { getAuthHeader, getAnonymousURL, safeParse };
