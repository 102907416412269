import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'cdk-radial';
// note to check and remove white space added before the string like name of api and org name
import MaterialIcon from '@material/react-material-icon';
// Load replaceAll shim as its not yet supported on IE
import replaceAll from 'string.prototype.replaceall';
import { AvatarWrapper, AVATAR_SIZES } from 'cdk-radial';
import { getIntitials } from '../../../../../src/utils/format-data';
import {
  Content,
  IconLockOutline,
  IconNetworkingSdwan,
  TextButton
} from 'cdk-radial';
import '@rmwc/tooltip/styles';
import { Tooltip } from '@rmwc/tooltip';
import { isEnabled } from '../../../../utils/feature-gate-utils';

const API_VISIBILITY = {
  PUBLIC: 'public',
  PRIVATE: 'private'
};

// Quick and dirty fix to supress markdown content(ex: '#') from displaying on the API cards in directory home page
function suppressMarkdown(input = '') {
  return replaceAll(input, '#', '');
}

function approvedTags(categories) {
  if (categories.length > 1) {
    let newCat = [...categories];
    return (
      <Tooltip content={getRemainingTags(newCat)}>
        <span>{`Categories: ${categories[0]} (+${categories.length -
          1})`}</span>
      </Tooltip>
    );
  } else if (categories.length === 1) {
    return <span>{`Categories: ${categories[0]}`}</span>;
  } else {
    return <span>Categories:</span>;
  }
}
function getRemainingTags(categories) {
  categories.shift();
  let tags = '';
  categories.map(t => {
    tags = tags + t + ', ';
  });
  return tags.slice(0, -2);
}
function subheadingTitle(name) {
  return (
    <Tooltip
      content={
        <div
          id="tooltip"
          style={{
            background: 'black',
            color: 'white'
          }}
        >
          {`By ${name}`}
        </div>
      }
    >
      <span id="category">{`By ${name}`}</span>
    </Tooltip>
  );
}

const ApiCard = props => {
  const {
    api: {
      id,
      name,
      description,
      visibility,
      kind,
      tags,
      summary,
      orgName,
      orgLogo
    },
    domainsToDisplay,
    entity
  } = props;
  const categories =
    domainsToDisplay &&
    domainsToDisplay.length &&
    domainsToDisplay
      .filter(approvedTag => tags.includes(approvedTag.id))
      .map(tag => tag.name);

  const getSummary = () => {
    if (!!summary) {
      return suppressMarkdown(summary);
    } else {
      return suppressMarkdown(description);
    }
  };

  return !name && (!summary || !description) ? null : (
    <>
      <Link to={kind === 'async-api' ? `/async-apis/${id}` : `/apis/${id}`}>
        <Card data-cy={`spec-card__${name}`} className="spec-card">
          <div className="top_div">
            <div className="api_card_logo">
              {!!orgLogo ? (
                <img src={orgLogo} className="api_org_logo" alt="logo" />
              ) : (
                <AvatarWrapper
                  style={{
                    width: '48px',
                    height: '48px',
                    display: 'inline-flex',
                    backgroundColor: '#5E2368'
                  }}
                  size={AVATAR_SIZES.STANDARD}
                >
                  <Content
                    style={{
                      color: 'white',
                      fontFamily: 'roboto',
                      fontWeight: '500',
                      fontize: '18px',
                      lineHeight: '24px'
                    }}
                  >
                    {getIntitials(orgName).toUpperCase()}
                  </Content>
                </AvatarWrapper>
              )}
              <div className="api_card_apiTitle_div">
                <div
                  className={name.length > 46 ? 'api_title_3' : 'api_title_2'}
                >
                  {name}
                </div>
                <div
                  className={
                    name.length > 46
                      ? 'api_card_sub_title_3'
                      : 'api_card_sub_title_2'
                  }
                >
                  {subheadingTitle(orgName)}
                </div>
              </div>
            </div>
            <div className="api_card_description">{getSummary()}</div>
            <div className="api_card__ruler" />
            <span className="api_category_tags">
              {approvedTags(categories)}
              <div className="api-category-misc">
                {visibility === API_VISIBILITY.PRIVATE && <IconLockOutline />}
              </div>
            </span>
          </div>
        </Card>
      </Link>

      {/* {!isEnabled(entity, 'newApiDirUI') && (
        <Link to={kind === 'async-api' ? `/async-apis/${id}` : `/apis/${id}`}>
          <Card data-cy={`spec-card__${name}`} className="spec-card">
            <div
              className="spec-card__title"
              data-cy={`spec-card__title__${name}`}
            >
              {name}
            </div>
            {visibility === API_VISIBILITY.PRIVATE && (
              <MaterialIcon className="spec-card__private-icon" icon="lock" />
            )}
            <div
              className="spec-card__desc"
              data-cy={`spec-card__description__${name}`}
            >
              {suppressMarkdown(description)}
            </div>
          </Card>
        </Link>
      )} */}
    </>
  );
};

export default ApiCard;
