import React, { useState, useEffect } from 'react';
import { SimpleAccordion } from 'cdk-radial';
import { TestSimulatorEndpointContent } from './TestSimulatorEndpointContent';
import { StyledWrapperBorderColor } from '../styledComponents';

export const TestSimulatorContent = ({ specContent, endpoint }) => {
  const [openPanels, setOpenPanels] = useState({});

  const handlePanelToggle = id => {
    const isOpen = openPanels[id];
    setOpenPanels({
      [id]: !isOpen
    });
  };

  useEffect(() => {
    const idx = specContent.findIndex(
      f => f.verb === endpoint.method && f.endpoint === endpoint.path
    );

    setOpenPanels({ [`${endpoint.method}-${idx}`]: true });
  }, []);

  const endpointItems = specContent.map((ep, index) => {
    const titleClass = `test-simul-verb method-${ep.verb}`;
    const panelClass = `accordion-${ep.verb.toLowerCase()}-panel`;
    return {
      title: <div className={titleClass}>{ep.verb?.toUpperCase()}</div>,
      renderAfterTitle: () => (
        <div className="accordion-desc">{ep.description}</div>
      ),
      id: `${ep.verb}-${index}`,
      panelClassName: panelClass,
      content: <TestSimulatorEndpointContent endpointContent={ep} />
    };
  });
  return (
    <StyledWrapperBorderColor>
      <SimpleAccordion
        items={endpointItems}
        onPanelToggle={handlePanelToggle}
        openPanels={openPanels}
      />
    </StyledWrapperBorderColor>
  );
};
