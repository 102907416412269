import React, { createContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const loginLocationKey = 'fortellis-login-location';

export const locationContext = createContext({});
const LocationContextProvider = locationContext.Provider;

// Handles routing the user back to previous location after login
export function LoginLocationProvider({ children }) {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // Route on load
    routeToPreviousLocation();
  }, []);

  function setCurrentLocation() {
    localStorage.setItem(loginLocationKey, location.pathname);
  }

  function routeToPreviousLocation() {
    const previousLocation = localStorage.getItem(loginLocationKey);
    if (previousLocation) {
      history.push(previousLocation);
      localStorage.removeItem(loginLocationKey);
    }
  }

  return (
    <LocationContextProvider
      value={{
        setCurrentLocation,
        routeToPreviousLocation
      }}
    >
      {children}
    </LocationContextProvider>
  );
}
