import React from 'react';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';

import '../api-explorer.scss';

const CodeHighlight = ({ language, children }) => {
  let fontSize = language === 'text' ? '15px' : '13px';
  return (
    <SyntaxHighlighter
      codeTagProps={{
        style: {
          fontFamily: 'Source Code Pro',
          fontSize: '14px',
          fontWeight: '500',
          letterSpacing: '0.82px',
          lineHeight: '18px',
          whiteSpace: 'pre',
          color: '#FFFFFF'
        }
      }}
      language={language || 'text'}
    >
      {children}
    </SyntaxHighlighter>
  );
};

export default CodeHighlight;
