import React from 'react';
import { withRouter } from 'react-router-dom';
// Components
// Config
import config from '../../config/config.js';
// Styles
import notfound from '../../assets/404.png';
import './notfound.scss';

function NotFound({ history }) {
  return (
    <div className="fdn-page__notfound">
      <div className="fdn-page__notfound-image">
        <img src={notfound} alt="404" />
        <div className="fdn-page__notfound-image-text">
          <h1>
            404 error <br />
            page not found
          </h1>
          <hr />
          <h2>we won't stop looking if you don't.</h2>
          {history.length > 2 ? (
            <button className="redirect" onClick={history.goBack}>
              <div className="redirect-icon">
                <i className="material-icons one">keyboard_arrow_left</i>
                <i className="material-icons two">keyboard_arrow_left</i>
              </div>
              Click back to keep looking
            </button>
          ) : (
            <a className="redirect" href={config.developer.url}>
              <div className="redirect-icon">
                <i className="material-icons one">keyboard_arrow_left</i>
                <i className="material-icons two">keyboard_arrow_left</i>
              </div>
              Click back to keep looking
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(NotFound);
