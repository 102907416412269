
const subscriptionId = {
  name: 'Subscription-Id',
  in: 'header',
  description: `The Fortellis Marketplace subscription identifier between a user entity and the app.`,
  required: true,
  type: 'string',
  default: 'test'
};

const authorization = {
  name: 'Authorization',
  in: 'header',
  description: `Provides authorization to access Fortellis APIs. For calling a Fortellis Provider, this must be an OAuth 2.0 token issued by the Fortellis authorization server.`,
  required: true,
  type: 'string',
  disabled: true,
  placeholder: 'Automatically set'
};

export { subscriptionId, authorization };
