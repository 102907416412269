import React, { useEffect } from 'react';
import remark from 'remark';
import remark2react from 'remark-react';
import './ApiDetails.scss';

const ApiOverview = ({ content }) => {
  //set scroll To top when component unmounts
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);
  return (
    <div className="api-description" data-cy="api-description">
      {content && content.length ? (
        remark()
          .use(remark2react, {
            remarkReactComponents: {
              p: DescriptionContent,
              a: StyledAnchor,
              h1: DescriptionHeader,
              h2: DescriptionHeader,
              h3: DescriptionHeader,
              h4: DescriptionHeader
            }
          })
          .processSync(content).contents
      ) : (
        <div className="api-description-message">
          The provider does not have any overview available at this time.
        </div>
      )}
    </div>
  );
};
const DescriptionHeader = props => (
  <h2 className="description-header">{props.children}</h2>
);
const DescriptionContent = props => (
  <p className="description-content">{props.children}</p>
);
const StyledAnchor = props => (
  <a className="styled-anchor" {...props}>
    {props.children}
  </a>
);

export default ApiOverview;
