import { types, INITIAL_STATE, emailMessages } from './constants';
import React from 'react';
import RedirectToLogin from '../common/SpecsNotAccessible';
import NotFound from '../../components/notfound';

export function combineReducers(reducers) {
  const keys = Object.keys(reducers).filter(key => {
    return typeof reducers[key] === 'function';
  });

  return function masterReducer(state = INITIAL_STATE, action) {
    if (action && action.type) {
      const newState = { ...state };
      keys.forEach(key => {
        const previousStateForKey = newState[key];
        const newStateForKey = reducers[key](previousStateForKey, action);
        if (previousStateForKey !== newStateForKey) {
          newState[key] = newStateForKey;
        }
      });
      return newState;
    }
    throw new Error('Action is invalid!');
  };
}

const apiInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case types.API_INFO_GET_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.API_INFO_GET_RECEIVE:
      if (action.response.httpStatus === 403) {
        return {
          ...state,
          isFetching: false,
          error: action.response.error + ' Please login.'
        };
      }
      if (action.response.error) {
        return {
          ...state,
          isFetching: false,
          error: <RedirectToLogin />
        };
      }
      if (
        action.oneToOneApiAnon &&
        action.response &&
        action.response.instances &&
        action.response.instances.length
      ) {
        return {
          ...state,
          data: Array.isArray(action.response)
            ? action.response[0]
            : action.response,
          isFetching: false,
          error: null,
          oneToOneApiAnon: true
        };
      }

      return {
        ...state,
        data: Array.isArray(action.response)
          ? action.response[0]
          : action.response,
        isFetching: false,
        error: null
      };
    case types.REDIRECT_TO_LOGIN:
      return {
        ...state,
        isFetching: false,
        error: <RedirectToLogin />
      };
    case types.API_INFO_GET_ERROR:
      if (action && action.error && action.error.httpStatus) {
        return {
          ...state,
          isFetching: false,
          error: <RedirectToLogin />
        };
      }
      if (action.error && action.error.code === 404) {
        return {
          ...state,
          isFetching: false,
          error: <NotFound />
        };
      }
      return {
        ...state,
        isFetching: false,
        error: <RedirectToLogin />
      };
    default:
      return state;
  }
};

const domainNameReducer = (state = {}, action) => {
  switch (action.type) {
    case types.DOMAIN_NAME_GET_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.DOMAIN_NAME_GET_RECEIVE:
      const data =
        action.response &&
        action.response.items &&
        action.response.items.length &&
        action.response.items[1];
      return {
        ...state,
        data: data && data.dimensions,
        isFetching: false
      };
    case types.DOMAIN_NAME_GET_ERROR:
      return {
        ...state,
        isFetching: false,
        error: 'An error occurred while fetching domain name'
      };
    default:
      return state;
  }
};

const sendEmailToProviderReducer = (state = {}, action) => {
  switch (action.type) {
    case types.SEND_EMAIL_TO_PROVIDER_GET_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.SEND_EMAIL_TO_PROVIDER_GET_RECEIVE:
      return {
        ...state,
        data: action.response,
        isFetching: false,
        message: emailMessages.EMAIL_SUCCESS
      };
    case types.SEND_EMAIL_TO_PROVIDER_GET_ERROR:
      return {
        ...state,
        isFetching: false,
        message: emailMessages.EMAIL_FAILURE
      };
    default:
      return state;
  }
};

const sendEmailToConsumerReducer = (state = {}, action) => {
  switch (action.type) {
    case types.SEND_EMAIL_TO_CONSUMER_GET_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.SEND_EMAIL_TO_CONSUMER_GET_RECEIVE:
      return {
        ...state,
        data: action.response,
        isFetching: false,
        message: emailMessages.EMAIL_SUCCESS
      };
    case types.SEND_EMAIL_TO_CONSUMER_GET_ERROR:
      return {
        ...state,
        isFetching: false,
        message: emailMessages.EMAIL_FAILURE
      };
    default:
      return state;
  }
};

export default combineReducers({
  apiInfo: apiInfoReducer,
  domainName: domainNameReducer,
  sendEmailToProvider: sendEmailToProviderReducer,
  sendEmailToConsumer: sendEmailToConsumerReducer
});
