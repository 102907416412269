import styled from '@emotion/styled';
import Button from '@material/react-button';
import TextField from '@material/react-text-field';
import MaterialIcon from '@material/react-material-icon';
import { Badge } from '@cdk-rds/badge';
import { Dialog } from 'cdk-radial';

const GET_PANEL = 'accordion-get-panel';
const POST_PANEL = 'accordion-post-panel';
const PATCH_PANEL = 'accordion-patch-panel';
const PUT_PANEL = 'accordion-put-panel';
const DELETE_PANEL = 'accordion-delete-panel';

const getMethodColor = method => {
  const methodColorMap = {
    get: '#E6EDF7;',
    post: '#E6F6E6',
    delete: '#FDE6E6',
    put: '#E6F6E6',
    unknown: '#E6EDF7'
  };
  return methodColorMap[method] || methodColorMap['unknown'];
};

const getApiMethodColor = method => {
  const methodColorMap = {
    get: '#E6EDF7;',
    post: '#E6F6E6',
    delete: '#FDE6E6',
    put: '#FB8C00;',
    unknown: '#E6EDF7'
  };
  return methodColorMap[method] || methodColorMap['unknown'];
};

const getMethodActiveStateColor = (method, isSelected) => {
  const methodColorMap = {
    get: '#E6EDF7',
    post: 'rgba(81,162,58,0.08)',
    delete: 'rgba(204,0,0,0.08)',
    put: 'rgba(251,140,0,0.08)',
    unknown: '#614a4a26',
    asyncApiSectionSelected: '#6b99c926'
  };
  if (isSelected) {
    if (method === 'asyncApiSpecSection')
      return methodColorMap['asyncApiSectionSelected'];
    return methodColorMap[method] || methodColorMap['unknown'];
  }
  if (method === 'asyncApiSpecSection') {
    return '#a59e9e26';
  }
  return `rgba(0,0,0,0.02)`;
};

const getApiMethodColor2 = method => {
  const methodColorMap = {
    get: '#074FAF;',
    post: '#09AD00',
    delete: '#EB0000',
    put: '#FB8C00;',
    unknown: '#E6EDF7'
  };
  return methodColorMap[method] || methodColorMap['unknown'];
};

//---------------------Endpoint list  ----------------------

export const ApiEndpoints = styled('div')`
  @media (min-width: 769px) {
    display: flex;
    max-width: 1200px;
    margin: 32px auto;
    margin-left: -2px;
  }
  @media (max-width: 768px) {
    display: block;
    margin: 2px 10px;
  }
`;

export const EndpointsList = styled('div')`
  @media (min-width: 769px) {
    min-width: 300px;
    max-width: 44px;
    margin-right: 48px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Endpoints = styled('div')`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

export const EndpointDetails = styled('div')`
border: 1px solid rgba(0, 0, 0, 0.12);

@media (min-width: 769px) {
  display: flex;
  max-width: 1800px;
  margin: 32px auto;
  margin-left: -2px;
  justify-content: space-between;
}
@media (max-width: 768px) {
  display: block;
  margin: 2px 10px;
`;

export const SpecSection = styled('div')`
  @media (min-width: 769px) {
    min-width: 75%;
    max-width: 75%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SpecSectionItem = styled('div')`
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
  margin: 12px;
  font-family: Raleway;
  text-transform: capitalize;
`;

export const EndpointListItem = styled('div')`
  height: 44px;
  min-height: 44px;
  :last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

export const EndpointListInfo = styled('div')`
  display: flex;
  cursor: pointer;
  width:300px;
  min-height: 44px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 0px;
  background-color: #F3F3F3;
  :hover {
    #E6F4FA;
  }
`;

export const EndpointListName = styled('div')`
  align-items: center;
  margin: 12px 12px 8px 0px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #151b25;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  white-space: pre;
`;

export const EndpointListMethod = styled('div')`
  height: 20px;
  width: 59px;
  min-width: 59px;
  border-radius: 2px;
  border-width: 2px;
  border-style: solid;
  margin: 10px 12px;
  color: #151b25;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  padding: 3px, 2px, 3px, 2px;
  blend: pass through;
  border-color: ${props => getApiMethodColor2(props.method)};

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  text-transform: capitalize;
  background-color: ${props => getMethodColor(props.method)};
`;

//--------------------- API Endpoint info ----------------------

export const EndpointHeader = styled('div')`
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  @media (min-width: 769px) {
    padding: 16px 26px;
    display: flex;
  }
  @media (max-width: 768px) {
    padding: 12px 12px;
  }
`;

export const DescriptionContainer = styled('div')`
  width: 768px;
  display: contents;
  @media (min-width: 769px) {
    margin-top: 32px;
    height: 32px;
    margin-bottom: 56px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ApiEndpointMethod = styled('div')`
  display: inline-flex;
  color: #074faf;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-transform: uppercase;
  margin-right: 5px;
  letter-spacing: 0.18px;
`;

export const ApiEndpointName = styled('div')`
  display: inline-flex;
  width: 680px;
  margin-top: 32px;
  color: #232323;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
`;

export const EndpointDescription = styled('div')`
  color: #151b25b3;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
  width: 738px;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }
`;

export const DescriptionPopoverContainer = styled('div')`
  color: rgba(0, 0, 0, 0.87);
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 9px 0px 1px;
  overflow: ${props => (props.isPopOver ? 'unset' : 'hidden')};
  position: relative;
  max-height: 48px;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;

  :before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;

export const DescriptionPopover = styled('div')`
  color: rgba(0, 0, 0, 0.87);
  opacity: 1;
  border-radius: 4px;
  padding: 16px;
  top: 24px;
  border-color: #074faf;
  background-color: white;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  position: absolute;
  text-align: left;
  z-index: 2;
  transition: opacity 211ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 141ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
`;

export const EndpointSection = styled('div')`
  margin-bottom: 4px;

  @media (min-width: 769px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  @media (max-width: 768px) {
    padding: 0px 12px 12px 12px;
  }
`;

export const EndpointDetail = styled('div')`
  width: 500px;
  height: 44px;
  border-radius: 4px;
  border: 1px solid #dcddde;
  background-color: #ffffff;
  padding: 0px;
  align-items: center;
  display: flex;
  margin-bottom: 4px;
  overflow: auto;
  margin-top: 8px;
`;

export const EndpointDetailTitle = styled('div')`
  color: #151b25;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background-color: rgba(107, 37, 116, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px, 16px, 12px, 16px;
  max-width: 150px;
  width: 150px;
  overflow: auto;
  height: 44px;
  gap: 4px;
`;

export const EndpointDetailContent = styled('div')`
  display: flex;
  align-items: center;
  padding-left: 24px;
  overflow: auto;
  color: #151b25;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const ParametersSection = styled('div')`
  margin-top: 6px;
`;

export const CodeBody = styled('div')`
  max-width: 772px;
  max-height: 668px;
  overflow: auto;
  background-color: #151B25;
  color: white;
  border-radius: 8px;
  padding: 10px;
  gap: 10px;
  margin-bottom: 32px;
}
  & pre {
    margin-top: 0px;
    background-color:#151B25!important;
  }
`;

export const AsyncApiSpecCodeBody = styled('div')`
  max-height: 800px;
  background-color:  rgba(0,0,0,0.87);
  color: white;
  border-radius: 4px;
  & pre {
    background-color: rgb(33, 33, 33)!important;
    white-space: revert;
  }
}
`;

export const TextSection = styled('div')`
  width: 722px;
  height: 36px;
  color: #FFFFFF;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  overflow: auto;
  background-color: #151B25;
  color: white;
  border-radius: 4px;
  padding: 8px;
  margin-top: 8px;
}
  & pre {
    width:385px;
    height:20px;
    margin-top:8px !important;
    margin-bottom:8px !important;
    margin-left:8px !important;
    margin: 0px;
    background-color:#151B25 !important;
  }
`;

export const URLSection = styled('div')`
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  margin-left: 8px !important;
  margin: 0px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

export const InfoSection = styled('div')`
  margin-top: 32px;
  margin-bottom: 32px;
`;

export const Title = styled('h2')`
  height: 32px;
  width: 100%;
  color: #151b25;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.18px;
  margin-top: 32px;
  margin-bottom: 8px;
`;

export const Subtitle = styled('h3')`
  height: 28px;

  color: rgba(21, 27, 37, 0.701961);
  font-family: Montserrat;
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.15px;
  margin-bottom: 8px;
  margin-top: 8px;
`;

//------------------ For schemaStructure -------------------

export const OuterSchemaContainer = styled('ul')`
  margin-left: -60px;
`;

export const newdiv = styled('div')`
  align-items: center;
  text-align: center;
`;

export const ButtonLayout = styled('div')`
  justify-content: center;
  align-items: center;
  padding: 9px 12px;
  gap: 8px;

  width: 97px;
  height: 36px;
  border-radius: 8px;

  margin-left: 40px;
  margin-bottom: 10px;
  button {
    margin: 4px;
    margin-left: -16px;
    margin-top: -2px;
    color: #5e2368 !important;
    background-color: #f0e9f1 !important;
    width: 103px;
  }
`;

export const SchemaContainer = styled('ul')`
  list-style: none;
  padding-left: 0px;
  margin-top: 5px;
  margin-left: 40px;
  border-left: 1px solid #ccc;
  overflow: visible;
`;

export const SchemaElement = styled('li')`
  list-style: none;
  margin: 0px 0px 8px 0px;
`;

export const ElementTitleContainer = styled('div')`
  margin-left: -5px;
`;

export const ElementLabel = styled('p')`
font-size: 14px;
font-weight: 700;
line-height: 24px;
//font-family: ${props => ''};
color: rgba(0,0,0,0.87);
display: inline;
margin-left: 20px;
`;

export const ElementType = styled('span')`
  color: rgba(0, 0, 0, 0.6);
  display: inline;
  margin-left: 5px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
`;

export const ElementRequired = styled('span')`
  color: #b00020;
  display: inline;
  margin-left: 5px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
`;

export const Description = styled('p')`
font-size: 14px;
margin: 2px 0px 0px 35px;
color: rgba(0,0,0,0.6);
//font-family: ${props => ''};
line-height: 1;
font-weight: 500;
`;

export const Decorative = styled('span')`
  margin-left: 40px;
  margin-right: 40px;
`;

export const ExpandToggleInline = styled('span')`
  display: inline;
  cursor: pointer;
  background-color: white;
  font-size: 20px;
  margin-left: 0px;
  visibility: ${props =>
    props.type === 'object' || props.type === 'array' ? 'visible' : 'hidden'};
`;

//------------------ Table ---------------------------

export const Paper = styled('div')`
  overflow: auto;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
`;

export const Table = styled('table')`
  border-collapse: collapse;
  width: 100%;
  overflow: auto;
`;

export const TableRow = styled(`tr`)`
  padding: 16px;
  line-height: 48px;
  :nth-child(even) {
    background-color: #f3f3f3;
  }
`;

export const TableHeader = styled('thead')``;

export const TableBody = styled('tbody')`
  padding-bottom: 4px;
  border-radius: 0px 0px 4px 4px;
`;

export const TableHeadItem = styled('th')`
  color: #151b25;
  font-family: Open Sans;
  font-size: 12px;
  padding: 12px 16px;
  font-weight: 600;
  line-height: 14px;
  background-color: #ffffff;
  text-align: left;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  box-shadow: inset 0px -2px 0px #151b25;
  :last-child {
    border-radius: 0px 4px 0px 0px;
  }
  :first-of-type {
    border-radius: 4px 0px 0px 0px;
  }
`;

export const TableItem = styled('td')`
  color: #151b25;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.15px;
  line-height: 20px;
  padding: 16px;
  text-align: left;
  max-width: 300px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
`;

//------------------ For TryItOut ---------------------------

export const FormField = styled(TextField)`
  label {
    font-family: Raleway, sans-serif !important;
  }
  input {
    font-family: Raleway, sans-serif !important;
  }
  div {
    font-family: Raleway, sans-serif !important;
  }
`;

export const Formcontrol = styled('div')`
  label {
    font-family: Raleway, sans-serif !important;
  }
  input {
    font-family: Raleway, sans-serif !important;
  }
  div {
    font-family: Raleway, sans-serif !important;
  }
`;

export const TryoutWrapper = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(112, 112, 112, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow: hidden;
`;

export const TryoutContainer = styled('div')`
  background-color: #fff;
  width: 80%;
  height: 92%;
  box-shadow: 0px 2px 20px #00000044;
  border-radius: 4px;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 50px 1fr 75px;
  overflow: hidden;
`;

export const TryoutHeader = styled('div')`
  padding: 0em 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
`;

export const TryoutCloseIcon = styled(MaterialIcon)`
  cursor: pointer;
`;

export const TryoutTitle = styled('h1')`
  font-size: 18px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
`;

export const TryoutBody = styled('div')`
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  padding: 0em 1em;
  @media screen and (max-width: 798px) {
    flex-direction: column;
  }
  @media all and (-ms-high-contrast: none) {
    height: 76vh;
  }
`;

export const TryoutParameters = styled('div')`
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
  padding: 1em 1em;
  width: 60%;
  box-sizing: border-box;
  border-right: 1px dashed #aaa;
  @media screen and (max-width: 798px) {
    width: auto;
    border-right: none;
    border-bottom: 1px dashed #aaa;
    margin-bottom: 20px;
  }
`;

export const BigInline = styled('span')`
  font-size: 24px;
  display: inline;
`;

export const SmallInline = styled('span')`
  font-size: 14px;
  vertical-align: top;
  display: inline;
`;

export const TryoutParametersSection = styled('div')`
  margin-bottom: 3em;
`;

export const TryoutSubtitle = styled('h3')`
  font-size: 18px;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  margin-top: 24px;
`;

export const Parameter = styled('div')`
  margin-top: 1.5em;
  margin-bottom: 2em;
`;

export const TryoutResults = styled('div')`
  overflow: auto;
  height: 100%;
  width: 100%;
  margin: 0.5em 2em 0em 2em;
  box-sizing: border-box;
  @media screen and (max-width: 798px) {
    margin: 0;
  }
`;

export const ResultSection = styled('div')`
  margin-bottom: 24px;
`;

export const TryoutError = styled('div')`
  background-color: #db444433;
  border-radius: 4px;
  color: $delete-color;
  padding: 0.5em 2em;
  text-align: center;
  margin-top: 10px;
  font-family: Raleway, sans-serif;
  margin-bottom: 10px;
`;

export const TryoutSuccess = styled('div')`
  background-color: #9fff80;
  border-radius: 4px;
  color: $delete-color;
  padding: 0.5em 2em;
  text-align: center;
  margin-top: 10px;
  font-family: Raleway, sans-serif;
  margin-bottom: 20px;
`;

export const TryoutFooter = styled('div')`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0em 1em;
  border-top: 1px solid #efefef;
`;

export const TryoutButton = styled(Button)`
  align-content: flex-end;
  padding: 0.75em 1em;
  margin-right: 1.5em;
  margin-left: 0.5em;
  height: 45px;
  margin-bottom: 1%;
  margin-top: 1%;
`;

export const TryoutReset = styled(Button)`
  background-color: transparent;
  :hover {
    background-color: #f6f0f4;
    box-shadow: none;
  }
  color: #904778;
  padding: 0.75em 1em;
  height: 45px;
  margin-bottom: 1%;
  margin-top: 1%;
`;
export const ApiHeaderDetails = styled.section`
  display: flex;
  flex-direction: column;
  div.header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ApiVersionSection = styled.div`
  align-self: center;
  & {
    span:first-child {
      color: white;
      font-size: 18px;
    }
    span:nth-child(2) {
      padding: 0 5px;
    }
  }
  .api-env-list {
    width: 200px;
    height: 48px;
    border: 1px;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    background-color: #ffffff;
    border-color: rgba(21, 27, 37, 0.5);
  }

  .api-version-list {
    width: 200px;
    height: 48px;
    border: 1px;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    background-color: #ffffff;
    border-color: rgba(21, 27, 37, 0.5);
    margin: 0 10px;
  }
`;

export const ApiReleaseType = styled.span`
  color: #fff;
  border-radius: 2px;
  font-size: 12px;
  padding: 4px 9px;
  &.general {
    background-color: green;
  }
`;

export const ProxyDetails = styled.section`
  & {
    span:first-child {
      color: #151b25;
      font-size: 18px;
    }
    span:nth-child(2) {
      color: #5e2368;
      font-family: 'Montserrat';
      font-weight: 600;
    }
  }
`;

export const ReleaseTag = styled(Badge)`
  span:first-child {
    color: #fff;
    padding: 2px 10px;
    font-size: 14px;
    margin-top: 12px;
  }
`;

export const StyledTd = styled.td`
  padding: 10px 0;
  vertical-align: top;
`;

export const StyledTh = styled.th`
  text-align: left;
  padding-bottom: 8px;
`;

export const StyledWrapperBorderColor = styled.div`
  .${GET_PANEL} {
    border: 2px solid #074faf;
    margin-bottom: 4px;
    > button {
      display: flex;
      justify-content: space-between;
      svg {
        margin-left: auto;
      }
    }
  }
  .${POST_PANEL} {
    border: 2px solid #09ad00;
    margin-bottom: 4px;
    > button {
      display: flex;
      justify-content: space-between;
      svg {
        margin-left: auto;
      }
    }
  }
  .${PATCH_PANEL} {
    border: 2px solid #8a8d92;
    margin-bottom: 4px;
    > button {
      display: flex;
      justify-content: space-between;
      svg {
        margin-left: auto;
      }
    }
  }
  .${PUT_PANEL} {
    border: 2px solid #fa9c19;
    margin-bottom: 4px;
    > button {
      display: flex;
      justify-content: space-between;
      svg {
        margin-left: auto;
      }
    }
  }
  .${DELETE_PANEL} {
    border: 2px solid #eb0000;
    margin-bottom: 4px;
    > button {
      display: flex;
      justify-content: space-between;
      svg {
        margin-left: auto;
      }
    }
  }
`;

export const StyledDialog = styled(Dialog)`
  & > div:nth-of-type(2) {
    padding-top: 0;
  }
`;
