import React, { useState, useEffect, useRef } from 'react';
import {
  IconArrowDropDown,
  IconArrowDropUp,
  Input,
  Menu,
  MenuListItem
} from 'cdk-radial';
import { Subtitle } from '../styledComponents';
import { STAUS_CODES } from './constants';
import '../api-explorer.scss';

export const ResponseSelection = ({
  activeHttpCode,
  responseStructs,
  setSelectedResponse
}) => {
  const [respOptions, setRespOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    label: '',
    value: ''
  });

  useEffect(() => {
    if (Array.isArray(responseStructs) && responseStructs.length > 0) {
      let resps = [];
      responseStructs.forEach(f => {
        resps = [...resps, { value: f.id, label: f.schema?.description }];
      });
      setRespOptions(resps);
      setSelectedOption(resps[0]);
      setSelectedResponse(resps[0].value);
    }
  }, [responseStructs]);

  const targetRef = useRef();
  const dimensionRef = useRef();
  const menuContainerRef = useRef();

  const handleOpen = _ => {
    setIsOpen(true);
  };

  const handleClose = _ => {
    setIsOpen(false);
  };

  const handleSelect = option => {
    setSelectedOption(option);
    setIsOpen(false);
    setSelectedResponse(option.value);
  };

  useEffect(() => {
    const setIsOpenFromMenu = event => {
      if (event.detail.ref === menuContainerRef)
        setIsOpen(event.detail.openMenu);
    };
    document.addEventListener('customMenuOpenEvent', setIsOpenFromMenu);
    return () =>
      document.removeEventListener('customMenuOpenEvent', setIsOpenFromMenu);
  }, []);

  return (
    <div className="response-selector-wrapper">
      <div className="response-selector-active-code">
        <Subtitle>
          {activeHttpCode} - {STAUS_CODES[activeHttpCode]}
        </Subtitle>
      </div>
      {responseStructs.length > 0 && (
        <>
          <div
            data-testid="single-select-dropdown"
            className="response-selector-width"
          >
            <Input
              dataTestId="menu-input"
              helperText=""
              icon={isOpen ? <IconArrowDropDown /> : <IconArrowDropUp />}
              id="single-select-dropdown"
              label="Select Response"
              labelRef={dimensionRef}
              name="single-select-dropdown"
              onChange={() => {}}
              placeholder="Select"
              ref={targetRef}
              value={selectedOption.label}
              style={{ caretColor: 'transparent' }}
            />
          </div>
          <Menu
            dataTestId="menu-container"
            dimensionRef={dimensionRef}
            isOpen={isOpen}
            labelRef={targetRef}
            onClose={handleClose}
            onOpen={handleOpen}
            isAuto={false}
            ref={menuContainerRef}
            style={{ maxWidth: 'fit-content' }}
          >
            {respOptions.map((option, ind) => (
              <div className="response-selector-width">
                <MenuListItem
                  dataTestId={`menu-item-${ind}`}
                  isDisabled={option.disabled}
                  isSelected={option.value === selectedOption.value}
                  key={ind}
                  onClick={() => handleSelect(option)}
                  hideSelectedItemCheckmark={false}
                >
                  {option.label}
                </MenuListItem>
              </div>
            ))}
          </Menu>
        </>
      )}
    </div>
  );
};
