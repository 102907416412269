import React from 'react';
import { Link } from 'react-router-dom';
import { Card, PrimaryButton } from 'cdk-radial';
import replaceAll from 'string.prototype.replaceall';
import { AvatarWrapper, AVATAR_SIZES } from 'cdk-radial';
import { getIntitials } from '../../../../../src/utils/format-data';
import { Content } from 'cdk-radial';
import '@rmwc/tooltip/styles';
import { isEnabled } from '../../../../utils/feature-gate-utils';
import config from '../../../../config/config';

function suppressMarkdown(input = '') {
  return replaceAll(input, '#', '');
}

const BundleCard = props => {
  const {
    apiBundle: { apis, name, description },
    entity
  } = props;

  const getDescription = () => {
    if (!!description) {
      return suppressMarkdown(description);
    }
  };
  let setBundleApis = '';
  if (apis) {
    apis.forEach((api, index) => {
      setBundleApis += api.id;
      if (apis.length - 1 !== index) {
        setBundleApis += ',';
      }
    });
  }

  return !name && !description && !apis ? null : (
    <>
      <Card
        data-cy={`spec-card__${name}`}
        className="bundle_spec-card"
        dataTestId="bundle_card"
      >
        <div className="bundle_card_top_div">
          <div className="bundle_card_logo">
            <AvatarWrapper
              style={{
                width: '48px',
                height: '48px',
                left: '16px',
                top: '16px',
                backgroundColor: '#5E2368',
                dataTestId: 'bundle_avtar'
              }}
              size={AVATAR_SIZES.STANDARD}
            >
              <Content
                style={{
                  color: 'white',
                  fontFamily: 'roboto',
                  fontWeight: '500',
                  fontize: '18px',
                  lineHeight: '24px'
                }}
                dataTestId="bundle_descryption"
              >
                {getIntitials(name).toUpperCase()}
              </Content>
            </AvatarWrapper>

            <div
              className={name.length > 46 ? 'bundle_title_3' : 'bundle_title_2'}
            >
              {name}
            </div>
          </div>
          <div className="bundle_card_description">{getDescription()}</div>
          <div className="list_apis">Included APIs ({apis.length})</div>
        </div>
        <div
          className={`bundle_card_middle_div ${
            apis.length > 3 ? 'background' : ''
          }`}
        >
          <div
            className={
              apis.length > 3 ? 'bundle_apis_scroll_gray' : 'bundle_apis_scroll'
            }
          >
            {apis.map(function(api, idx) {
              return (
                <div className="api-font">
                  {api.type === 'api' ? (
                    <p>
                      <Link to={`/apis/${api.id}`}> {api.name}</Link>
                      <br />
                    </p>
                  ) : (
                    <p>
                      <Link to={`/async-apis/${api.id}`}> {api.name}</Link>
                      <br />
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="bundle_card_bottom_div">
          <div className="bundle_card__ruler" />
          <div style={{ marginLeft: 'auto' }}>
            <a
              href={`${config.developer.url}developer-account/solutions/register?api=${setBundleApis}`}
            >
              <PrimaryButton
                dataTestId="build_with_bundle"
                iconColor="primary"
                text="Build Now"
              />
            </a>
          </div>
        </div>
      </Card>
    </>
  );
};

export default BundleCard;
