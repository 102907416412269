const types = {
  API_INFO_GET_REQUEST: 'API_INFO_GET_REQUEST',
  API_INFO_GET_RECEIVE: 'API_INFO_GET_RECEIVE',
  API_INFO_GET_ERROR: 'API_INFO_GET_ERROR',
  ORG_NAME_GET_REQUEST: 'ORG_NAME_GET_REQUEST',
  ORG_NAME_GET_RECEIVE: 'ORG_NAME_GET_RECEIVE',
  ORG_NAME_GET_ERROR: 'ORG_NAME_GET_ERROR',
  DOMAIN_NAME_GET_REQUEST: 'DOMAIN_NAME_GET_REQUEST',
  DOMAIN_NAME_GET_RECEIVE: 'DOMAIN_NAME_GET_RECEIVE',
  DOMAIN_NAME_GET_ERROR: 'DOMAIN_NAME_GET_ERROR',

  RATE_PLANS_GET_REQUEST: 'RATE_PLANS_GET_REQUEST',
  RATE_PLANS_GET_RECEIVE: 'RATE_PLANS_GET_RECEIVE',
  RATE_PLANS_GET_ERROR: 'RATE_PLANS_GET_ERROR',
  REDIRECT_TO_LOGIN: 'REDIRECT_TO_LOGIN',
  SEND_EMAIL_TO_PROVIDER_GET_REQUEST: 'SEND_EMAIL_TO_PROVIDER_GET_REQUEST',
  SEND_EMAIL_TO_PROVIDER_GET_RECEIVE: 'SEND_EMAIL_TO_PROVIDER_GET_RECEIVE',
  SEND_EMAIL_TO_PROVIDER_GET_ERROR: 'SEND_EMAIL_TO_PROVIDER_GET_ERROR',
  SEND_EMAIL_TO_CONSUMER_GET_REQUEST: 'SEND_EMAIL_TO_CONSUMER_GET_REQUEST',
  SEND_EMAIL_TO_CONSUMER_GET_RECEIVE: 'SEND_EMAIL_TO_CONSUMER_GET_RECEIVE',
  SEND_EMAIL_TO_CONSUMER_GET_ERROR: 'SEND_EMAIL_TO_CONSUMER_GET_ERROR'
};
const emailMessages = {
  EMAIL_SUCCESS: 'Message Sent.',
  EMAIL_FAILURE: 'Message Could not be sent.'
};
const INITIAL_STATE = {
  apiInfo: {
    data: null,
    isFetching: false,
    oneToOneApiAnon: false,
    error: ''
  },
  orgName: {
    data: null,
    isFetching: false,
    error: ''
  },
  sendEmail: {
    data: null,
    isFetching: false,
    error: ''
  }
};
export { types, INITIAL_STATE, emailMessages };
