import React from 'react';
import config from '../../../../config/config';
import { SecondaryButton } from 'cdk-radial';

export const ApiPageTopSection = ({ entity }) => {
  return (
    <>
      <div className="api-search-top-banner">
        <div className="top-banner-info-wrapper">
          <div className="top-banner-info-line-1">Build, Test, and Publish</div>
          <div className="top-banner-info-line-2">Next-generation APIs</div>
          <div className="top-banner-button-wrapper">
            {!entity && (
              <a href={config.signup.url}>
                <SecondaryButton
                  className="top-banner-button"
                  text="Register Today"
                />
              </a>
            )}
            {!!entity && (
              <a
                href={`${config.developer.url}developer-account/apis/register`}
              >
                <SecondaryButton
                  className="top-banner-button top-banner-right-button"
                  text="Add a New API"
                />
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
