const types = {
  GET_DOMAINS_REQUEST: 'GET_DOMAINS_REQUEST',
  GET_DOMAINS_RECEIVE: 'GET_DOMAINS_RECEIVE',
  GET_DOMAINS_ERROR: 'GET_DOMAINS_ERROR',

  GET_APIS_REQUEST: 'GET_APIS_REQUEST',
  GET_APIS_RECEIVE: 'GET_APIS_RECEIVE',
  GET_APIS_ERROR: 'GET_APIS_ERROR',
  GET_API_PAGES_REQUEST: 'GET_API_PAGES_REQUEST',
  GET_API_PAGES_RECEIVE: 'GET_APIS_PAGES_RECEIVE',
  GET_API_PAGES_ERROR: 'GET_API_PAGES_ERROR',

  GET_ASYNC_APIS_REQUEST: 'GET_ASYNC_APIS_REQUEST',
  GET_ASYNC_APIS_RECEIVE: 'GET_ASYNC_APIS_RECEIVE',
  GET_ASYNC_APIS_ERROR: 'GET_ASYNC_APIS_ERROR',
  GET_ASYNC_API_PAGES_REQUEST: 'GET_ASYNC_API_PAGES_REQUEST',
  GET_ASYNC_API_PAGES_RECEIVE: 'GET_ASYNC_APIS_PAGES_RECEIVE',
  GET_ASYNC_API_PAGES_ERROR: 'GET_ASYNC_API_PAGES_ERROR'
};
const INITIAL_STATE = {
  domains: {
    data: [],
    loading: false
  },
  apis: {
    data: [],
    loading: false
  },
  asyncApis: {
    data: [],
    loading: false
  }
};
const API_TYPES_DROPDOWN_OPTIONS = [
  {
    label: 'REST APIs',
    value: 'REST APIs'
  },
  {
    label: 'Async APIs',
    value: 'Async APIs'
  },
  {
    label: 'Bundles',
    value: 'Bundles'
  }
];
const API_TYPES_DROPDOWN_OPTIONS_OLD = [
  {
    label: 'REST APIs',
    value: 'REST APIs'
  },
  {
    label: 'Async APIs',
    value: 'Async APIs'
  }
];
const PAGINATION = {
  LIMIT: 12
};
const PAGINATION_BUNDLE = {
  LIMIT: 2,
  EXTENDED_LIMIT: 10
};
const API_TYPES = {
  REST: 'rest-api',
  ASYNC: 'async-api'
};

const ERROR_STATEMENT =
  'The following Error(s) needs to be fixed to get a response:';

const TEST_SIMULATOR_SUB_HEADER =
  "This tool lets you review what's needed to make calls to this API using examples from the API Specification to show the responses you can expect to see from a request. Random values are generated for all input parameters and reflect the type of the value expected by the API service. If needed by a method, a request body is provided from the specification. ";

export {
  types,
  INITIAL_STATE,
  API_TYPES_DROPDOWN_OPTIONS,
  API_TYPES_DROPDOWN_OPTIONS_OLD,
  PAGINATION,
  PAGINATION_BUNDLE,
  API_TYPES,
  ERROR_STATEMENT,
  TEST_SIMULATOR_SUB_HEADER
};
