import React, { Component } from 'react';
import { createBrowserHistory } from 'history';

// Components
import ApiList from './ApiList';
import BundleList from './BundleList';
import { ApiSearchGroup } from './ApiSearchGroup';
import SearchResult from './SearchResult';
import '../api-directory.scss';

//Library Components
import { CircularProgress } from '@fortellis/circular-progress';
import { EmptyArea } from '@fortellis/empty-area';
import { Flex } from '@fortellis/flex';
import MaterialIcon from '@material/react-material-icon';

// Variables
import config from '../../../../config/config';
import {
  API_TYPES_DROPDOWN_OPTIONS,
  API_TYPES_DROPDOWN_OPTIONS_OLD,
  PAGINATION,
  PAGINATION_BUNDLE
} from '../../constants';

import {
  SecondaryButton,
  PrimaryButton,
  SearchInput,
  FilterChips,
  ChoiceChips,
  INPUT_SIZES
} from 'cdk-radial';

//Utils
import { isEnabled } from '../../../../utils/feature-gate-utils';

import Fuse from 'fuse.js';

class ApiSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: this.props.searchString || '',
      selectedAPIType: ['Async APIs', 'REST APIs'],
      selectedDomainIds: '',
      chipDomainOptions: [],
      visible: PAGINATION.LIMIT,
      visibleBundle: PAGINATION_BUNDLE.LIMIT,
      isPaginationRequired: true,
      selectedOrgs: [],
      entity: {},
      apiBundles: [],
      advanceSearch: true
    };
    this.loadMore = this.loadMore.bind(this);
    this.loadMoreBundle = this.loadMoreBundle.bind(this);
  }

  loadMore() {
    this.setState(prev => {
      return { visible: prev.visible + PAGINATION.LIMIT };
    });
  }
  loadMoreBundle() {
    const paginationLimit =
      Array.isArray(this.state.selectedAPIType) &&
      this.state.selectedAPIType.indexOf('Bundles') > -1 &&
      this.state.selectedAPIType.length === 1
        ? PAGINATION_BUNDLE.EXTENDED_LIMIT
        : PAGINATION_BUNDLE.LIMIT;

    this.setState(prev => {
      return { visibleBundle: prev.visibleBundle + paginationLimit };
    });
  }

  onEnhancedChange = (event, domainId = '', orgs = []) => {
    const paginationLimit =
      Array.isArray(event) &&
      event.length === 1 &&
      event.indexOf('Bundles') > -1
        ? PAGINATION_BUNDLE.EXTENDED_LIMIT
        : PAGINATION_BUNDLE.LIMIT;

    this.setState({
      selectedAPIType: event,
      selectedDomainIds: domainId,
      visible: PAGINATION.LIMIT,
      visibleBundle: paginationLimit,
      isPaginationRequired: true,
      selectedOrgs: orgs
    });
  };

  handleSetSearchFilters = (searchFilters, source = '') => {
    let selectedApiTypes = [];
    let locOrgs = [];
    let locCategories = [];

    if (source === 'searchText') {
      selectedApiTypes = searchFilters['apiType'];
      locCategories = searchFilters['category'];
      locOrgs = searchFilters['org'];
    } else {
      if (!!searchFilters.category) {
        Object.keys(searchFilters.category).forEach(f => {
          searchFilters.category[f] && locCategories.push(f);
        });
      }

      if (!!searchFilters.apiType) {
        const apiTypeSet = {
          restApi: 'REST APIs',
          asyncApi: 'Async APIs',
          bundles: 'Bundles'
        };
        Object.keys(searchFilters.apiType).forEach(f => {
          searchFilters.apiType[f] && selectedApiTypes.push(apiTypeSet[f]);
        });
      }
      if (!!searchFilters.org) {
        Object.keys(searchFilters.org).forEach(f => {
          searchFilters.org[f] && locOrgs.push(f);
        });
      }
    }
    this.onEnhancedChange(selectedApiTypes, locCategories, locOrgs);
  };

  // handleChipSelection = (apiType = [], category = [], org = []) => {
  //   this.onEnhancedChange(apiType, category, org);
  // };

  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.selectedDomainIds &&
      this.props.location.state.selectedAPIType &&
      this.props.location.state.selectedOrgs
    ) {
      this.setState({
        selectedAPIType: this.props.location.state.selectedAPIType,
        selectedDomainIds: this.props.location.state.selectedDomainIds,
        selectedOrgs: this.props.location.state.selectedOrgs
      });
      // reset filters on page refresh
      const history = createBrowserHistory();
      if (
        history.location.state &&
        (history.location.state.selectedOrgs ||
          history.location.state.selectedDomainIds)
      ) {
        let state = { ...history.location.state };
        delete state.selectedOrgs;
        delete state.selectedDomainIds;
        delete state.selectedAPIType;
        history.replace({ ...history.location, state });
      }
    }
  }

  componentDidUpdate(prevProps) {
    let domainsToDisplay;
    if (this.props.domains.items) {
      domainsToDisplay = this.props.domains.items[1].dimensions
        .filter(domain => domain.name)
        .sort(function(a, b) {
          //sorting domain list by display name
          let nameA = a.name.toUpperCase(); // ignore upper and lowercase
          let nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
    }
    // Typical usage (don't forget to compare props):
    if (this.props.domains !== prevProps.domains) {
      let options = [];
      let allApis = Array.isArray(prevProps.apis)
        ? prevProps.apis.concat(prevProps.asyncApis)
        : prevProps.asyncApis;
      options.push(
        domainsToDisplay.map(element => {
          let obj = {
            label: element.name,
            value: element.name,
            isDisabled:
              element.visibility === 'private' ||
              allApis.some(e => e.domain === element.name)
                ? true
                : false,
            name: element.name
          };
          return obj;
        })
      );

      const mergeOptions = options.flat(1).sort(function(a, b) {
        //sorting domain list by display name
        let nameA = a.value.toUpperCase(); // ignore upper and lowercase
        let nameB = b.value.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      if (options.length) {
        this.setState({
          chipDomainOptions: mergeOptions
        });
      }
    }

    if (!!this.props.entity && Object.keys(this.state.entity).length === 0) {
      this.setState({ entity: this.props.entity });
    }

    if (
      this.state.apiBundles.length === 0 &&
      this.props?.apis?.bundles &&
      Array.isArray(this.props.apis.bundles) &&
      this.props.apis.bundles.length !== 0
    ) {
      this.setState({ apiBundles: this.props.apiBundles });
    }
  }

  render() {
    const { selectedAPIType, selectedDomainIds, selectedOrgs } = this.state;
    const {
      apisLoading,
      apis,
      apiBundles,
      asyncApis,
      asyncApisLoading,
      domains,
      domainsLoading
    } = this.props;
    let filteredApis = [];
    let filteredApiBundles = [];

    let apisToDisplay = apis && apis.length ? apis : [];
    let asyncApisToDisplay = asyncApis && asyncApis.length ? asyncApis : [];
    let apiBundlesToDisplay = apiBundles && apiBundles.length ? apiBundles : [];
    //edge condition
    let apisToDisplayNew = apis && apis.length ? apis : [];
    let asyncApisToDisplayNew = asyncApis && asyncApis.length ? asyncApis : [];

    const fuzzy = this.state.advanceSearch; // fuzzy set true
    const optionsApi = {
      isCaseSensitive: false,
      includeScore: true,
      shouldSort: true,
      findAllMatches: true,
      includeMatches: true,
      threshold: 0.6,
      keys: ['name', 'description', 'summary', 'tags', 'orgName']
    };
    // match api name present within the card
    const optionsBundles = {
      isCaseSensitive: false,
      includeScore: true,
      shouldSort: true,
      findAllMatches: true,
      threshold: 0.6,
      includeMatches: true,
      keys: [
        'name',
        'description'
        // "apis.name"
      ]
    };
    // text search
    // if (this.state.searchText && !isEnabled(this.state.entity, 'newApiDirUI')) {
    //   apisToDisplay = apisToDisplay.filter(
    //     api =>
    //       api.name
    //         .toLowerCase()
    //         .includes(this.state.searchText.toLowerCase()) ||
    //       api.description
    //         .toLowerCase()
    //         .includes(this.state.searchText.toLowerCase()) ||
    //       api?.tags.find(tagObj => tagObj.includes(this.state.searchText)) ||
    //       (isEnabled(this.state.entity, 'newApiDirUI') &&
    //         api.summary != null &&
    //         api.summary != '' &&
    //         api.summary
    //           .toLowerCase()
    //           .includes(this.state.searchText.toLowerCase())) ||
    //       api.orgData.name
    //         .toLowerCase()
    //         .includes(this.state.searchText.toLowerCase())
    //   );
    //   asyncApisToDisplay = asyncApisToDisplay.filter(
    //     api =>
    //       api.name
    //         .toLowerCase()
    //         .includes(this.state.searchText.toLowerCase()) ||
    //       api.description
    //         .toLowerCase()
    //         .includes(this.state.searchText.toLowerCase()) ||
    //       api?.tags.find(tagObj => tagObj === this.state.searchText) ||
    //       (isEnabled(this.state.entity, 'newApiDirUI') &&
    //         api.summary != null &&
    //         api.summary != '' &&
    //         api.summary
    //           .toLowerCase()
    //           .includes(this.state.searchText.toLowerCase())) ||
    //       api.orgData.name
    //         .toLowerCase()
    //         .includes(this.state.searchText.toLowerCase())
    //   );
    //   apiBundlesToDisplay = apiBundlesToDisplay.filter(
    //     api =>
    //       api.name
    //         .toLowerCase()
    //         .includes(this.state.searchText.toLowerCase()) ||
    //       api.description
    //         .toLowerCase()
    //         .includes(this.state.searchText.toLowerCase())
    //   );
    // }
    if (this.state.searchText) {
      // fuzzy search
      const apiFuseIntance = new Fuse(apisToDisplay, optionsApi);
      apisToDisplay = apiFuseIntance
        .search(this.state.searchText)
        .map(e => e.item);
      apisToDisplayNew = apiFuseIntance.search(this.state.searchText);
      const asyncApiFuseIntance = new Fuse(asyncApisToDisplay, optionsApi);
      asyncApisToDisplay = asyncApiFuseIntance
        .search(this.state.searchText)
        .map(e => e.item);
      asyncApisToDisplayNew = asyncApiFuseIntance.search(this.state.searchText);
      const bundleFuseIntance = new Fuse(apiBundlesToDisplay, optionsBundles);
      apiBundlesToDisplay = bundleFuseIntance
        .search(this.state.searchText)
        .map(e => e.item);
    }
    // category filter
    function filterCategory(apiListData, lowercaseCategories) {
      return (apiListData = apiListData.filter(
        api =>
          api.tags &&
          api.tags.some(item =>
            typeof item === 'string'
              ? lowercaseCategories.includes(item.toLowerCase())
              : lowercaseCategories.includes(
                  item.name ? item.name.toLowerCase() : ''
                )
          )
      ));
    }

    function filterCategoryNew(apiListData, lowercaseCategories) {
      return (apiListData = apiListData.filter(
        api =>
          api.item.tags &&
          api.item.tags.some(item =>
            typeof item === 'string'
              ? lowercaseCategories.includes(item.toLowerCase())
              : lowercaseCategories.includes(
                  item.name ? item.name.toLowerCase() : ''
                )
          )
      ));
    }
    if (this.state.selectedDomainIds && this.state.selectedDomainIds.length) {
      const lowercaseCategories =
        typeof selectedDomainIds == 'string'
          ? [this.state.selectedDomainIds.toLowerCase()]
          : this.state.selectedDomainIds.map(cat => cat.toLowerCase());

      apisToDisplay = filterCategory(apisToDisplay, lowercaseCategories);
      asyncApisToDisplay = filterCategory(
        asyncApisToDisplay,
        lowercaseCategories
      );
      if (this.state.searchText !== '') {
        apisToDisplayNew = filterCategoryNew(
          apisToDisplayNew,
          lowercaseCategories
        );
        asyncApisToDisplayNew = filterCategoryNew(
          asyncApisToDisplayNew,
          lowercaseCategories
        );
      }
    }
    let domainsToDisplay;
    if (domains.items) {
      domainsToDisplay = domains.items[1].dimensions
        .filter(domain => domain.name)
        .sort(function(a, b) {
          //sorting domain list by display name
          let nameA = a.name.toUpperCase(); // ignore upper and lowercase
          let nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
    }

    const domainId =
      Array.isArray(selectedDomainIds) && selectedDomainIds.length
        ? selectedDomainIds[0]
        : selectedDomainIds;
    const domainIdValue = this.state.chipDomainOptions?.find(
      chip => chip.value?.toLowerCase() === domainId
    )?.value;

    // const setSearchText = ({ target: { value } }) => {
    //   this.setState({ searchText: value });
    // };
    const setSearchText = searchValue => {
      this.setState({ searchText: searchValue });
      if (searchValue === '') {
        this.setState({
          visible: PAGINATION.LIMIT,
          visibleBundle: PAGINATION_BUNDLE.LIMIT
        });
      }
    };

    const sortFuzzyData = filteredApis => {
      let filterApiData = filteredApis;
      filterApiData.sort((a, b) => {
        if (a.score > b.score) {
          return 1;
        } else if (a.score < b.score) {
          return -1;
        } else {
          return 0;
        }
      });
      filterApiData = filterApiData.map(e => e.item);
      return filterApiData;
    };

    if (selectedAPIType && selectedAPIType.length > 0) {
      if (
        selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[0].value) &&
        !selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[1].value) &&
        !selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[2].value)
      ) {
        // only rest
        filteredApis = apisToDisplay;
        filteredApiBundles = [];
      } else if (
        selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[1].value) &&
        !selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[0].value) &&
        !selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[2].value)
      ) {
        // only async
        filteredApis = asyncApisToDisplay;
        filteredApiBundles = [];
      } else if (
        selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[2].value) &&
        !selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[0].value) &&
        !selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[1].value)
      ) {
        //  only bundle
        filteredApiBundles = apiBundlesToDisplay;
      } else if (
        selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[0].value) &&
        selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[1].value) &&
        !selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[2].value)
      ) {
        // api & async
        if (fuzzy && this.state.searchText !== '') {
          filteredApis = [...apisToDisplayNew, ...asyncApisToDisplayNew];
          filteredApis = sortFuzzyData(filteredApis);
        } else {
          filteredApis = [...apisToDisplay, ...asyncApisToDisplay];
        }
        filteredApiBundles = [];
      } else if (
        selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[0].value) &&
        selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[2].value) &&
        !selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[1].value)
      ) {
        // rest and bundle
        filteredApis = apisToDisplay;
        filteredApiBundles = apiBundlesToDisplay;
      } else if (
        selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[1].value) &&
        selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[2].value) &&
        !selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[0].value)
      ) {
        // async and bundle
        filteredApis = asyncApisToDisplay;
        filteredApiBundles = apiBundlesToDisplay;
      } else if (
        selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[0].value) &&
        selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[1].value) &&
        selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[2].value)
      ) {
        // rest, async and bundle
        if (fuzzy && this.state.searchText !== '') {
          filteredApis = [...apisToDisplayNew, ...asyncApisToDisplayNew];
          filteredApis = sortFuzzyData(filteredApis);
        } else {
          filteredApis = [...apisToDisplay, ...asyncApisToDisplay];
        }
        filteredApiBundles = apiBundlesToDisplay;
      }
    } else {
      if (fuzzy && this.state.searchText !== '') {
        filteredApis = [...apisToDisplayNew, ...asyncApisToDisplayNew];
        filteredApis = sortFuzzyData(filteredApis);
      } else {
        filteredApis = [...apisToDisplay, ...asyncApisToDisplay];
      }
      filteredApiBundles = apiBundlesToDisplay;
    }

    // if (!isEnabled(this.state.entity, 'newApiDirUI')) {
    //   if (apisToDisplay && apisToDisplay.length) {
    //     filteredApis = apisToDisplay;
    //   }

    //   if (
    //     selectedAPIType &&
    //     (!selectedAPIType.length ||
    //       (selectedAPIType.length === 1 && !selectedAPIType[0]) ||
    //       (selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[0].value) &&
    //         selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[1].value)))
    //   ) {
    //     // append results from async-api-service to the end of the list
    //     if (asyncApisToDisplay && asyncApisToDisplay.length) {
    //       filteredApis = filteredApis.concat(asyncApisToDisplay);
    //     }
    //   } else if (
    //     selectedAPIType &&
    //     selectedAPIType.includes(API_TYPES_DROPDOWN_OPTIONS[1].value)
    //   ) {
    //     filteredApis = asyncApisToDisplay;
    //   }
    // }
    // org filter
    if (
      selectedOrgs &&
      Array.isArray(selectedOrgs) &&
      selectedOrgs.length > 0
    ) {
      filteredApis = filteredApis.filter(
        f => selectedOrgs.indexOf(f.orgId) > -1
      );
    }
    // hide all empty apis (i.e. no name or description)
    filteredApis = filteredApis.filter(api => api.name && api.description);

    // should be relavance when there is search string avoid sorting
    if (!fuzzy || (fuzzy && this.state.searchText === '')) {
      filteredApis = filteredApis.sort(function(a, b) {
        // sorting apis list by name (case insensitive)
        let nameA = a.name ? a.name.toUpperCase() : '';
        let nameB = b.name ? b.name.toUpperCase() : '';
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }
    filteredApis = [...new Set(filteredApis.map(JSON.stringify))].map(
      JSON.parse
    );

    return (
      <>
        <div className="search__api-directory">
          <SearchInput
            dataTestId="search-input"
            clearButtonLabel="clearButtonLabel"
            id="search-input-expanded"
            isAlwaysExpanded
            name="search-input-expanded"
            onChange={setSearchText}
            value={this.state.searchText}
            size={INPUT_SIZES.STANDARD}
            placeholder="Search API Directory"
          />
          <br />
        </div>
        <div className="main-page-wrapper">
          <div className="api-dir-search-section">
            <ApiSearchGroup
              domains={domains}
              setSearchFilters={this.handleSetSearchFilters}
              selectedDomainIds={selectedDomainIds}
              selectedApiType={selectedAPIType}
              selectedOrgs={selectedOrgs}
            />
          </div>
          <div className="header-body-wrapper">
            {/* <div className="header-title-container-new">
              {!isEnabled(this.state.entity, 'newApiDirUI') && (
                <span className="lg-header-container">API Directory</span>
              )}

              {!isEnabled(this.state.entity, 'newApiDirUI') && (
                <div className="search__api-directory1">
                  <SearchInput
                    dataTestId="search-input"
                    clearButtonLabel="clearButtonLabel"
                    id="search-input-expanded"
                    isAlwaysExpanded
                    name="search-input-expanded"
                    onChange={setSearchText}
                    value={this.state.searchText}
                    size={INPUT_SIZES.STANDARD}
                    placeholder="Search API Directory"
                  />
                </div>
              )}

              {!isEnabled(this.state.entity, 'newApiDirUI') && (
                <div className="add__api_button">
                  <a
                    className="anchor_add_api"
                    href={`${config.developer.url}developer-account`}
                  >
                    <PrimaryButton
                      className="fdn-content__action-button"
                      text="Add API"
                    />
                  </a>
                </div>
              )}
            </div> */}

            {/*   Selected Chips   */}

            <SearchResult
              setSearchFilters={this.handleSetSearchFilters}
              domains={domains}
              searchText={this.state.searchText}
              domainsToDisplay={domainsToDisplay}
              selectedOrgs={this.state.selectedOrgs}
              count={filteredApiBundles.length + filteredApis.length}
              selectedDomainIds={selectedDomainIds}
              selectedAPIType={this.state.selectedAPIType}
            />

            {Array.isArray(this.state.selectedDomainIds) &&
              this.state.selectedDomainIds.length === 0 && (
                <BundleList
                  isSearchText={this.state.searchText !== '' ? true : false}
                  apiBundles={
                    filteredApiBundles &&
                    filteredApiBundles.slice(0, this.state.visibleBundle)
                  }
                  count={filteredApiBundles ? filteredApiBundles.length : 0}
                  entity={this.state.entity}
                />
              )}

            {Array.isArray(this.state.selectedDomainIds) &&
              this.state.selectedDomainIds.length === 0 &&
              this.state.isPaginationRequired &&
              this.state.visibleBundle < filteredApiBundles.length && (
                <div className="show__more-bundles">
                  <SecondaryButton
                    onClick={this.loadMoreBundle}
                    text="Show More Bundles"
                  />
                </div>
              )}
            {/* {!isEnabled(this.state.entity, 'newApiDirUI') && (
              <div className="api-directory_select-and-chips__header-container">
                <div
                  className="api-directory_select__container"
                  data-cy="api-type__selector"
                >
                  <FilterChips
                    id="api-type-selector"
                    className="api-type-selector"
                    options={
                      isEnabled(this.state.entity, 'newApiDirUI')
                        ? API_TYPES_DROPDOWN_OPTIONS
                        : API_TYPES_DROPDOWN_OPTIONS_OLD
                    }
                    onChange={this.onEnhancedChange}
                    value={selectedAPIType}
                  />
                </div>

                {domainsLoading ? (
                  <div className="spinner-for-chips">
                    <Flex align="center" justify="center">
                      <CircularProgress />
                    </Flex>
                  </div>
                ) : domainsToDisplay && domainsToDisplay.length ? (
                  <div className="api-directory_chipset__container">
                    <ChoiceChips
                      dataTestId="choice-chips"
                      id="domain-choice-selector"
                      onClick={selectedDomainIds => {
                        this.setState({
                          selectedDomainIds: selectedDomainIds
                        });
                      }}
                      options={this.state.chipDomainOptions}
                      value={domainIdValue || selectedDomainIds}
                    />
                  </div>
                ) : null}
              </div>
            )} */}
            {apisLoading || asyncApisLoading ? (
              <Flex
                className="fdn-empty-area--circular-progress"
                align="center"
                justify="center"
              >
                <CircularProgress />
              </Flex>
            ) : !filteredApis || filteredApis.length < 1 ? (
              <>
                <div className="error_div">
                  <strong>
                    <span>
                      No APIs available to show for selected filter criteria
                    </span>
                    <br />
                    <br />
                    <span>please try again with other filter criteria</span>
                  </strong>
                </div>
              </>
            ) : selectedDomainIds && selectedDomainIds.length ? (
              <ApiList
                key={selectedDomainIds}
                domainsToDisplay={domainsToDisplay}
                apis={filteredApis}
                isSearchText={this.state.searchText !== '' ? true : false}
                count={filteredApis ? filteredApis.length : 0}
                entity={this.state.entity}
              />
            ) : (
              <ApiList
                apis={filteredApis && filteredApis.slice(0, this.state.visible)}
                isSearchText={this.state.searchText !== '' ? true : false}
                count={filteredApis ? filteredApis.length : 0}
                domainsToDisplay={domainsToDisplay}
                entity={this.state.entity}
              />
            )}

            {this.state.isPaginationRequired &&
              this.state.visible < filteredApis.length && (
                <div className="show__more-api">
                  <SecondaryButton
                    onClick={this.loadMore}
                    text="Show More APIs"
                  />
                </div>
              )}
          </div>
        </div>
      </>
    );
  }
}

export default ApiSection;
