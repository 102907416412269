import React, { useState } from 'react';
import { IconArrowCascadeRight } from 'cdk-radial';

import './AdvancedPlans.scss';

const CONSTANTS = {
  CUSTOM: 'custom'
};

const formatNumbers = value => {
  return new Intl.NumberFormat('en-us', {
    maximumFractionDigits: 20
  }).format(value);
};

export const AdvancedPlanTile = ({
  plan,
  isAsync,
  allEndpoints,
  instanceIdVersionMap
}) => {
  return (
    <div className="advance-plan-tile">
      <PlanDetails
        details={{
          type: plan.plan !== CONSTANTS.CUSTOM ? plan.plan : plan.name,
          price: plan.price,
          freeDays: plan.freeDays,
          description: plan.description
        }}
      />
      <EndpointContainers
        plan={plan}
        isAsync={isAsync}
        allEndpoints={allEndpoints}
        instanceIdVersionMap={instanceIdVersionMap}
      />
    </div>
  );
};

const PlanDetails = ({ details }) => {
  const freeDays = details.freeDays;
  return (
    <div className="advance-plan-description-container">
      <h1 className="advance-plan-title">{`${details.type}`}</h1>
      <h1 className="advance-plan-pricing">
        {`$${details.price.toLocaleString('en-US', {
          minimumFractionDigits: 2
        })} / `}{' '}
        <span className="advance-plan-month" style={{ fontSize: '14px' }}>
          month
        </span>
      </h1>
      {freeDays !== 0 ? (
        <div className="advance-plan-freetrial">
          <h1 className="api-freetrial-date">API {freeDays}-Day Free Trial</h1>
          <h3 className="api-freetrial-date-text">
            Begins upon payment of Certification Fee
          </h3>
        </div>
      ) : (
        <div className="advance-plan-freetrial-text"> </div>
      )}
      <h3 className="advance-plan-description">{details?.description}</h3>
    </div>
  );
};

const EndpointContainers = ({
  plan,
  isAsync,
  allEndpoints,
  instanceIdVersionMap
}) => {
  const [expandedContainer, setExpandedContainer] = useState(false);

  const onExpanded = index => {
    setExpandedContainer(expandedContainer === index ? null : index);
  };

  return (
    <div className="advance-plan-endpoint-container">
      {plan.containers.map((container, index) => (
        <Container
          pricingDetails={{
            title: container.name,
            quota: container.quota,
            overage: container.overage,
            endpoints: container.endPoints,
            channels: container.channels,
            isFree: container?.isFree
          }}
          index={index}
          isExpanded={index === expandedContainer}
          onExpanded={index => onExpanded(index)}
          allEndpoints={allEndpoints}
          isAsync={isAsync}
          instanceIdVersionMap={instanceIdVersionMap}
        />
      ))}
    </div>
  );
};

const Container = ({
  pricingDetails: { title, quota, overage, endpoints, channels, isFree },
  index,
  onExpanded,
  isExpanded,
  isAsync,
  allEndpoints = [],
  instanceIdVersionMap
}) => {
  const paths = isAsync ? channels : endpoints;
  const containerEndpoints =
    paths && paths.length
      ? constructEndpointTitle(paths, instanceIdVersionMap)
      : allEndpoints;

  const callsPerMonthText = `${
    isFree ? 'Unlimited' : formatNumbers(quota)
  } free calls per month`;

  const overageText = `$${overage} overage fee`;

  return (
    <div>
      <div className="advance-plan-enpoint-container-label">{title}</div>
      <pre className="advance-plan-container-quota-overage">
        {callsPerMonthText} <br />
        {overageText}
      </pre>
      <IconArrowCascadeRight
        onClick={() => onExpanded(index)}
        className={
          isExpanded ? 'advance-plan-arrow-rotate' : 'advance-plan-arrow'
        }
      />
      {isExpanded && (
        <div className={'advance-plan-endpoint-list'}>
          {containerEndpoints.map((endpoint, index) => (
            <h3 key={index} className="advance-plan-endpoint-name">
              {endpoint}
            </h3>
          ))}
        </div>
      )}

      <div className="advance-plan-divider" />
    </div>
  );
};

const constructEndpointTitle = (endpoints, instanceIdVersionMap) => {
  return endpoints.map(ep => {
    let desc = ep.summary || ep.route.replace('/', '');
    return `${ep?.verb?.padEnd(9, ' ')} ${instanceIdVersionMap?.find(
      f => f.apiInstanceId === ep.apiInstanceId
    )?.version || ''}-${desc}`;
  });
};
