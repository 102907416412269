import React from 'react';
import { Cell, Grid, Row } from '@material/react-layout-grid';
import BundleCard from './BundleCard';

function BundleList({ apiBundles, isSearchText, count, entity = {} }) {
  return (
    <div
      className={`bundle-list_container ${
        apiBundles.length === 0 ? 'hide_bundle_grid' : ''
      }`}
    >
      {!isSearchText && <div className="api-main-header">API Bundles</div>}
      {isSearchText && (
        <div className="api-main-header">{`API Bundles (${count})`}</div>
      )}
      <Grid>
        <Row>
          {apiBundles.map((bundle, index) => (
            <Cell
              desktopColumns={6}
              phoneColumns={5}
              tabletColumns={5}
              key={index}
            >
              <BundleCard apiBundle={bundle} entity={entity} />
            </Cell>
          ))}
        </Row>
      </Grid>
    </div>
  );
}

export default BundleList;
