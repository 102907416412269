import React from 'react';
import { withRouter } from 'react-router-dom';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { PageFooter } from '@fortellis/page';
import ApiSection from './api-section/ApiSection';
import { ApiPageTopSection } from './api-section/ApiPageTopSection';
import { isEnabled } from '../../../utils/feature-gate-utils';
import { MainSection } from 'cdk-radial';

// Styles
import './api-directory.scss';

class APIDirectory extends React.Component {
  render() {
    const {
      store: { domains, apis, asyncApis },
      entityContext: { entity }
    } = this.props;

    let params = new URLSearchParams(this.props.location.search);
    return (
      <React.Fragment>
        {/* {isEnabled(entity, 'newApiDirUI') && (
          <ApiPageTopSection entity={entity} />
        )} */}
        <ApiPageTopSection entity={entity} />
        <MainSection className="main__section_page">
          <ApiSection
            apis={apis && apis.data ? apis.data : []}
            apiBundles={apis && apis.bundles ? apis.bundles : []}
            apisLoading={apis && apis.loading}
            asyncApis={asyncApis && asyncApis.data ? asyncApis.data : []}
            asyncApisLoading={asyncApis && asyncApis.loading}
            domains={domains && domains.data ? domains.data : []}
            domainsLoading={domains && domains.loading}
            location={this.props.location}
            searchString={params.get('Sr')}
            entity={entity}
          />
        </MainSection>
        <PageFooter />
      </React.Fragment>
    );
  }
}

export default withAuth(withRouter(APIDirectory));
