import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter
} from 'react-router-dom';
import AuthFooter from './components/footer/AuthFooter';
import AuthHeader from './components/header/AuthHeader';
import AuthSessionRefreshDialog from './components/sessionRefresh/AuthSessionRefreshDialog';
import APIDirectoryContainer from './components/api-directory-home/api-directory-home-page/APIDirectoryContainer';
import ScrollToTop from './components/ScrollToTop';
import EntityProvider from './components/entity-context/EntityProvider';
import { EntityProvider as LocalEntityProvider } from './components/entity-context/entityContext';
import NotFound from './components/notfound';
import { LocaleProvider, LocaleConsumer } from '@fortellis/locale';
import config from './config/config';
import { addLocaleData, IntlProvider } from 'react-intl';
import appLocaleData from './locales/data.json';
import footerLocaleData from '@fortellis/footer/locales/data.js';
import headerLocaleData from '@fortellis/header/locales/data.json';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import ApiDetailsContainer from './components/apiDetails/ApiDetailsContainer';
import { LoginLocationProvider } from './components/locationContext';
import { ThemeProvider } from 'styled-components';
import { fortellisTheme } from '@cdk-rds/core';
import { Security, LoginCallback } from '@okta/okta-react';
import {
  getAuthContext,
  HideSignOut,
  withAuth
} from '@cdk-prod/fortellis-auth-context';
import { AhaWidget } from '@cdk-prod/fortellis-aha-widget';

import './app.scss';

const localeData = {
  en: { ...appLocaleData.en, ...headerLocaleData.en, ...footerLocaleData.en },
  fr: { ...appLocaleData.fr, ...headerLocaleData.fr, ...footerLocaleData.fr }
};

const env = {
  issuer: process.env.REACT_APP_AUTH_ISSUER,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ['openid', 'profile', 'email'],
  pkce: false
};

const applicationId = process.env.REACT_APP_PLATFORM_APPLICATIONID_FORTELLIS;
const isAhaEnabled =
  process.env.REACT_APP_FEATURE_AHA_WIDGET_ENABLED === 'true';

const AhaWidgetWithAuth = withAuth(AhaWidget);

addLocaleData([...en, ...fr]);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.updateMobile();
    window.addEventListener('resize', this.updateMobile);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.updateMobile);
  }

  updateMobile = () => {
    if (!this.mounted) {
      return;
    }

    if (window.innerWidth <= 1039) {
      this.setState({ mobile: true });
    } else {
      this.setState({ mobile: false });
    }
  };

  render() {
    if (config.env !== 'prod') {
      console.warn(`Application is currently running in '${config.env}' mode`);
    }

    const { baseUrl, clientId, scope } = config.oauth;

    return (
      <ScrollToTop>
        <ThemeProvider theme={fortellisTheme}>
          <Security
            oktaAuth={this.props.oktaAuth}
            restoreOriginalUri={this.props.restoreOriginalUri}
          >
            <LocaleProvider>
              <LocaleConsumer>
                {localeContext => (
                  <IntlProvider
                    locale={localeContext.locale}
                    messages={localeData[localeContext.locale]}
                  >
                    <LocalEntityProvider>
                      <LoginLocationProvider>
                        <EntityProvider>
                          <HideSignOut />
                          {isAhaEnabled && (
                            <AhaWidgetWithAuth applicationId={applicationId} />
                          )}
                          <AuthSessionRefreshDialog />
                          <AuthHeader mobile={this.state.mobile} />
                          <Switch>
                            <Route
                              exact
                              path="/"
                              component={APIDirectoryContainer}
                            />
                            <Route
                              exact
                              path="/login/callback"
                              component={LoginCallback}
                            />
                            <Route
                              path="/apis/:id"
                              component={ApiDetailsContainer}
                            />
                            <Route
                              path="/async-apis/:id"
                              component={ApiDetailsContainer}
                            />
                            <Route component={NotFound} />
                          </Switch>
                          <AuthFooter mobile={this.state.mobile} />
                        </EntityProvider>
                      </LoginLocationProvider>
                    </LocalEntityProvider>
                  </IntlProvider>
                )}
              </LocaleConsumer>
            </LocaleProvider>
          </Security>
        </ThemeProvider>
      </ScrollToTop>
    );
  }
}

const RootWithRouterAccess = withRouter(
  getAuthContext({ WrappedComponent: App, env })
);

export default class Root extends Component {
  render() {
    return (
      <Router>
        <RootWithRouterAccess />
      </Router>
    );
  }
}
