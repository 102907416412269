import React, { useState, useEffect } from 'react';
import { Chip } from 'cdk-radial';
import '../api-directory.scss';

function SearchResult({
  count,
  selectedAPIType,
  selectedDomainIds,
  selectedOrgs,
  setSearchFilters,
  domainsToDisplay,
  searchText,
  domains
}) {
  const [apiType, setApiTye] = useState([]);
  const [category, setCategory] = useState([]);
  const [org, setOrg] = useState([]);

  const handleAPIClose = e => {
    const newArray = apiType.filter(m => m !== e);
    setApiTye(newArray);
    setSearchFilters(
      {
        apiType: newArray,
        category,
        org
      },
      'searchText'
    );
  };

  const handleCategoryClose = e => {
    const newArray = category.filter(m => m !== e);
    setCategory(newArray);
    setSearchFilters(
      {
        apiType,
        category: newArray,
        org
      },
      'searchText'
    );
  };

  const handleOrgClose = e => {
    const newArray = org.filter(m => m !== e);
    setOrg(newArray);
    setSearchFilters(
      {
        apiType,
        category,
        org: newArray
      },
      'searchText'
    );
  };

  const handleClearAll = () => {
    setApiTye([]);
    setOrg([]);
    setCategory([]);
    setSearchFilters(
      {
        apiType: [],
        category: [],
        org: []
      },
      'searchText'
    );
  };

  // map chip label
  const chipLableMap = e => {
    const locCategories = (domains?.items ?? [0, 1])[1]?.dimensions || [];
    return locCategories.find(f => f.id === e)?.name || '';
  };

  const chipOrgLableMap = e => {
    const locOrgs = (domains?.items ?? [])[0]?.dimensions || [];
    return locOrgs.find(f => f.id === e)?.name || '';
  };

  useEffect(() => {
    setApiTye(selectedAPIType);
    setOrg(selectedOrgs);
    setCategory(selectedDomainIds);
  }, [selectedAPIType, selectedOrgs, selectedDomainIds]);

  return (
    <>
      <div className="api-main-header result_wrapper">
        {searchText !== '' && (
          <div>{`Results for ${searchText} (${count})`}</div>
        )}
        {searchText === '' && <div>{`Results (${count})`}</div>}
        <div className="chip_container">
          {!!apiType &&
            Array.isArray(apiType) &&
            apiType.length > 0 &&
            apiType.map((e, idx) => {
              return (
                <Chip
                  key={idx}
                  className="custom_chip"
                  label={e}
                  onRemove={() => handleAPIClose(e)}
                />
              );
            })}
          {!!category &&
            Array.isArray(category) &&
            category.length > 0 &&
            category.map((e, idx) => {
              return (
                <Chip
                  key={idx}
                  className="custom_chip"
                  label={chipLableMap(e)}
                  onRemove={() => handleCategoryClose(e)}
                />
              );
            })}
          {!!org &&
            Array.isArray(org) &&
            org.length > 0 &&
            org.map((e, idx) => {
              return (
                <Chip
                  key={idx}
                  className="custom_chip"
                  label={chipOrgLableMap(e)}
                  onRemove={() => handleOrgClose(e)}
                />
              );
            })}
          {(apiType.length !== 0 ||
            category.length !== 0 ||
            org.length !== 0) && (
            <button className="clear_all_button" onClick={handleClearAll}>
              Clear All
            </button>
          )}
        </div>
      </div>
      <div className="result_gap"></div>
    </>
  );
}

export default SearchResult;
