import { openAha } from '@cdk-prod/fortellis-aha-widget';

const isAhaEnabled =
  process.env.REACT_APP_FEATURE_AHA_WIDGET_ENABLED === 'true';

export default {
  env: process.env['REACT_APP_ENV'],
  developer: {
    text: 'Developer Network',
    url: process.env['REACT_APP_NAV_DEVELOPER_HOME_URL']
  },
  marketplace: {
    text: 'Marketplace',
    url: process.env['REACT_APP_NAV_MARKETPLACE_EXPLORE_URL']
  },
  keys: {
    fortellisApiKey: process.env['REACT_APP_API_KEY']
  },
  api: {
    developerUrl: process.env['REACT_APP_NAV_DEVELOPER_URL'],
    developerHomeUrl: process.env['REACT_APP_NAV_DEVELOPER_HOME_URL'],
    domainUrl: process.env['REACT_APP_API_GATEWAY_URL_V3'],
    eventingControlUrl: process.env['REACT_APP_API_EVENTING_CONTROL_URL'],
    apiGatewayUrl: process.env['REACT_APP_API_GATEWAY_URL'],
    asyncApiServiceBaseUrl: process.env['REACT_APP_ASYNC_API_SERVICE_BASE_URL'],
    apiGatewayUrlV2: process.env['REACT_APP_API_GATEWAY_URL_V3'],
    subscriptionUrl: process.env['REACT_APP_API_SUBSCRIPTION_URL'],
    anonymousUrl: process.env['REACT_APP_ANON_SERVICE_API_URL'],
    emailDispatcherUrl: process.env['REACT_APP_API_EMAIL_DISPATCHER_SERVICE'],
    fortellisApiUrl: process.env['REACT_APP_FORTELLIS_API'],
    brokerUrl: process.env['REACT_APP_ASYNC_API_BROKER_URL']
  },
  apidocs: {
    url: process.env['REACT_APP_NAV_DEVELOPER_APIS_URL'],
    testSimulatorUrl: process.env['REACT_APP_TEST_SIMULATOR_LEARN_MORE']
  },
  blog: {
    text: 'Fortellis Developer',
    url: process.env['REACT_APP_BLOG_URL']
  },
  logout: {
    url: process.env['REACT_APP_LOGOUT_URL']
  },
  oauth: {
    baseUrl: process.env['REACT_APP_OAUTH_BASE_URL'],
    clientId: process.env['REACT_APP_OAUTH_CLIENT_ID'],
    responseType: process.env['REACT_APP_OAUTH_RESPONSE_TYPE'],
    scope: process.env['REACT_APP_OAUTH_SCOPE']
  },
  accountsServer: {
    url: process.env['REACT_APP_ACCOUNTS_SERVER_URL']
  },
  accountsUiURL: {
    url: process.env['REACT_APP_ACCOUNTS_UI_URL']
  },
  signup: {
    url: process.env['REACT_APP_SIGNUP_URL']
  },
  specs: {
    designGuidelinesUrl: process.env['REACT_APP_NAV_DOCS_URL'].replace(/\/$/,'') + '/docs/general/overview/api-design-guidelines/', // prettier-ignore
    specPublishingRuleUrl:
      process.env['REACT_APP_NAV_DOCS_URL'].replace(/\/$/, '') +
      '/docs/general/overview/spec-publishing-rules/'
  },
  contact: {
    url: process.env['REACT_APP_CONTACT_US_URL']
  },
  providerDocs: {
    bucket: process.env['REACT_APP_PROVIDER_DOC_BUCKET'],
    AWSRegion: 'us-west-2'
  },
  nav: {
    home: {
      text: 'Fortellis',
      url: process.env['REACT_APP_NAV_HOME_URL']
    },
    apis: {
      text: 'APIs',
      url: process.env['REACT_APP_NAV_DEVELOPER_APIS_URL']
    },
    marketplace: {
      text: 'Marketplace',
      url: process.env['REACT_APP_NAV_MARKETPLACE_URL']
    },
    insights: {
      text: 'Insights',
      url: process.env['REACT_APP_NAV_DEVELOPER_INSIGHTS_URL']
    },
    community: {
      text: 'Community',
      url: process.env['REACT_APP_NAV_DEVELOPER_COMMUNITY_URL']
    },
    docs: {
      text: 'Resources',
      url: process.env['REACT_APP_NAV_DOCS_URL']
    },
    user: {
      text: 'User',
      menu: [
        {
          code: 'org-management',
          text: 'Organization Management',
          url: process.env['REACT_APP_NAV_USER_ORG_MANAGEMENT_URL']
        },
        {
          code: 'developer-account',
          text: 'Developer Account',
          url: process.env['REACT_APP_NAV_DEVELOPER_URL']
        },
        {
          code: 'marketplace-account',
          text: 'Marketplace Account',
          menu: [
            {
              code: 'marketplace-account-solutions',
              text: 'Apps',
              url: process.env['REACT_APP_NAV_USER_MARKETPLACE_ACCOUNT_SOLUTION_URL'] // prettier-ignore
            }
          ]
        },
        {
          code: 'divider-desktop',
          text: 'Divider Desktop'
        },
        {
          code: 'settings',
          text: 'Settings',
          url: process.env['REACT_APP_NAV_USER_ADMIN-ACCOUNT_SETTINGS_URL']
        },
        ...(isAhaEnabled
          ? [
              {
                code: 'submit-ideas',
                text: 'Submit Ideas',
                handleMenuClick: () => {
                  const campaignId =
                    process.env.REACT_APP_PLATFORM_CAMPAIGNID_FORTELLIS;
                  openAha(campaignId, isAhaEnabled);
                }
              }
            ]
          : [])
      ]
    },
    language: {
      text: 'Language',
      menu: [
        { code: 'en', text: 'English - EN' },
        { code: 'fr', text: 'Français - FR' }
      ]
    }
  },
  footer: {
    social: [
      {
        code: 'facebook',
        url: 'https://www.facebook.com/groups/551215795213352/?source_id=180950569178220' // prettier-ignore
      },
      {
        code: 'twitter',
        url: 'https://twitter.com/fortellisdev'
      },
      {
        code: 'github',
        url: 'https://github.com/fortellis'
      },
      {
        code: 'youtube',
        url: 'https://www.youtube.com/channel/UCk4etySO7-MJhYCPNDtox2A'
      }
    ],
    nav: [
      {
        text: 'About',
        menu: [
          {
            text: 'Community',
            url: process.env['REACT_APP_NAV_DEVELOPER_COMMUNITY_URL']
          },
          {
            text: 'Marketplace',
            url: process.env['REACT_APP_NAV_MARKETPLACE_URL']
          }
        ]
      },
      {
        text: 'Documentation',
        menu: [
          {
            text: 'API Directory',
            url: process.env['REACT_APP_NAV_DEVELOPER_APIS_URL']
          },
          {
            text: 'Resources',
            url: process.env['REACT_APP_NAV_DOCS_URL']
          }
        ]
      },
      {
        text: 'Support',
        menu: [
          {
            text: 'Contact Us',
            url: process.env.REACT_APP_CONTACT_US_URL
          },
          {
            text: 'Developer Forum',
            url: process.env.REACT_APP_BLOG_URL
          }
        ]
      }
    ],
    copyright: {
      trademark: `©${new Date().getFullYear()}. Fortellis is a registered trademark of CDK Global, LLC.`,
      legal: {
        text: ' ',
        url: process.env['REACT_APP_LEGAL_URL']
      },
      terms: {
        text: 'Terms of Use & Privacy Policy',
        url: process.env['REACT_APP_TERMS_URL']
      }
    }
  }
};
