import React, { Component, useRef } from 'react';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import '@rmwc/select/styles';
import { Snackbar } from '@material/react-snackbar';
import { IconFileDownload, TextButton, fortellisTheme } from 'cdk-radial';
import { ThemeProvider } from 'styled-components';
import {
  Endpoints,
  ApiEndpoints,
  EndpointsList,
  EndpointListItem,
  EndpointListInfo,
  SpecSectionItem,
  SpecSection,
  ApiHeaderDetails,
  AsyncApiSpecCodeBody
} from './styledComponents';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import './api-explorer.scss';
import YAML from 'yaml';
import config from '../../config/config';
import { Button } from '@material/react-button';
const refs = {};

const ApiSpecYamlText = ({ spec, selectedSection }) => {
  if (spec) {
    //reorder asyncapi property to be first
    const orderedSpec = {
      asyncapi: spec.asyncapi
    };
    Object.assign(orderedSpec, spec);
    var startLine = 1;

    //separate spec per sections to support highlighting and scrolling on click
    return (
      <div className="yaml-spec-section">
        {Object.keys(orderedSpec).map(section => {
          const doc = new YAML.Document();
          const startingLine = startLine;
          let currentSection = {};
          currentSection[section] = spec[section];
          doc.contents = currentSection;
          const yamlText = doc.toString();
          const REGEX_FIND_COUNT_OF_LINES = /\r\n|\r|\n/;
          startLine =
            startLine + yamlText.split(REGEX_FIND_COUNT_OF_LINES).length - 1;
          let customStyles = {
            marginTop: '0px',
            marginBottom: '0px',
            paddingTop: '0px',
            paddingBottom: '0px'
          };

          return (
            <AsyncApiSpec
              selectedSection={selectedSection}
              section={section}
              customStyles={customStyles}
              startingLine={startingLine}
              yamlText={yamlText}
            />
          );
        })}
      </div>
    );
  }
};

const AsyncApiSpec = ({
  selectedSection,
  section,
  customStyles,
  startingLine,
  yamlText
}) => {
  refs[section] = useRef();
  return (
    <div ref={refs[section]}>
      <AsyncApiSpecCodeBody id={selectedSection === section ? 'selected' : ''}>
        <SyntaxHighlighter
          language="yaml"
          customStyle={customStyles}
          showLineNumbers={true}
          startingLineNumber={startingLine}
          wrapLongLines={true}
          lineNumberContainerStyle={{
            float: 'left',
            paddingRight: '20px',
            paddingLeft: '15px',
            minWidth: '20px',
            color: 'white',
            lineHeight: 'inherit'
          }}
          codeTagProps={{
            style: {
              fontFamily: 'Source Code Pro',
              color: 'rgb(215, 71, 168)',
              lineHeight: 'initial'
            }
          }}
        >
          {yamlText || ''}
        </SyntaxHighlighter>
      </AsyncApiSpecCodeBody>
    </div>
  );
};

class AsyncApiExplorer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      specSections: [],
      showToaster: false,
      selectedSection: null,
      brokerUrl: null,
      refs: {}
    };
  }

  setSection = section => {
    if (this.state.refs[section] && this.state.refs[section].current) {
      this.state.refs[section].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
      this.setState({ selectedSection: section });
    }
  };

  copyToClipBoard = async e => {
    try {
      await navigator.clipboard.writeText(e.currentTarget.innerText);
      this.setState({
        showToaster: true,
        message: 'Copied to clipboard'
      });
      setTimeout(() => this.setState({ showToaster: false }), 500);
    } catch (e) {
      console.log(e);
      this.setState({
        showToaster: true,
        message: 'Failed to Copy'
      });
    }
  };

  componentDidMount = async () => {
    const apiInstance = this.props.api.environments[0].instances[0];
    this.setState({
      content: apiInstance.spec,
      brokerUrl: config.api.brokerUrl,
      specSections: Object.keys(apiInstance.spec),
      refs: refs
    });
  };

  render() {
    const {
      state: { content, brokerUrl, specSections, selectedSection }
    } = this;

    return (
      <ThemeProvider theme={fortellisTheme}>
        <div className="api-tab-container generic-style-for-browsers">
          <ApiHeaderDetails className="header-section">
            <div className="header-content">
              <div className="api-details-title">Async API Specifications</div>
              <TextButton
                className="download_spec_btn"
                data-cy="download_spec_btn"
                onClick={() => {
                  this.props.downloadSpec(content);
                }}
                icon={<IconFileDownload />}
                text="API SPEC"
              />
            </div>
          </ApiHeaderDetails>
          <ApiEndpoints>
            <EndpointsList>
              <Endpoints className="spec-sections">
                {specSections.map(item => {
                  //skip 'id' and 'async-api' properties as a section
                  if (item === 'id' || item === 'asyncapi') return;
                  return (
                    <EndpointListItem key={item} id={item}>
                      <EndpointListInfo
                        onClick={() => this.setSection(item)}
                        method={'asyncApiSpecSection'}
                        isSelected={item === selectedSection}
                      >
                        <SpecSectionItem method={item}>{item}</SpecSectionItem>
                      </EndpointListInfo>
                    </EndpointListItem>
                  );
                })}
              </Endpoints>
            </EndpointsList>
            {content && (
              <SpecSection>
                <div>
                  {brokerUrl && (
                    <div>
                      <div className="broker-url-text"> Broker URL </div>
                      <span
                        className="broker-url"
                        onClick={this.copyToClipBoard}
                      >
                        {this.state.brokerUrl}
                      </span>
                    </div>
                  )}
                  <ApiSpecYamlText
                    spec={this.state.content}
                    selectedSection={this.state.selectedSection}
                  />
                </div>
              </SpecSection>
            )}
          </ApiEndpoints>
          <Snackbar
            open={this.state.showToaster}
            message={this.state.message}
          />
        </div>
      </ThemeProvider>
    );
  }
}

export default withAuth(AsyncApiExplorer);
