import React, { Component } from 'react';
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton
} from '@material/react-dialog';
import './ApiDetails.scss';
import { FormField } from '../apiExplorer/styledComponents';
import { Input } from '@material/react-text-field';
import TextField from '@material-ui/core/TextField';
import { emailMessages } from './constants';
import { Snackbar } from '@material/react-snackbar';
import moment from 'moment';

const maxSizeForTextField = 250;
const maxSizeForNameField = 40;
let date = new Date();
let dateComponent = moment(date)
  .utc()
  .format('YYYY-MM-DD');
let timeComponent = moment(date)
  .utc()
  .format('HH:mm:ss');

const TextInput = ({
  name,
  label,
  value,
  onChange,
  required,
  disabled,
  type,
  maxLength,
  ...other
}) => (
  <FormField
    label={label}
    autoComplete="off"
    data-cy={`contact-support-label__selector-${label}`}
    inputlabelprops={{
      shrink: true
    }}
  >
    <Input
      style={{ fontWeight: 500, fontFamily: 'Raleway' }}
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      required={required}
      disabled={disabled}
      type={type}
      maxLength={maxLength}
      data-cy={`contact-support-value__selector-${name}`}
      {...{ other }}
    />
  </FormField>
);

class ContactSupport extends Component {
  state = {
    name: '',
    email: '',
    message: '',
    snackBar: false
  };

  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.initializeState(this.props.auth.userData);
    }
  }

  componentWillReceiveProps() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  createConsumerEmailPayload = () => {
    const {
      auth: { userData }
    } = this.props;
    let confirmationToConsumer = {
      templateIdentifier: 'l/149371/2020-03-31/4t7wk6',
      emailContentSubstitutions: {
        firstName: userData.name,
        email: userData.email,
        apiName: this.props.apiName,
        date: dateComponent,
        time: `${timeComponent} UTC`,
        apiProviderName: this.props.providerName,
        message: this.state.message
      }
    };
    this.props.sendEmailToConsumer(confirmationToConsumer);
  };

  createProviderEmailPayload = () => {
    let contactSupportMsgToProvider = {
      templateIdentifier: 'l/149371/2020-03-31/4t7wp4',
      emailContentSubstitutions: {
        apiName: this.props.apiName,
        firstName: this.props.providerName,
        email: this.props.providerSupportEmail,
        userName: this.state.name,
        userEmail: this.state.email,
        date: dateComponent,
        time: `${timeComponent} UTC`,
        message: this.state.message
      }
    };
    this.props.sendEmailToProvider(contactSupportMsgToProvider);
    this.setState({ snackBar: true });
  };

  sendEmail = () => {
    this.createProviderEmailPayload();
    this.createConsumerEmailPayload();
    this.props.onClose();
  };

  handleMessageChange = event => {
    this.setState({ message: event.target.value });
  };

  handleNameChange = event => {
    this.setState({ name: event.target.value });
  };

  handleEmailChange = event => {
    this.setState({ email: event.target.value });
  };

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    const {
      auth: { userData }
    } = this.props;
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onClose();
      this.clearState(userData.name, userData.email);
    }
  }

  clearState = (name, email) => {
    this.setState({ name: name, email: email, message: '' });
  };

  initializeState = userData => {
    this.setState({ name: userData.name, email: userData.email });
  };

  onCancel = () => {
    const {
      auth: { userData }
    } = this.props;
    this.props.onClose();
    this.clearState(userData.name, userData.email);
  };

  render() {
    const { open = false } = this.props;
    const {
      auth: { userData }
    } = this.props;
    const {
      store: { sendEmailToProvider }
    } = this.props;
    const validateEmail = !this.state.email.match(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
    );
    return (
      <div ref={this.setWrapperRef}>
        <Dialog
          open={open}
          onCancel={this.onCancel}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Contact {this.props.orgName ? this.props.orgName : null}
          </DialogTitle>
          <DialogContent>
            <TextInput
              required={true}
              maxLength={maxSizeForNameField}
              margin="dense"
              id="name"
              label="Name"
              type="name"
              fullWidth
              onChange={this.handleNameChange}
              value={this.state.name}
            />
            <div className="character-remaining-div">
              {this.state.name ? this.state.name.length : 0} /{' '}
              {maxSizeForNameField}
            </div>
            <br />
            <br />
            <TextField
              margin="dense"
              required={true}
              id="email"
              label="From"
              type="email"
              fullWidth
              helperText={
                validateEmail ? (
                  <div style={{ color: 'red' }}>
                    Please enter valid email address
                  </div>
                ) : (
                  ''
                )
              }
              onChange={this.handleEmailChange}
              value={this.state.email}
            />
            <br />
            <br />
            <TextInput
              name="Message"
              label="Message"
              type="text"
              maxLength={maxSizeForTextField}
              value={this.state.message}
              onChange={e => this.handleMessageChange(e)}
              disabled={false}
              required={true}
            />
            <div className="character-remaining-div">
              {this.state.message ? this.state.message.length : 0} /{' '}
              {maxSizeForTextField}
            </div>
            <div className="contact_publisher_modal_clause">
              <p className="contact_publisher_modal_clause_content">
                By clicking Submit, you agree to send your contact information
                to{' '}
                {this.props.orgName ? (
                  <b>{this.props.orgName}</b>
                ) : (
                  <b>the Organisation</b>
                )}
                .
              </p>
            </div>
          </DialogContent>
          <DialogFooter>
            <DialogButton onClick={this.props.onClose} id="CancelButton">
              Cancel
            </DialogButton>
            <DialogButton
              disabled={
                !this.state.name ||
                !this.state.email ||
                !this.state.message ||
                validateEmail ||
                !this.state.name.match(/[a-zA-Z]/g) ||
                !this.state.message.match(/[a-zA-Z]/g)
              }
              onClick={this.sendEmail}
              id="SubmitButton"
            >
              Submit
            </DialogButton>
          </DialogFooter>
        </Dialog>
        {sendEmailToProvider.isFetching
          ? null
          : sendEmailToProvider.data
          ? this.state.snackBar && (
              <Snackbar
                message={
                  sendEmailToProvider.message === emailMessages.EMAIL_SUCCESS
                    ? emailMessages.EMAIL_SUCCESS
                    : emailMessages.EMAIL_FAILURE
                }
                onClose={() => {
                  this.setState({ snackBar: false });
                }}
                onClosing={() => {
                  this.setState({ snackBar: false });
                }}
                className="snack-bar-contact"
              />
            )
          : null}
      </div>
    );
  }
}

export default ContactSupport;
