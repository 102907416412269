import { withAuth } from '@cdk-prod/fortellis-auth-context';
import React, { useContext } from 'react';
import { PERSONAL_ACCOUNT_ID } from '@cdk-prod/fortellis-entity-context';
import './ApiDetails.scss';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Cell } from '@material/react-layout-grid';
import RedirectURL from '../common/RedirectURL';
import { locationContext } from '../locationContext';
import { IconOpenInNew } from 'cdk-radial';
import { AdvancedPlans } from '../advanced-plans/AdvancedPlans';
import { getInstanceIdVersionMap } from '../../utils/spec-utils';

let cf = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 4,
  minimumFractionDigits: 2
});
let nf = new Intl.NumberFormat();
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500
  },
  control: {
    padding: theme.spacing(2)
  },
  control2: {
    backgroundColor: '#F3F3F3',
    boxShadow: '0px 1px 3px rgba(21, 27, 37, 0.5)',
    alignItems: 'center',
    padding: '16px, 32px, 16px, 32px',
    gap: '32px',
    height: '80px',
    width: '100%',
    borderRadius: '-7px',
    display: 'flex'
  },
  control3: {
    backgroundColor: '#F3F3F3',
    height: '80px',
    width: '100%',
    borderRadius: '-8px',
    marginLeft: '-15px',
    boxShadow: '0px 1px 3px rgba(21, 27, 37, 0.5)',
    padding: '16px 32px',
    margin: '0px 32px',
    marginTop: '16px'
  },
  control4: {
    backgroundColor: '#F3F3F3',
    boxShadow: '0px 1px 3px rgba(21, 27, 37, 0.5)',
    alignItems: 'center',
    padding: '16px, 32px, 16px, 32px',
    gap: '32px',
    height: '80px',
    width: '100%',
    borderRadius: '-7px',
    display: 'flex',
    marginLeft: '-15px'
  }
}));

const isAdvancedPlan = plan =>
  Boolean(
    plan &&
      plan.type &&
      plan.type.toLowerCase &&
      plan.type.toLowerCase() === 'advanced' &&
      plan.status?.toLowerCase() === 'active'
  );

const APIDetailsHeader = ({ title }) => {
  return <div className="api-details-title">{title}</div>;
};

const ApiPricing = props => {
  const classes = useStyles();
  const noPricingText = () => {
    return (
      <div className="no-documentation-text">
        The API provider does not have any pricing available at this time.
      </div>
    );
  };
  const isAsync = props && props.apiType === 'async-api';

  const getAllEndpoints = () => {
    const apiInfo = props.store.apiInfo;
    const spec =
      apiInfo &&
      apiInfo.data &&
      apiInfo.data.environments &&
      apiInfo.data.environments[0] &&
      apiInfo.data.environments[0].instances &&
      apiInfo.data.environments[0].instances[0] &&
      apiInfo.data.environments[0].instances[0].spec;
    let allEndpointsTexts = [];
    if (spec) {
      if (isAsync) {
        let channels = spec.channels || [];
        allEndpointsTexts = Object.keys(channels);
      } else {
        let paths = spec.paths || [];
        let pathResources = Object.keys(paths);
        pathResources.forEach(resource => {
          let methods = Object.keys(paths[resource]) || [];
          methods.forEach(method => {
            const summary =
              paths[resource][method] && paths[resource][method].summary
                ? paths[resource][method].summary
                : resource.replace('/', '');
            const endpointText = `${method.toUpperCase()} ${summary}`;
            allEndpointsTexts.push(endpointText);
          });
        });
      }
    }

    return allEndpointsTexts;
  };

  const displayTieredRatePlan = ratePlan => {
    const tiers = ratePlan.rates ? ratePlan.rates : ratePlan.tiers;
    const wrapperClass =
      tiers.length >= 4
        ? 'rate-plan-wrapper'
        : 'rate-plan-wrapper-for-less-than-4';
    const cardClass =
      tiers.length >= 4 ? 'rate-plan-card' : 'rate-plan-card-for-less-than-4';
    const cellClass = tiers.length >= 4 ? classes.control2 : classes.control4;

    return (
      <Cell className={classes.control}>
        <div className="rate-plan-heading">Transactional Plan</div>
        <div className="rate-plan-subheading">
          Based on monthly call quotas.
        </div>
        <div className={wrapperClass}>
          {tiers.map(rateItem => {
            let rate =
              rateItem.rate || rateItem.rate === 0
                ? rateItem.rate
                : rateItem.pricePerRequest;
            return (
              <Grid className={cardClass}>
                <Cell className={cellClass}>
                  <div className="trans-rate-plan-text">
                    <div className="rate-plan-title">
                      ${cf.format(rate)} / Call
                    </div>
                    <div className="rate-plan-details">
                      {nf.format(rateItem.startUnit)}-
                      {rateItem.endUnit
                        ? nf.format(rateItem.endUnit)
                        : 'Unlimited'}{' '}
                      Calls / Month
                    </div>
                  </div>
                </Cell>
              </Grid>
            );
          })}
        </div>
      </Cell>
    );
  };

  const displaySubscriptionRatePlan = ratePlan => (
    <Cell className={classes.control}>
      <div className="rate-plan-heading">Subscription Plan</div>
      <div className="subs-rate-plan-subheading">
        Monthly flat rate plan for unlimited use across all dealership
        locations.
      </div>
      <Cell className={classes.control3}>
        <div className="subs-rate-plan-text">
          ${cf.format(ratePlan.subscriptionPrice)} / Month
        </div>
      </Cell>
    </Cell>
  );

  const displayRatePlans = plans => {
    if (plans.some(ratePlan => isAdvancedPlan(ratePlan))) {
      return (
        <>
          <AdvancedPlans
            instanceIdVersionMap={getInstanceIdVersionMap(props.store.apiInfo)}
            plans={plans.filter(p => isAdvancedPlan(p))}
            isAsync={isAsync}
            allEndpoints={getAllEndpoints()}
          />
        </>
      );
    } else {
      return (
        <>
          {plans.map(ratePlan => {
            if (ratePlan.type && ratePlan.type.toLowerCase() === 'tiered') {
              return displayTieredRatePlan(ratePlan);
            } else if (
              ratePlan.type &&
              ratePlan.type.toLowerCase() === 'subscription'
            ) {
              return displaySubscriptionRatePlan(ratePlan);
            } else if (
              ratePlan.type &&
              ratePlan.type.toLowerCase() === 'free'
            ) {
              return (
                <Cell className={classes.control}>
                  <div className="rate-plan-heading">Free Plan</div>
                  <div className="subs-rate-plan-subheading">
                    Unlimited free API calls per month with this plan.
                  </div>
                  <Cell className={classes.control3}>
                    <div className="free-subs-rate-plan-text">$0 / Month</div>
                    <div className="free-rate-plan-details">
                      Unlimited Calls / Month
                    </div>
                  </Cell>
                </Cell>
              );
            } else {
              return null;
            }
          })}
        </>
      );
    }
  };

  return (
    <div
      className="api-tab-container generic-style-for-browsers"
      style={{ marginBottom: '5%' }}
    >
      <APIDetailsHeader title="API Pricing" />
      {props.store.apiInfo &&
      props.store.apiInfo.data &&
      props.store.apiInfo.data.plans &&
      props.store.apiInfo.data.plans.length
        ? displayRatePlans(props.store.apiInfo.data.plans)
        : noPricingText()}
    </div>
  );
};

export default withAuth(ApiPricing);
