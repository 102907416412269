export const searchParams = {
  apiType: {
    text: 'API Type',
    values: [
      {
        key: 'restApi',
        value: 'REST APIs'
      },
      {
        key: 'asyncApi',
        value: 'Async APIs'
      },
      {
        key: 'bundles',
        value: 'Bundles'
      }
    ]
  }
};
