function formatPhoneNumber(phone) {
  const re = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
  if (phone) {
    const groups = re.exec(phone);
    return groups
      ? `${groups[1] ? `+${groups[1]}` : ''}(${groups[2]}) ${groups[3]}-${
          groups[4]
        }`
      : false;
  }
}

function sortData(a, b) {
  if (a.lastUpdated > b.lastUpdated) return 1;
  if (a.lastUpdated < b.lastUpdated) return -1;
  return 0;
}

const getIntitials = name => {
  if (!name) {
    return '';
  }
  const [first, second] = name.split(' ');
  if (second) {
    return first.charAt(0) + second.charAt(0);
  }
  if (first) {
    return first.substring(0, 2);
  }
};

export { formatPhoneNumber, sortData, getIntitials };
