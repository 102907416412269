import React from 'react';

const RedirectURL = props => {
  return (
    <div className={props.classes} {...props}>
      {' '}
      <a href={props.url} target="_blank" rel="noopener noreferrer">
        {props.heading}{' '}
        {props.icon ? <i className="redirect-icon">{props.icon}</i> : ''}
      </a>
    </div>
  );
};
export default RedirectURL;
