import React, { useState, useEffect } from 'react';
import { CodeBody, Title, Subtitle } from '../styledComponents';
import { TextButton, BUTTON_SIZES } from 'cdk-radial';
import CodeHighlight from './CodeHighlight';
import SchemaStructure from './SchemaStructure';
import { ResponseSelection } from './ResponseSelection';
import { BODY_TYPES } from './constants';
import { getPropertyType } from '../../../utils/spec-utils';

import '../api-explorer.scss';

export const ResponseBodySection = ({ endpoint, schema }) => {
  const [responseCodes, setResponseCodes] = useState([]);
  const [schemaResponse, setSchemaResponse] = useState({});
  const [responseBodyExample, setResponseBodyExample] = useState({});
  const [activeHttpCode, setActiveHttpCode] = useState();
  const [responseStructs, setResponseStructs] = useState([]);

  const isValidJson = input => {
    try {
      JSON.parse(input);
    } catch (e) {
      return false;
    }
    return true;
  };

  const getResponseExample = (endpoint, activeHttpCode) => {
    let responseExample;
    const parent = endpoint.schema?.responses[activeHttpCode]?.content
      ? Object.keys(endpoint.schema.responses[activeHttpCode]?.content)[0]
      : [];

    const schemaResponse = endpoint
      ? endpoint?.schema?.responses[activeHttpCode] &&
        !!parent &&
        endpoint?.schema?.responses[activeHttpCode]?.content?.[parent]?.schema
      : null;

    if (!!schemaResponse) {
      responseExample =
        endpoint.schema.responses[activeHttpCode]?.content?.[parent]?.schema
          .example;
    } else if (!!endpoint?.schema?.responses[activeHttpCode]?.content) {
      responseExample =
        endpoint.schema.responses[activeHttpCode].content[parent]?.example ||
        endpoint.schema.responses[activeHttpCode].content[parent]?.examples;
    }
    if (typeof responseExample === 'string' && isValidJson(responseExample)) {
      responseExample = JSON.parse(responseExample);
    }

    responseExample =
      !!schemaResponse && !responseExample
        ? getPropertyType(schemaResponse)
        : responseExample;
    return responseExample;
  };

  useEffect(() => {
    if (!!endpoint?.schema?.responses) {
      const responseCodes = Object.keys(endpoint.schema?.responses);
      setResponseCodes(responseCodes);
      const leastCode = responseCodes.reduce((acc, loc) =>
        acc < loc ? acc : loc
      );
      setActiveHttpCode(leastCode);
    }
  }, [endpoint?.schema?.responses]);

  useEffect(() => {
    const isOas3Spec = !!schema?.openapi && schema.openapi.indexOf('3') > -1;

    if (isOas3Spec) {
      const parent = endpoint.schema?.responses[activeHttpCode]?.content
        ? Object.keys(endpoint.schema.responses[activeHttpCode]?.content)[0]
        : [];

      const schemaResponse = endpoint
        ? endpoint.schema.responses[activeHttpCode] &&
          !!parent &&
          endpoint.schema.responses[activeHttpCode]?.content?.[parent]?.schema
        : null;

      setSchemaResponse(schemaResponse);
      setResponseStructure(schemaResponse);

      const responseExample = getResponseExample(endpoint, activeHttpCode);
      setResponseBodyExample(responseExample);
    } else {
      const schemaResponse = endpoint
        ? endpoint?.schema?.responses[activeHttpCode] &&
          endpoint?.schema?.responses[activeHttpCode]?.schema
        : null;

      const responseBodyExample = endpoint
        ? schemaResponse &&
          endpoint?.schema?.responses[activeHttpCode]?.schema?.example
        : null;
      setSchemaResponse(schemaResponse);
      setResponseBodyExample(responseBodyExample);
    }
  }, [activeHttpCode, endpoint]);

  const setResponseStructure = schemaResponse => {
    let respStruct = [];
    if (
      !!schemaResponse &&
      Object.keys(schemaResponse).length > 0 &&
      !!schemaResponse?.oneOf
    ) {
      schemaResponse.oneOf.forEach((f, idx) => {
        respStruct = [...respStruct, { id: idx, schema: f }];
      });
    }
    setResponseStructs(respStruct);
  };

  const handleSelectedResponse = resp => {
    const locResponse = responseStructs.find(f => f.id === resp)?.schema;
    setSchemaResponse(locResponse);
    setResponseBodyExample(locResponse?.example);
  };

  return (
    <>
      <Title center>Response</Title>
      <div className="response-code-wrapper">
        {responseCodes.map(m => (
          <div
            className={`staus-code-wrapper ${
              activeHttpCode === m ? 'staus-code-selected' : ''
            }`}
          >
            <TextButton
              size={BUTTON_SIZES.SMALL}
              text={m}
              onClick={() => setActiveHttpCode(m)}
            />
          </div>
        ))}
      </div>

      <ResponseSelection
        activeHttpCode={activeHttpCode}
        responseStructs={responseStructs}
        setSelectedResponse={handleSelectedResponse}
      />
      {!!schemaResponse && Object.keys(schemaResponse).length > 0 && (
        <>
          <Subtitle>Response Body Structure</Subtitle>
          <SchemaStructure
            structure={'Response'}
            schema={schemaResponse}
            type={BODY_TYPES.response}
          />
        </>
      )}
      {responseBodyExample && Object.keys(responseBodyExample).length > 0 && (
        <>
          <Subtitle>Response Body Example</Subtitle>
          <CodeBody data-cy={`apiexplorer-responsecode-body`}>
            <CodeHighlight language="json">
              {JSON.stringify(responseBodyExample, null, 4)}
            </CodeHighlight>
          </CodeBody>
        </>
      )}
    </>
  );
};
