import React from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableHeader,
  TableHeadItem,
  TableItem,
  TableRow,
  Subtitle
} from '../styledComponents';

const ParameterTable = ({ params, title, visible }) => {
  return (
    <React.Fragment>
      {visible ? (
        <>
          <Subtitle>{title}</Subtitle>

          <Table className="roundedCorners">
            <TableHeader>
              <TableRow>
                <TableHeadItem>Parameter</TableHeadItem>
                <TableHeadItem>Type</TableHeadItem>
                <TableHeadItem>Description</TableHeadItem>
                <TableHeadItem>Required</TableHeadItem>
              </TableRow>
            </TableHeader>
            <TableBody>
              {params.map(param => (
                <TableRow key={param.name}>
                  <TableItem>{param.name}</TableItem>
                  <TableItem>{param.type || param?.schema?.type}</TableItem>
                  <TableItem>
                    {param.description ? param.description : 'n/a'}
                  </TableItem>
                  <TableItem>{param.required ? 'Yes' : 'No'}</TableItem>
                  {/* // TODO: Add examples */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export default ParameterTable;
