import React from 'react';
import { Cell, Grid, Row } from '@material/react-layout-grid';
import ApiCard from './ApiCard';
import { isEnabled } from '../../../../utils/feature-gate-utils';

function ApiList({
  title,
  apis = [],
  domainsToDisplay = [],
  entity = {},
  isSearchText,
  count
}) {
  let sortedApis = apis;
  if (sortedApis && sortedApis.length) {
    return (
      <div className="spec-list_container">
        {/* {isEnabled(entity, 'newApiDirUI') && !isSearchText && (
          <div className="api-main-header">Discover more APIs</div>
        )}
        {isEnabled(entity, 'newApiDirUI') && isSearchText && (
          <div className="api-main-header">{`APIs (${count})`}</div>
        )} */}
        <div className="api-main-header">Discover more APIs</div>
        <div className="api-main-header">{`APIs (${count})`}</div>
        <Grid>
          {title && (
            <Cell columns={12}>
              <div className="spec-list_title">{title}</div>
            </Cell>
          )}

          {sortedApis && sortedApis.length ? (
            <Row>
              {sortedApis.map((api, index) => (
                <Cell
                  desktopColumns={4}
                  phoneColumns={4}
                  tabletColumns={4}
                  key={index}
                >
                  <ApiCard
                    key={`spec-card__${api.id}`}
                    api={api}
                    domainsToDisplay={domainsToDisplay}
                    entity={entity}
                  />
                </Cell>
              ))}
            </Row>
          ) : null}
        </Grid>
      </div>
    );
  } else {
    return null;
  }
}

export default ApiList;
