import React, { Fragment, useEffect, useState } from 'react';
import { SecondaryButton } from 'cdk-radial';
import { getSpecObj } from '../../../utils/spec-utils';
import config from '../../../config/config';
import { TestSimulatorContent } from './TestSimulatorContent';
import { TEST_SIMULATOR_SUB_HEADER } from '../../api-directory-home/constants';
import { StyledDialog } from '../styledComponents';

export const TestSimulator = ({ schema, endpoint }) => {
  const [isTestSimDlgOpen, setIsTestSimDlgOpen] = useState(false);
  const [specContent, setSpecContent] = useState([]);

  useEffect(() => {
    if (!!schema?.paths) {
      const specObj = getSpecObj(schema);
      setSpecContent(specObj);
    }
  }, [schema]);

  const handleTestSimDlgOpen = _ => {
    setIsTestSimDlgOpen(true);
  };

  const handleTestSimDlgClose = _ => {
    setIsTestSimDlgOpen(false);
  };
  return (
    <Fragment>
      <SecondaryButton
        dataTestId="secondary-button"
        iconColor="primary"
        iconSize={24}
        onClick={() => handleTestSimDlgOpen()}
        size="standard"
        text="Try It Out"
      />
      <StyledDialog
        dataTestId="test-simil-dlg"
        id="test-simulator-dlg"
        isOpen={isTestSimDlgOpen}
        onClose={handleTestSimDlgClose}
        title="Try It Out"
        className="test-simul-dlg"
      >
        <div>
          <div className="test-simul-sub-header">
            {TEST_SIMULATOR_SUB_HEADER}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={config.apidocs.testSimulatorUrl}
            >
              <strong>Learn More</strong>
            </a>
          </div>
          <TestSimulatorContent specContent={specContent} endpoint={endpoint} />
        </div>
      </StyledDialog>
    </Fragment>
  );
};
