import React, { Fragment, useEffect, useState } from 'react';
import {
  Input,
  INPUT_SIZES,
  PrimaryButton,
  SecondaryButton,
  Toast,
  TOAST_POSITIONS,
  TOAST_VARIANTS,
  TOAST_VISIBILITY_DURATIONS
} from 'cdk-radial';
import { CodeBody } from '../../apiExplorer/styledComponents';
import CodeHighlight from '../../apiExplorer/apiEndpoint/CodeHighlight';
import * as constants from '../../apiExplorer/apiEndpoint/constants';
import { getResponses, getValidationStatus } from '../../../utils/spec-utils';
import ReactJson from 'react-json-view';
import { IconSync } from 'cdk-radial';
import { getRequestValidationErrors } from '../../../utils/spec-utils';
import { StyledTd, StyledTh } from '../styledComponents';
import { ERROR_STATEMENT } from '../../api-directory-home/constants';

export const TestSimulatorEndpointContent = ({ endpointContent }) => {
  const [isExecuted, setIsExecuted] = useState(false);
  const [reqExample, setReqExample] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [inputErrors, setInputErrors] = useState({});
  const [reqSchema, setReqSchema] = useState({});
  const [reqErrors, setReqErrors] = useState([]);
  const [responses, setResponses] = useState([]);

  const requestBodyProps = {
    src: reqExample,
    theme: 'monokai',
    name: null,
    displayDataTypes: false,
    displayObjectSize: false,
    enableClipboard: false,
    style: {
      fontWeight: '500',
      width: '65%',
      letterSpacing: '0.82px',
      lineHeight: '18px',
      whiteSpace: 'pre',
      backgroundColor: '#151B25',
      maxWidth: '772px',
      maxHeight: '668px',
      fontFamily: 'Source Code Pro',
      overflow: 'auto',
      color: 'white',
      borderRadius: '8px',
      padding: '10px',
      gap: '10px',
      margin: '16px 0'
    }
  };

  const initializeInputValues = () => {
    const newInputValues = {};
    const newInputErrors = {};
    // eslint-disable-next-line no-unused-expressions
    endpointContent?.request?.forEach(param => {
      if (param.in !== 'body') {
        newInputValues[param.name] = param.example;
        newInputErrors[param.name] = false;
      }
    });
    setInputValues(newInputValues);
    setInputErrors(newInputErrors);

    const reqBody = endpointContent?.request?.find(f => f.in === 'body');

    setReqExample(reqBody?.schema?.example);
    setReqSchema(reqBody?.schema);
  };

  useEffect(() => {
    if (endpointContent) {
      initializeInputValues();
      setResponses(getResponses(endpointContent, isExecuted));
    }
  }, [endpointContent, isExecuted]);

  useEffect(() => {
    if (reqExample) {
      setReqErrors(getRequestValidationErrors(reqSchema, reqExample));
    }
  }, [reqExample]);

  const onReset = () => {
    setIsExecuted(false);
    initializeInputValues();
  };

  const handleChange = (req, value) => {
    setInputValues(prev => ({ ...prev, [req?.name]: value }));
    setInputErrors(prev => ({
      ...prev,
      [req?.name]: !getValidationStatus(
        value,
        req?.required,
        req?.type || req?.schema?.type,
        req?.format || req?.schema?.format,
        req?.enum || req?.schema?.enum
      )
    }));
  };

  return (
    <div className="accordion-cell-container generic-style-for-browsers">
      <div className="test-simulator-desc">
        <div>{endpointContent?.description}</div>
        <div className="test-simulator-heading"> Parameters</div>

        {endpointContent?.request?.filter(f => f.in !== 'body')?.length ===
        0 ? (
          <div>No Parameters</div>
        ) : (
          <table>
            <thead style={{ borderBottom: '1px solid #C4C4C4' }}>
              <tr>
                <StyledTh>Name</StyledTh>
                <StyledTh>Description</StyledTh>
              </tr>
            </thead>
            {endpointContent?.request
              ?.filter(f => f.in !== 'body')
              .map((req, idx) => {
                return (
                  <tbody>
                    <tr>
                      <td className="params-input-resp-body">
                        <div style={{ width: '240px', maxWidth: '772px' }}>
                          <Input
                            enableCustomValidation
                            id={`${req?.name}-idx`}
                            name={req?.name}
                            isRequired={req?.required}
                            isDisabled={isExecuted}
                            value={inputValues[req.name] || ''}
                            onChange={e => handleChange(req, e.target.value)}
                            label={req?.name}
                            size={INPUT_SIZES.SMALL}
                            errorMessage="Provide a valid input"
                            hasError={inputErrors[req?.name]}
                            helperText={`${req?.type ||
                              req?.schema?.type ||
                              ''} (${req?.in})`}
                          />
                        </div>
                      </td>
                      <td className="params-desc">{req?.description}</td>
                    </tr>
                  </tbody>
                );
              })}
            {endpointContent?.request?.filter(f => f.in !== 'body')?.length %
              2 ===
              1 && <div></div>}
          </table>
        )}

        {reqExample && (
          <Fragment>
            <div className="test-simulator-heading"> Request Body</div>
            <ReactJson
              onEdit={
                !isExecuted
                  ? edit => {
                      setReqExample(edit.updated_src);
                      return true;
                    }
                  : false
              }
              onAdd={
                !isExecuted
                  ? add => {
                      setReqExample(add.updated_src);
                      return true;
                    }
                  : false
              }
              onDelete={
                !isExecuted
                  ? del => {
                      setReqExample(del.updated_src);
                      return true;
                    }
                  : false
              }
              {...requestBodyProps}
            />
            {reqErrors.length > 0 && (
              <div className="test-simul-req-body-error-wrapper">
                <Toast
                  content={
                    <div className="test-simul-req-body-error">
                      {ERROR_STATEMENT}
                      <span>
                        <ul>
                          {' '}
                          {reqErrors.map(m => (
                            <li>{m}</li>
                          ))}{' '}
                        </ul>
                      </span>
                    </div>
                  }
                  position={TOAST_POSITIONS.INLINE}
                  visibilityDuration={TOAST_VISIBILITY_DURATIONS.INFINITE}
                  variant={TOAST_VARIANTS.NEGATIVE}
                />
              </div>
            )}
          </Fragment>
        )}

        <div style={{ display: 'flex' }}>
          <PrimaryButton
            style={{ width: '282px' }}
            text={'Execute'}
            onClick={() => {
              setIsExecuted(true);
            }}
            isDisabled={
              Object.values(inputErrors).includes(true) || !!reqErrors.length
            }
          />
          {isExecuted && (
            <SecondaryButton
              style={{ marginLeft: '8px' }}
              icon={<IconSync />}
              onClick={onReset}
              text={'Reset'}
            />
          )}
        </div>
        <div className="test-simulator-heading">Mock Responses</div>

        <table>
          <thead style={{ borderBottom: '1px solid #C4C4C4' }}>
            <tr>
              <StyledTh style={{ width: '22%' }}>Code</StyledTh>
              <StyledTh>Description</StyledTh>
              <StyledTh>Links</StyledTh>
            </tr>
          </thead>
          <tbody>
            {responses.map(resp => {
              return (
                <tr style={{ padding: '24px 0' }}>
                  <StyledTd>
                    <div>{resp?.statusCode}</div>
                  </StyledTd>
                  <StyledTd>
                    <div>{constants.STAUS_CODES[resp?.statusCode]}</div>
                    <div className="test-simul-params-resp">
                      {resp?.example && (
                        <CodeBody data-cy={`apiexplorer-responsecode-body`}>
                          <CodeHighlight language="json">
                            {JSON.stringify(resp?.example, null, 4)}
                          </CodeHighlight>
                        </CodeBody>
                      )}
                    </div>
                  </StyledTd>
                  <StyledTd>
                    <div>No Links</div>
                  </StyledTd>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
