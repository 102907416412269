import { withAuth } from '@cdk-prod/fortellis-auth-context';
import React, { useContext, useState } from 'react';

import './ApiDetails.scss';
import RedirectURL from '../common/RedirectURL';
import ContactSupport from './ContactSupport';
import { formatPhoneNumber } from '../../utils/format-data.js';
import { locationContext } from '../locationContext';
import { SecondaryButton, IconOpenInNew, withTooltip } from 'cdk-radial';
import { Column, Grid, Row } from '@cdk-rds/grid';

const contactTypesMap = {
  general: 'General Questions',
  sales: 'Sales',
  support: 'Technical Support'
};

const APIDetailsHeader = ({ title }) => {
  return <div className="api-details-title">{title}</div>;
};

const renderNoSupportTxt = () => {
  return (
    <div className="api-tab-container generic-style-for-browsers">
      <div className="no-documentation-text">
        The API provider does not have any contact information available at this
        time.
      </div>
    </div>
  );
};

const RedirectURLComponent = props => (
  <RedirectURL
    classes="api-doc-name api-redirect"
    url={
      props.website.startsWith('http')
        ? props.website
        : `http://${props.website}`
    }
    heading={'Visit Site'}
    icon={<IconOpenInNew height={16} width={16} style={{ fill: '#6B2574' }} />}
    {...props}
  />
);

const urlWithTooltipRenderer = props => {
  const tooltipProps = {
    id: 'website-url-id-1',
    text: props.website
  };
  const ComponentWithTooltip = withTooltip(RedirectURLComponent, tooltipProps);
  return <ComponentWithTooltip {...props} />;
};

const RenderSupportInfo = ({ contactInfo, contactType, ...props }) => {
  const [contactSupportModal, setContactSupportModalVisbility] = useState(
    false
  );
  let phoneNumber = contactInfo.phone;
  let email = contactInfo.email;
  let website = contactInfo.url;
  let orgName;
  if (props.orgName) {
    orgName = props.orgName;
  } else if (props.store && props.store.orgName && props.store.orgName.data) {
    orgName = props.store.orgName.data.name;
  }
  let apiName;
  if (props.apiName) {
    apiName = props.apiName;
  } else if (!!props.store?.apiInfo?.data) {
    apiName = props.store.apiInfo.data.name;
  }
  if (phoneNumber || email || website) {
    return (
      <div>
        <div style={{ display: 'inline-flex' }}>
          <div style={{ display: 'inline-block', position: 'relative' }}>
            <div className="contact-information">
              {contactTypesMap[contactType]}
            </div>
            <div className="contact-address">
              {phoneNumber
                ? formatPhoneNumber(phoneNumber) || phoneNumber
                : null}
              {contactInfo.phoneExt ? ' Ext: ' + contactInfo.phoneExt : ''}
              {phoneNumber ? <br /> : null}
            </div>
            {website && urlWithTooltipRenderer({ website: website })}
            {email && (
              <SecondaryButton
                id="ContactSupportButton"
                data-testid="contact-support"
                className="contact-support contact-button-position"
                onClick={() => {
                  setContactSupportModalVisbility(true);
                }}
                text="Contact"
              />
            )}
          </div>
        </div>
        <ContactSupport
          {...props}
          open={contactSupportModal}
          orgName={orgName}
          providerName={contactInfo.name}
          providerSupportEmail={email}
          apiName={apiName}
          onClose={() => {
            setContactSupportModalVisbility(false);
          }}
        />
      </div>
    );
  } else {
    return null;
  }
};

const filterContactInfo = apiInfo => {
  let contactData = {};
  if (
    apiInfo &&
    apiInfo.data &&
    apiInfo.data.contact &&
    Object.keys(apiInfo.data.contact).length
  ) {
    Object.keys(apiInfo.data.contact).forEach(contactType => {
      if (
        Object.keys(contactTypesMap).includes(contactType) &&
        typeof apiInfo.data.contact[contactType] === 'object' &&
        (apiInfo.data.contact[contactType].email ||
          apiInfo.data.contact[contactType].phone ||
          apiInfo.data.contact[contactType].url)
      ) {
        contactData[contactType] = apiInfo.data.contact[contactType];
      }
    });
  }
  return contactData;
};

const ApiSupport = props => {
  const { setCurrentLocation } = useContext(locationContext);
  const contacts = filterContactInfo(props.store.apiInfo);
  let apiName;
  if (props.apiName) {
    apiName = props.apiName;
  } else if (props.store && props.store.apiInfo && props.store.apiInfo.data) {
    apiName = props.store.apiInfo.data.name;
  }
  return (
    <React.Fragment>
      <div className="api-tab-container generic-style-for-browsers">
        <APIDetailsHeader title="API Contacts" />
        <div className="contact_text_description" data-cy="contact-description">
          {`For support with ${apiName}, please contact us via the contact option(s) found below.`}
        </div>
        {Object.keys(contacts).length ? (
          <Grid>
            <Row style={{ padding: '0px' }}>
              {Object.keys(contacts).map(contactType => {
                return (
                  <Column l={4} m={4} s={6} xs={6}>
                    <RenderSupportInfo
                      contactType={contactType}
                      contactInfo={contacts[contactType]}
                      {...props}
                    />
                  </Column>
                );
              })}
            </Row>
          </Grid>
        ) : (
          renderNoSupportTxt()
        )}
      </div>
    </React.Fragment>
  );
};

export default withAuth(ApiSupport);
