import React, { useState, useEffect } from 'react';
import { ApiSearchGroupItem } from './ApiSearchGroupItem';
import { searchParams } from '../../../../utils/data-utils';

export const getSearchItems = (searchItems, allSearchItems) => {
  let locAllSearchItems = {};
  const searchKey = Object.keys(searchItems)[0];

  locAllSearchItems = JSON.parse(JSON.stringify(allSearchItems));
  locAllSearchItems[searchKey] = {
    ...locAllSearchItems[searchKey],
    ...searchItems[searchKey]
  };

  return locAllSearchItems;
};

export const ApiSearchGroup = ({
  domains,
  setSearchFilters,
  selectedDomainIds,
  selectedApiType,
  selectedOrgs
}) => {
  const [allSearchItems, setAllSearchItems] = useState({});
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    if (!!domains?.items) {
      setFilters({
        ...searchParams,
        category: {
          text: 'Categories',
          values: createFilters(domains, 'Categories')
        }
        // org: {
        //   text: 'Publishers',
        //   values: createFilters(domains, 'Publishers') // Below code block is being commented out to implement FORTDNP-1755
        // }
      });
    }
  }, [domains]);

  useEffect(() => {
    const apiTypeSet = {
      'REST APIs': 'restApi',
      'Async APIs': 'asyncApi',
      Bundles: 'bundles'
    };

    if (
      !!selectedDomainIds &&
      !!selectedApiType &&
      !!selectedOrgs &&
      !!allSearchItems
    ) {
      const locSelectedApiType = Array.isArray(selectedApiType)
        ? selectedApiType
        : [selectedApiType];
      const locApiTypes = !!allSearchItems['apiType']
        ? Object.keys(allSearchItems['apiType']).filter(
            f => allSearchItems['apiType'][f]
          )
        : [];
      const locCategories = !!allSearchItems['category']
        ? Object.keys(allSearchItems['category']).filter(
            f => allSearchItems['category'][f]
          )
        : [];
      const locOrgs = !!allSearchItems['org']
        ? Object.keys(allSearchItems['org']).filter(
            f => allSearchItems['org'][f]
          )
        : [];

      const propsApiTypes = locSelectedApiType.map(m => apiTypeSet[m]);

      if (
        JSON.stringify(locApiTypes) !== JSON.stringify(propsApiTypes) ||
        JSON.stringify(locCategories) !== JSON.stringify(selectedDomainIds) ||
        JSON.stringify(locOrgs) !== JSON.stringify(selectedOrgs)
      ) {
        let locObjApiType = {};
        let locObjCategories = {};
        let locObjOrgs = {};

        propsApiTypes.forEach(
          f => (locObjApiType = { ...locObjApiType, [f]: true })
        );
        !!selectedDomainIds &&
          Array.isArray(selectedDomainIds) &&
          selectedDomainIds.forEach(
            f => (locObjCategories = { ...locObjCategories, [f]: true })
          );
        !!selectedOrgs &&
          Array.isArray(selectedOrgs) &&
          selectedOrgs.forEach(
            f => (locObjOrgs = { ...locObjOrgs, [f]: true })
          );
        const localAllSearchItems = {
          apiType: locObjApiType,
          category: locObjCategories,
          org: locObjOrgs
        };

        setAllSearchItems(localAllSearchItems);
        setSearchFilters(localAllSearchItems);
      }
    }
  }, [selectedDomainIds, selectedApiType, selectedOrgs, allSearchItems]);

  const createFilters = (domains, type) => {
    const domainTypes = {
      Categories: 'CATEGORIES',
      Publishers: 'PROVIDERS'
    };
    let filterArr = [];

    const localFilters = domains.items.filter(
      f => f.name.toUpperCase() === domainTypes[type]
    )[0]?.dimensions;

    localFilters.forEach(
      f => (filterArr = [...filterArr, { key: f.id, value: f.name }])
    );

    return filterArr;
  };

  const handleSetSearchItems = searchItems => {
    const locAllSearchItems = getSearchItems(searchItems, allSearchItems);

    setAllSearchItems(locAllSearchItems);
    setSearchFilters(locAllSearchItems);
  };

  const getSelectedFilters = searchKey => {
    let searchFilters = [];
    switch (searchKey) {
      case 'apiType':
        searchFilters = selectedApiType;
        break;
      case 'category':
        searchFilters = selectedDomainIds;
        break;
      default:
        searchFilters = selectedOrgs;
        break;
    }
    return searchFilters;
  };

  return (
    <>
      <ul className="search-group-items">
        {Object.keys(filters).map((m, sId) => (
          <div key={sId}>
            <ApiSearchGroupItem
              keyId={sId}
              searchParams={filters[m]}
              searchKey={m}
              selectedFilters={getSelectedFilters(m)}
              setSearchFilters={handleSetSearchItems.bind(this)}
            />
            <div className="search-group-border" />
          </div>
        ))}
      </ul>
    </>
  );
};
