import React, { useState, useEffect } from 'react';
import { Checkbox, CheckboxGroup, IconArrowDropDown } from 'cdk-radial';
import { Tooltip } from '@rmwc/tooltip';

export const ApiSearchGroupItem = ({
  keyId,
  searchParams,
  searchKey,
  selectedFilters,
  setSearchFilters
}) => {
  const [searchExpander, setSearchExpander] = useState(false);
  const [selectedSearchItems, setSelectedSearchItems] = useState({});
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    if (!!searchKey && searchParams?.values?.length > 0) {
      const locSearchData = [];
      searchParams.values.forEach(f => {
        locSearchData.push({
          label: f.value,
          name: f.key,
          searchKey: searchKey
        });
      });

      setSearchData(locSearchData);
    }
  }, [searchParams, searchKey]);

  useEffect(() => {
    const selectedFilterType = selectedFilters;

    if (
      !!searchKey &&
      !!selectedFilterType &&
      Array.isArray(selectedFilterType) &&
      searchParams?.values?.length > 0
    ) {
      let locSelectedFilters = {};
      let preSelectedFilters = {};

      selectedFilterType.forEach(ftr => {
        let locfilter = '';
        if (searchKey === 'apiType') {
          locfilter = searchParams.values.find(f => f.value === ftr);
        } else {
          locfilter = searchParams.values.find(f => f.key === ftr);
        }

        if (!!locfilter) {
          locSelectedFilters = {
            ...locSelectedFilters,
            [locfilter['key']]: true
          };
        }
      });

      searchData.forEach(sd => {
        if (!!selectedSearchItems[sd.name]) {
          preSelectedFilters = { ...preSelectedFilters, [sd.name]: false };
        }
      });

      const locSelectedSearchItems = {
        ...selectedSearchItems,
        ...preSelectedFilters,
        ...locSelectedFilters
      };

      const locSearchItems = {
        ...preSelectedFilters,
        ...locSelectedFilters
      };

      if (
        JSON.stringify(locSelectedSearchItems) !==
        JSON.stringify(selectedSearchItems)
      ) {
        setSelectedSearchItems(locSearchItems);
        setSearchFilters({
          [searchKey]: locSearchItems
        });
        !!Object.keys(locSelectedFilters).length && setSearchExpander(true);
      }
    }
  }, [selectedFilters, searchParams]);

  const handleExpanderClick = _ => {
    setSearchExpander(!searchExpander);
  };

  const getFilterText = filterText => {
    if (filterText.length > 20) {
      return `${filterText.substring(0, 17)}...`;
    } else {
      return filterText;
    }
  };

  const getCheckedStatus = key => {
    return !!Object.keys(selectedSearchItems).find(
      f => f === key && !!selectedSearchItems[f]
    );
  };

  const handleSearchItemClick = (e, itemKey) => {
    e.preventDefault();
    setSelectedSearchItems({
      ...selectedSearchItems,
      [itemKey]: !e.target.checked
    });

    setSearchFilters({
      [searchKey]: {
        [itemKey]: !e.target.checked
      }
    });
  };

  return (
    <>
      <div className="search-group-item">
        {searchParams?.values?.length > 0 && (
          <div
            className="api-search-block"
            onClick={handleExpanderClick.bind(this)}
          >
            <li key={keyId}>{searchParams.text}</li>
            <div className={searchExpander ? 'search-group-expander-down' : ''}>
              <IconArrowDropDown />
            </div>
          </div>
        )}
        {searchExpander && (
          <div className="search-group-item-param">
            <ul className="search-filter-group-items">
              {searchData
                .filter(f => f.searchKey === searchKey)
                .map((sd, idx) => (
                  <li key={idx} className="search-filter-group-item-wrapper">
                    <Checkbox
                      name={`searchData-${idx}`}
                      checked={getCheckedStatus(sd.name)}
                      label=""
                      hideLabel
                      onChange={() => {}}
                      onClick={e => handleSearchItemClick(e, sd.name)}
                    />
                    <div className="search-filter-group-item">
                      {sd.label.length > 20 ? (
                        <Tooltip content={sd.label}>
                          <span>{getFilterText(sd.label)}</span>
                        </Tooltip>
                      ) : (
                        sd.label
                      )}
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
