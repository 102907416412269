import React, { Component, Fragment } from 'react';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import {
  cleanName,
  prepareSpec,
  replaceParameters,
  addEndpointToPaths,
  getEnvironmentName
} from '../../utils/spec-utils';
import ApiEndpoint from './apiEndpoint';
import { TestSimulator } from './apiEndpoint/TestSimulator';
import { API_VERSION_STATUS, API_ENVIRONMENTS } from './apiEndpoint/constants';
import { subscriptionId, authorization } from '../../config/defaultModels';
import '@rmwc/select/styles';
import { Select } from '@rmwc/select';
import { Snackbar } from '@material/react-snackbar';
import { IconFileDownload, TextButton, fortellisTheme } from 'cdk-radial';
import { ThemeProvider } from 'styled-components';
import {
  Endpoints,
  EndpointsList,
  EndpointDetails,
  EndpointListItem,
  EndpointListInfo,
  EndpointListName,
  EndpointListMethod,
  ApiHeaderDetails,
  ApiVersionSection,
  ApiReleaseType,
  ProxyDetails,
  ReleaseTag
} from './styledComponents';

const parameterReplacements = [subscriptionId, authorization];
const endpointReplacements = [];

const APIDetailsHeader = ({ title }) => {
  return <div className="api-details-title">{title}</div>;
};

const noVersionsText = auth => {
  return auth.loginPending ? (
    ''
  ) : (
    <div className="no-documentation-text api-spec-text">
      Public versions are not available.
      {'\n'}
      <a
        className="cursor-pointer"
        onClick={() => {
          auth.login();
        }}
      >
        Sign in
      </a>{' '}
      to view more information or contact API provider.
    </div>
  );
};

const SpecDownload = ({ downloadSpec, content }) => {
  return (
    <TextButton
      className="download_spec_btn"
      data-cy="download_spec_btn"
      id="downloadSpec"
      text="API SPEC"
      onClick={() => {
        downloadSpec(content);
      }}
      icon={<IconFileDownload />}
    />
  );
};

class Apiexplorer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endpoint: null,
      content: null,
      showToaster: false,
      selectedEnvironment: null,
      selectedVersion: null,
      lifeCycleStatus: null,
      env: [],
      specToDisplay: null,
      environment: [],
      environments: {},
      versions: [],
      version: [],
      proxyUrl: null,
      namespace: null
    };
  }

  copyToClipBoard = async e => {
    try {
      await navigator.clipboard.writeText(e.currentTarget.innerText);
      this.setState({
        showToaster: true,
        message: 'Copied to clipboard'
      });
      setTimeout(() => this.setState({ showToaster: false }), 500);
    } catch (e) {
      console.log(e);
      this.setState({
        showToaster: true,
        message: 'Failed to Copy'
      });
    }
  };

  componentDidMount = async () => {
    const apiData = this.props.api;

    if (!this.props.auth.isAuthenticated) {
      const prodInstance = apiData?.instances?.find(
        instance =>
          instance.environment === 'prod' &&
          (instance.lifecycleStatus === API_VERSION_STATUS.GENERAL_RELEASE ||
            instance.lifecycleStatus === null)
      );

      const specContent = prodInstance
        ? await prepareSpec(prodInstance.spec)
        : {};
      this.setState({
        selectedEnvironment: 'Production',
        selectedVersion: prodInstance ? prodInstance.version : '',
        content: specContent,
        namespace: prodInstance ? prodInstance?.config?.namespace : '',
        proxyUrl: prodInstance ? prodInstance.config?.proxyUrl : ''
      });
    } else {
      this.generateEnvironmentMap();
    }
  };

  componentDidUpdate = async prevProps => {
    if (!prevProps.auth.isAuthenticated && this.props.auth.isAuthenticated) {
      this.generateEnvironmentMap();
    }
  };

  setEndpoint = endpoint => {
    this.setState({ endpoint });
  };

  generateEnvironmentMap = () => {
    const apiData = this.props.api;
    apiData.environments.forEach(environment => {
      if (environment.name === API_ENVIRONMENTS.Production) {
        environment.name = 'Production';
      }
      if (environment.name === API_ENVIRONMENTS.Test) {
        environment.name = 'Test';
      }
      let version = [];
      environment.instances.forEach(inst => {
        version.push({
          [inst.version]: {
            lifecycleStatus: inst.lifecycleStatus,
            spec: inst.spec,
            proxy: inst.config.proxyUrl,
            namespace: inst.config.namespace
          }
        });
      });
      this.state.env.push({ [environment.name]: version });
    });

    this.setState(
      {
        environments: apiData.environments.reduce(
          (acc, env) => ({ ...acc, [env.name]: env }),
          {}
        ),
        selectedEnvironment: getEnvironmentName(apiData.environments)
      },
      () => {
        this.handleEnvironmentChange(this.state.selectedEnvironment);
      }
    );
  };

  modifySchema = schema => {
    schema.paths = addEndpointToPaths(schema.paths, endpointReplacements);
    for (const [, operations] of Object.entries(schema.paths)) {
      for (const [, operation] of Object.entries(operations)) {
        operation.parameters = replaceParameters(
          operation.parameters,
          parameterReplacements
        );
      }
    }

    return schema;
  };

  generateEndpointList = schema => {
    const endpointList = [];
    for (const [path, operations] of Object.entries(schema.paths)) {
      for (const [verb, operation] of Object.entries(operations)) {
        endpointList.push({
          method: verb,
          name: operation.operationId || path,
          path: path,
          schema: operation,
          key: verb + '-' + path
        });
      }
    }
    return endpointList;
  };

  handleEnvironmentChange = env => {
    const versionList = this.state.environments[env]?.instances.map(
      inst => inst.version
    );

    versionList &&
      this.setState(
        {
          selectedEnvironment: env,
          versions: versionList,
          selectedVersion: versionList[0]
        },
        () => {
          this.handleVersionChange(this.state.versions[0]);
        }
      );
  };

  async handleVersionChange(version, selectedIndex = 0) {
    const instances = this.state.environments[this.state.selectedEnvironment]
      .instances;
    const instance = this.state.environments[
      this.state.selectedEnvironment
    ].instances.find(
      inst =>
        inst.version === version && instances.indexOf(inst) === selectedIndex
    );
    const specSelectedVersion = instance.spec;
    const proxyUrlSelectedVersion = instance.config.proxyUrl;
    const lifeCycleStatusSelectedVersion = instance.lifecycleStatus;
    const instanceNamespace = instance.config.namespace;
    const specContent = await prepareSpec(specSelectedVersion);

    this.setState({
      selectedVersion: version,
      content: specContent,
      proxyUrl: proxyUrlSelectedVersion,
      lifeCycleStatus: lifeCycleStatusSelectedVersion,
      namespace: instanceNamespace
    });
  }

  render() {
    const {
      state: { endpoint, content, proxyUrl, lifeCycleStatus }
    } = this;
    let schema = content ? this.modifySchema(content) : content;
    let endpoints = schema ? this.generateEndpointList(schema) : [];
    const { auth = {}, downloadSpec } = this.props;
    const { isAuthenticated = false } = auth;
    const customLifeCycleStatus = !Object.values(API_VERSION_STATUS).includes(
      lifeCycleStatus
    );
    const environmentList = Object.keys(this.state.environments).filter(
      env => env !== 'dev'
    );
    return (
      <ThemeProvider theme={fortellisTheme}>
        <div className="api-tab-container generic-style-for-browsers">
          <ApiHeaderDetails className="header-section">
            <div className="header-content">
              <div className="api-spec-and-proxyUrl">
                <APIDetailsHeader title="API Specifications" />
                {isAuthenticated && (
                  <Fragment>
                    {proxyUrl && (
                      <ProxyDetails className="proxy-details">
                        <span>Proxy URL: </span>
                        <span onClick={this.copyToClipBoard}>
                          {this.state.proxyUrl}
                        </span>
                      </ProxyDetails>
                    )}
                  </Fragment>
                )}
              </div>
              <ApiVersionSection className="version-details">
                {!isAuthenticated && (
                  <Fragment>
                    {this.state.selectedVersion ? (
                      <Fragment>
                        <div className="version-id">
                          Version: {this.state.selectedVersion}
                        </div>
                        <div className="api-release-download-container">
                          <ApiReleaseType className="general">
                            General Release
                          </ApiReleaseType>
                        </div>
                        <SpecDownload
                          downloadSpec={downloadSpec}
                          content={content}
                          version={this.state.selectedVersion}
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <SpecDownload
                          downloadSpec={downloadSpec}
                          content={content}
                        />
                      </Fragment>
                    )}
                  </Fragment>
                )}
                {isAuthenticated && (
                  <Fragment>
                    {environmentList.length > 0 ||
                    this.state.versions.length > 0 ? (
                      <Fragment>
                        {Object.keys(this.state.environments).length > 0 && (
                          <Select
                            className="api-env-list"
                            label="Environment"
                            onChange={evt =>
                              this.handleEnvironmentChange(evt.target.value)
                            }
                            value={this.state.selectedEnvironment}
                            options={environmentList}
                          ></Select>
                        )}

                        {this.state.versions.length > 0 && (
                          <Select
                            className="api-version-list"
                            value={this.state.selectedVersion}
                            label="Version"
                            onChange={evt =>
                              this.handleVersionChange(
                                evt.target.value,
                                evt.target.index
                              )
                            }
                            options={this.state.versions}
                          ></Select>
                        )}
                        {lifeCycleStatus ? (
                          <div className="api-release-download-container">
                            <SpecDownload
                              content={content}
                              downloadSpec={downloadSpec}
                            />
                            {lifeCycleStatus === API_VERSION_STATUS.BETA && (
                              <ReleaseTag
                                className="fdn-api-versions__badge"
                                badgeColor="#FA9C19"
                              >
                                Beta
                              </ReleaseTag>
                            )}
                            {lifeCycleStatus ===
                              API_VERSION_STATUS.GENERAL_RELEASE && (
                              <ReleaseTag
                                className="fdn-api-versions__badge"
                                badgeColor="#09AD00"
                              >
                                General Release
                              </ReleaseTag>
                            )}
                            {lifeCycleStatus ===
                              API_VERSION_STATUS.DEPRECATED && (
                              <ReleaseTag
                                className="fdn-api-versions__badge"
                                badgeColor="#074FAF"
                              >
                                Deprecated
                              </ReleaseTag>
                            )}

                            {customLifeCycleStatus && (
                              <ReleaseTag
                                className="fdn-api-versions__badge"
                                badgeColor="#074FAF"
                              >
                                {lifeCycleStatus}
                              </ReleaseTag>
                            )}
                          </div>
                        ) : (
                          <SpecDownload
                            content={content}
                            downloadSpec={downloadSpec}
                          />
                        )}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <SpecDownload
                          content={content}
                          downloadSpec={downloadSpec}
                        />
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </ApiVersionSection>
            </div>
          </ApiHeaderDetails>
          {((!this.props.oneToOneApiAnon && this.state.versions.length === 0) ||
            (this.props.oneToOneApiAnon &&
              (!endpoints || endpoints.length === 0))) &&
            noVersionsText(this.props.auth)}
          <EndpointDetails>
            <div className="endpoints-list-wrapper">
              <EndpointsList>
                <Endpoints className="endpoints-list">
                  {endpoints.map(item => {
                    return (
                      <EndpointListItem key={item.key} id={item.key}>
                        <EndpointListInfo
                          onClick={() => this.setEndpoint(item)}
                          method={item.method}
                          data-cy={`apiexplorer-endpoint-${item.key}`}
                          isSelected={
                            endpoint
                              ? item.key === endpoint.key
                              : item.key === endpoints[0].key
                          }
                        >
                          <EndpointListMethod method={item.method}>
                            {item.method}
                          </EndpointListMethod>
                          <EndpointListName method={item.method}>
                            {cleanName(item.name)}
                          </EndpointListName>
                        </EndpointListInfo>
                      </EndpointListItem>
                    );
                  })}
                </Endpoints>
              </EndpointsList>
              {content && (
                <div>
                  <ApiEndpoint
                    schema={content}
                    endpoint={endpoint || endpoints[0]}
                    environment={
                      this.state.selectedEnvironment === 'Test'
                        ? API_ENVIRONMENTS.Test
                        : API_ENVIRONMENTS.Production
                    }
                    namespace={this.state.namespace}
                    proxyUrl={this.state.proxyUrl}
                  />
                </div>
              )}
            </div>
            {!!endpoints && endpoints.length > 0 && (
              <div className="try-it-out-holder">
                <TestSimulator
                  schema={content}
                  endpoint={endpoint || endpoints[0]}
                />
              </div>
            )}
          </EndpointDetails>
          <Snackbar
            open={this.state.showToaster}
            message={this.state.message}
          />
        </div>
      </ThemeProvider>
    );
  }
}

export default withAuth(Apiexplorer);
