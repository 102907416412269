import React, { Component } from 'react';
import mergeAllOf from 'json-schema-merge-allof';
import { BODY_TYPES } from './constants';
import { Button, BUTTON_VARIANTS } from 'cdk-radial';
import {
  OuterSchemaContainer,
  ButtonLayout,
  SchemaContainer,
  SchemaElement,
  ElementTitleContainer,
  ElementLabel,
  ElementType,
  ElementRequired,
  Description,
  Decorative,
  ExpandToggleInline
} from '../styledComponents';

class SchemaStructure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  handleExpand() {
    this.setState({ expanded: true });
  }

  handleCollapse() {
    this.setState({ expanded: false });
  }

  processSchema(schema) {
    const processedSchema = mergeAllOf(schema, {
      resolvers: {
        type: mergeAllOf.options.resolvers.title,
        defaultResolver: mergeAllOf.options.resolvers.title
      }
    });

    return processedSchema;
  }

  render() {
    const { schema, type, structure } = this.props;
    const { expanded } = this.state;

    const processedSchema = this.processSchema(schema);
    if (!processedSchema.properties && !processedSchema.items) {
      return <div />;
    }

    return (
      <OuterSchemaContainer>
        <ButtonLayout>
          {!expanded && (
            <Button
              text="Expand All"
              variant={BUTTON_VARIANTS.SECONDARY}
              dataTestId={`apiexplorer-${structure}-expand-all`}
              onClick={() => this.handleExpand()}
            />
          )}
          {expanded && (
            <Button
              text="Collapse All"
              variant={BUTTON_VARIANTS.SECONDARY}
              dataTestId={`apiexplorer-${structure}-collapse-all`}
              onClick={() => this.handleCollapse()}
            />
          )}
        </ButtonLayout>
        <NestedSchema
          schema={processedSchema}
          expanded={expanded}
          type={type}
        />
      </OuterSchemaContainer>
    );
  }
}

const NestedSchema = ({ schema, expanded, type }) => {
  if (!schema) {
    return null;
  }

  const renderTopLevel = () => {
    if (schema.type === 'object' || schema.properties) {
      const properties =
        schema.properties ||
        (schema.additionalProperties && schema.additionalProperties.properties);
      if (!properties) {
        return null;
      }
      return Object.entries(properties).map(prop => {
        const name = prop[0];
        const newSchema = prop[1];
        const required =
          type === BODY_TYPES.request &&
          schema.required &&
          schema.required.length
            ? schema.required.includes(name)
            : false;
        if (newSchema.type === 'object' || newSchema.properties) {
          return (
            <ObjectElement
              key={name}
              name={name}
              schema={newSchema}
              expanded={expanded}
              required={required}
              type={type}
            />
          );
        } else if (newSchema.type === 'array' || newSchema.items) {
          return (
            <ArrayElement
              key={name}
              name={name}
              schema={newSchema}
              expanded={expanded}
              required={required}
              type={type}
            />
          );
        } else {
          return (
            <PrimitiveElement
              key={name}
              name={name}
              schema={newSchema}
              required={required}
            />
          );
        }
      });
    } else if (schema.type === 'array' || schema.items) {
      return (
        <ArrayElement
          key={'topLevelArray'}
          name={schema.name || ''}
          schema={schema}
          expanded={expanded}
        />
      );
    }
    return null;
  };

  return <SchemaContainer>{renderTopLevel()}</SchemaContainer>;
};

class ObjectElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: props.expanded || false,
      onlyInlineExpanded: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.expanded !== this.props.expanded ||
      nextProps.expanded !== this.state.expanded
    ) {
      this.setState({
        expanded: nextProps.expanded,
        onlyInlineExpanded: false
      });
    }
  }

  handleToggle() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded, onlyInlineExpanded: true });
  }

  render() {
    const { name, schema, required, type } = this.props;
    const { expanded, onlyInlineExpanded } = this.state;
    return (
      <SchemaElement>
        <div>
          <ElementTitle
            name={name}
            type={'object'}
            toggleExpand={() => this.handleToggle()}
            expanded={expanded}
            required={required}
          />
          <ElementDescription>
            {schema.description || schema.title}
          </ElementDescription>
          {expanded && (
            <NestedSchema
              schema={schema}
              expanded={onlyInlineExpanded ? false : expanded}
              type={type}
            />
          )}
        </div>
      </SchemaElement>
    );
  }
}

class ArrayElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: props.expanded || false,
      onlyInlineExpanded: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.expanded !== this.props.expanded ||
      nextProps.expanded !== this.state.expanded
    ) {
      this.setState({
        expanded: nextProps.expanded,
        onlyInlineExpanded: false
      });
    }
  }

  handleToggle() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded, onlyInlineExpanded: true });
  }

  renderChildren({ schema, expanded }) {
    if (schema.items) {
      if (schema.items.properties) {
        return (
          <NestedSchema
            schema={schema.items}
            expanded={expanded}
            type={this.props.type}
          />
        );
      } else if (schema.items.additionalProperties) {
        return (
          <NestedSchema
            schema={schema.items.additionalProperties}
            expanded={expanded}
            type={this.props.type}
          />
        );
      } else if (schema.items.type) {
        return <ElementType>({schema.items.type})</ElementType>;
      }
    }
    return null;
  }

  render() {
    const { name, schema, required } = this.props;
    const { expanded, onlyInlineExpanded } = this.state;
    return (
      <SchemaElement>
        <div>
          <ElementTitle
            name={name}
            type={'array'}
            toggleExpand={() => this.handleToggle()}
            expanded={expanded}
            required={required}
          />
          <ElementDescription>
            {schema.description || schema.title}
          </ElementDescription>
          {schema.items && (
            <>
              <Decorative>{'['}</Decorative>
              {expanded ? (
                <>
                  {this.renderChildren({
                    schema,
                    expanded: onlyInlineExpanded ? false : expanded
                  })}
                </>
              ) : (
                <span>...</span>
              )}
              <Decorative>{']'}</Decorative>
            </>
          )}
        </div>
      </SchemaElement>
    );
  }
}

const PrimitiveElement = ({ name, schema, required }) => {
  return (
    <SchemaElement>
      <div>
        <ElementTitle name={name} type={schema.type} required={required} />
        <PrimitiveDetails schema={schema} />
      </div>
    </SchemaElement>
  );
};

const PrimitiveDetails = ({ schema }) =>
  schema ? (
    <div>
      {
        <ElementDescription>
          {schema.description || schema.title}
        </ElementDescription>
      }
      {schema.enum && <ElementEnum>{schema.enum}</ElementEnum>}
    </div>
  ) : null;

const ElementDescription = props => {
  if (props.children) {
    return typeof props.children === 'string' ? (
      <Description>{props.children}</Description>
    ) : (
      <div>{props.children}</div>
    );
  }
  return null;
};

const ElementEnum = props => {
  if (props.children) {
    return Array.isArray(props.children) ? (
      <Description>
        Possible values include: {props.children.join(', ')}
      </Description>
    ) : (
      <div>{props.children}</div>
    );
  }
  return null;
};

const ElementTitle = ({ name, type, toggleExpand, expanded, required }) => (
  <ElementTitleContainer>
    <ExpandToggle
      name={name}
      type={type}
      onToggle={toggleExpand}
      expanded={expanded}
    />
    <ElementLabel>{name}</ElementLabel>
    <ElementType>({type})</ElementType>
    {required && <ElementRequired>* required</ElementRequired>}
  </ElementTitleContainer>
);

class ExpandToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: props.expanded || false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expanded !== this.props.expanded) {
      this.setState({ expanded: this.props.expanded });
    }
  }

  render() {
    const { type, onToggle, name } = this.props;
    const { expanded } = this.state;

    return (
      <ExpandToggleInline
        data-cy={`apiexplorer-${name}-expand-toggle`}
        type={type}
        onClick={() => onToggle && onToggle()}
      >
        {expanded ? '-' : '>'}
      </ExpandToggleInline>
    );
  }
}

export default SchemaStructure;
