import React, { useEffect, useRef, useLayoutEffect, useState } from 'react';
import { AdvancedPlanTile } from './AdvancedPlanTile';
import { IconChevronRight, IconChevronLeft } from 'cdk-radial';

import scssVariables from './AdvancedPlans.scss';

const PLAN_TYPE_ORDER = {
  custom: 1,
  basic: 2,
  essentials: 3,
  plus: 4,
  pro: 5
};

export const AdvancedPlans = ({
  instanceIdVersionMap,
  plans,
  isAsync,
  allEndpoints
}) => {
  const [showArrows, setShowArrows] = useState(false);
  const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState(true);
  const [isRightArrowDisabled, setIsRightArrowDisabled] = useState(true);
  const [indexStart, setIndexStart] = useState(null);
  const [plansToRender, setPlansToRender] = useState([]);
  const [width, setWidth] = useState(0);
  const [maxDisplay, setMaxDisplay] = useState(4);

  const ref = useRef();

  useLayoutEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, []);

  // Set max to display based on how many plans can fit in the container
  useEffect(() => {
    const tileWidth = parseInt(scssVariables['tile-width'] || 0);
    const tileMargin = parseInt(scssVariables['tile-margin'] || 0);
    if (width && tileWidth && tileMargin) {
      // width + left margin + right margin + little wiggle room
      const totalTileWidth = tileWidth + tileMargin + tileMargin + 2;
      const tilesToDisplay = Math.floor(width / totalTileWidth);
      setMaxDisplay(tilesToDisplay);
    }
  }, [width, scssVariables]);

  useEffect(() => {
    // Move custom plans to start
    // Sort remaining in basic, essentials, plus, pro order
    plans.sort((a, b) => {
      if (PLAN_TYPE_ORDER[a.plan] && PLAN_TYPE_ORDER[b.plan]) {
        return PLAN_TYPE_ORDER[a.plan] - PLAN_TYPE_ORDER[b.plan];
      } else {
        return 1; // someone added a new plan type that isn't on the defined list
      }
    });

    // Set arrows
    let length = plans.length || 0;
    let isMoreThanMaxPlans = length > maxDisplay ? true : false;
    setIndexStart(0);
    setShowArrows(isMoreThanMaxPlans);
    setIsRightArrowDisabled(!isMoreThanMaxPlans);
  }, [plans, maxDisplay]);

  useEffect(() => {
    let plansToRender = plans.slice(indexStart, indexStart + maxDisplay);
    setPlansToRender(plansToRender);
    if (indexStart + maxDisplay >= plans.length) {
      setIsRightArrowDisabled(true);
    } else {
      setIsRightArrowDisabled(false);
    }
    setIsLeftArrowDisabled(indexStart === 0 ? true : false);
  }, [indexStart, maxDisplay]);

  return (
    <div className="advance-plans-container" ref={ref}>
      <div className="advance-pricing-carousel">
        {showArrows && (
          <div className="arrow-left">
            <button
              className={`arrow-button ${isLeftArrowDisabled &&
                'arrow-disabled'}`}
              onClick={() => {
                setIndexStart(indexStart - maxDisplay);
              }}
              disabled={isLeftArrowDisabled}
            >
              <IconChevronLeft height={24} width={24} />
            </button>
          </div>
        )}
        <div
          className="advance-plans"
          style={{
            gridTemplateColumns: `repeat( ${plans.length}, 1fr)`,
            MsGridColumns: `repeat( ${plans.length}, 1fr)`
          }}
        >
          {plansToRender.map((p, idx) => (
            <AdvancedPlanTile
              key={idx}
              plan={p}
              isAsync={isAsync}
              allEndpoints={allEndpoints}
              instanceIdVersionMap={instanceIdVersionMap}
            />
          ))}
        </div>
        {showArrows && (
          <div className="arrow-right">
            <button
              className={`arrow-button ${isRightArrowDisabled &&
                'arrow-disabled'}`}
              onClick={() => {
                setIndexStart(indexStart + maxDisplay);
              }}
              disabled={isRightArrowDisabled}
            >
              <IconChevronRight height={24} width={24} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
