import React, { Component } from 'react';
import ParameterTable from './ParameterTable';
import { cleanName } from '../../../utils/spec-utils';
import '../try-it-out.scss';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeadItem,
  TableItem,
  TableRow,
  DescriptionContainer,
  ApiEndpointMethod,
  ApiEndpointName,
  EndpointSection,
  EndpointDescription,
  EndpointDetail,
  EndpointDetailTitle,
  EndpointDetailContent,
  ParametersSection,
  TextSection,
  InfoSection,
  Title,
  Subtitle,
  URLSection
} from '../styledComponents';

import config from '../../../config/config';
import { ResponseBodySection } from './ResponseBodySection';
import { RequestBodySection } from './RequestBodySection';

class ApiEndpoint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tryoutOpen: false,
      isPopOver: false
    };
  }

  getParameterType = type => {
    const { endpoint } = this.props;
    if (endpoint && endpoint.schema && endpoint.schema.parameters) {
      return endpoint.schema.parameters.filter(param => param.in === type);
    }
    return [];
  };

  toggleModal = () => {
    this.setState({ tryoutOpen: !this.state.tryoutOpen });
  };

  render() {
    const { schema, endpoint, namespace, environment, proxyUrl } = this.props;
    let url = '';
    const isOas3Spec = !!schema?.openapi && schema.openapi.indexOf('3') > -1;

    // OpenApi 3.x.x spec
    if (isOas3Spec) {
      url = `${proxyUrl}${endpoint.path}`;
    } else {
      // Swagger 2.0 spec
      if (namespace) {
        url =
          environment !== 'prod'
            ? `${config.api.fortellisApiUrl}/${namespace}-${environment}${schema.basePath}${endpoint.path}`
            : `${config.api.fortellisApiUrl}/${namespace}${schema.basePath}${endpoint.path}`;
      } else {
        url = endpoint
          ? `${config.api.fortellisApiUrl}${schema.basePath}${endpoint.path}`
          : '';
      }
    }
    const pathParams = this.getParameterType('path');
    const headerParams = this.getParameterType('header');
    const queryParams = this.getParameterType('query');
    let bodyParam = this.getParameterType('body');
    bodyParam = bodyParam.length > 0 ? bodyParam[0] : undefined;
    const { schema: { description = '' } = {} } = endpoint || {};

    return (
      <React.Fragment>
        {endpoint ? (
          <React.Fragment>
            <DescriptionContainer>
              <ApiEndpointMethod method={endpoint.method}>
                {endpoint.method}
              </ApiEndpointMethod>
              <ApiEndpointName data-cy={`apiexplorer-endpoint-name`}>
                {` - ${cleanName(endpoint.name)}`}
              </ApiEndpointName>
              <EndpointDescription data-cy={`apiexplorer-endpoint-description`}>
                {description && description.length ? description : ''}
              </EndpointDescription>
            </DescriptionContainer>

            <EndpointSection>
              <InfoSection>
                <Title>Resource URL</Title>
                <TextSection data-cy={`apiexplorer-endpoint-url`}>
                  <URLSection>{url}</URLSection>
                </TextSection>
              </InfoSection>

              <InfoSection>
                {(schema.schemes || endpoint.schema.tags) && (
                  <Title>Resource Details</Title>
                )}
                {schema.schemes && (
                  <EndpointDetail>
                    <EndpointDetailTitle>Security</EndpointDetailTitle>
                    <EndpointDetailContent>
                      {schema.schemes.join(' ')}
                    </EndpointDetailContent>
                  </EndpointDetail>
                )}
                {endpoint.schema.tags && (
                  <EndpointDetail>
                    <EndpointDetailTitle>Category</EndpointDetailTitle>
                    <EndpointDetailContent>
                      {endpoint.schema.tags.join(', ')}
                    </EndpointDetailContent>
                  </EndpointDetail>
                )}
              </InfoSection>
            </EndpointSection>

            <EndpointSection>
              <Title center>Request</Title>
              <ParametersSection>
                <ParameterTable
                  params={pathParams}
                  title="Path Parameters"
                  visible={pathParams.length}
                />
              </ParametersSection>
              <ParametersSection>
                <ParameterTable
                  classname="header-parameters"
                  params={headerParams}
                  title="Header Parameters"
                  visible={headerParams.length}
                />
              </ParametersSection>
              <ParametersSection>
                <ParameterTable
                  params={queryParams}
                  title="Query Parameters"
                  visible={queryParams.length}
                />
              </ParametersSection>
              <RequestBodySection
                endpoint={endpoint}
                schema={schema}
                bodyParam={bodyParam}
              />
            </EndpointSection>

            <EndpointSection>
              <ResponseBodySection endpoint={endpoint} schema={schema} />
              <Subtitle>Response Code Details</Subtitle>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeadItem>HTTP Code</TableHeadItem>
                    <TableHeadItem>Description</TableHeadItem>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {Object.keys(endpoint.schema.responses).map(key => (
                    <TableRow key={key}>
                      <TableItem small>{key}</TableItem>
                      <TableItem>
                        {endpoint.schema.responses[key].description}
                      </TableItem>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </EndpointSection>
          </React.Fragment>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}

export default ApiEndpoint;
