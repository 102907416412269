import { withAuth } from '@cdk-prod/fortellis-auth-context';
import React, { useContext } from 'react';
import './ApiDetails.scss';
import RedirectURL from '../common/RedirectURL';
import moment from 'moment';
import { locationContext } from '../locationContext';
import { IconOpenInNew } from 'cdk-radial';

const APIDetailsHeader = ({ title }) => {
  return <div className="api-details-title">{title}</div>;
};

const renderNoDocumentationTxt = () => {
  return (
    <div className="api-tab-container generic-style-for-browsers">
      <div className="no-documentation-text">
        The provider does not have any documentation available at this time.
      </div>
    </div>
  );
};

const ApiDocumentation = props => {
  const { setCurrentLocation } = useContext(locationContext);

  return (
    <div className="api-tab-container generic-style-for-browsers">
      <APIDetailsHeader title="API Documentation" />
      {props.auth.isAuthenticated ? (
        props.store.apiInfo &&
        props.store.apiInfo.data &&
        props.store.apiInfo.data.externalDocs &&
        props.store.apiInfo.data.externalDocs.length ? (
          props.store.apiInfo.data.externalDocs.map((doc, index) => {
            return (
              <>
                <RedirectURL
                  classes="api-doc-name api-redirect terms-and-agreements"
                  key={index}
                  url={doc.urlLink ? doc.urlLink : doc.url}
                  heading={doc.name}
                  icon={
                    <IconOpenInNew
                      height={16}
                      width={16}
                      style={{ fill: '#6B2574' }}
                    />
                  }
                />
                <div className="updated-date">
                  Updated:{' '}
                  {moment(doc.lastUpdated)
                    .utc()
                    .format('DD MMM YYYY hh:mm A')}
                </div>
              </>
            );
          })
        ) : (
          renderNoDocumentationTxt()
        )
      ) : (
        <div className="api-tab-container generic-style-for-browsers">
          <div className="no-documentation-text">
            <a
              className="cursor-pointer"
              onClick={() => {
                setCurrentLocation();
                props.auth.login();
              }}
            >
              Sign in
            </a>{' '}
            to view documentation.
          </div>
        </div>
      )}
    </div>
  );
};

export default withAuth(ApiDocumentation);
