import { withAuth } from '@cdk-prod/fortellis-auth-context';
import React, { Component } from 'react';
import { PrimaryButton } from 'cdk-radial';
import saveAs from 'file-saver';
import { Page } from '@fortellis/page';
import { Link } from 'react-router-dom';
import { Flex } from '@fortellis/flex';
import { CircularProgress } from '@fortellis/circular-progress';
import { capitalize } from '../../utils/spec-utils';
import { getIntitials } from '../../utils/format-data';
import ApiExplorer from '../apiExplorer';
import AsyncApiExplorer from '../apiExplorer/AsyncApiSpec.js';
import './ApiDetails.scss';
import {
  API_TYPES_DROPDOWN_OPTIONS,
  API_TYPES
} from '../api-directory-home/constants';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import config from '../../config/config';
import ApiPricing from './ApiPricing';
import ApiDocumentation from './ApiDocumentation';
import ApiSupport from './ApiSupport';
import ApiOverview from './ApiOverview';
import queryString from 'query-string';
import YAML from 'yaml';

let org, apiName;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

class ApiDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: this.findCurrentTab(),
      apiType: '',
      content: null
    };
  }
  /*check the URL for a query such as ?q=Documentation, if that matches one of the tabs, set the current tab to the corresponding number
    otherwise leave it as 0*/
  findCurrentTab() {
    if (!this.props.location) {
      return 0;
    }
    let query = queryString.parse(this.props.location.search);
    if (!query) {
      return 0;
    }
    let q = query.q;
    switch (q) {
      case 'Overview':
        return 0;
      case 'Specification':
        return 1;
      case 'Pricing':
        return 2;
      case 'Documentation':
        return 3;
      case 'Contacts':
        return 4;
      default:
        return 0;
    }
  }

  componentDidMount = () => {
    this.mounted = true;
    const state = this.props.location.state;
    setTimeout(() => {
      if (this.mounted) {
        this.setState({ ...state });
      }
    }, 100);
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.store &&
      nextProps.store.apiInfo &&
      nextProps.store.apiInfo.data &&
      nextProps.store.apiInfo.data.name
    ) {
      apiName = nextProps.store.apiInfo.data.name;
    }
    return {
      ...prevState,
      apiName: apiName
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.store.apiInfo.error) {
      if (this.props.store.apiInfo.data !== prevProps.store.apiInfo.data) {
        let parsedSpec = this.props.store.apiInfo.data;
        if (parsedSpec.tags && parsedSpec.tags.length) {
          this.props.getDomainDisplayName();
        }
      }
    }
  }

  getBreadcrumbs = apiName => {
    return [{ text: 'API Directory', url: '/' }, { text: apiName }];
  };

  getTabs = currentTab => {
    return (
      <div>
        <Tabs
          value={currentTab}
          onChange={(event, newValue) => {
            this.setState({ currentTab: newValue });
          }}
          indicatorColor="primary"
          textColor="primary"
          aria-label="full width tabs example"
        >
          <Tab
            label="Overview"
            id="full-width-tab-0"
            aria-controls="full-width-tabpanel-0"
          />
          <Tab
            label="Specification"
            id="full-width-tab-0"
            aria-controls="full-width-tabpanel-0"
          />
          <Tab
            label="Pricing"
            id="full-width-tab-1"
            aria-controls="full-width-tabpanel-1"
          />
          <Tab
            label="Documentation"
            id="full-width-tab-2"
            aria-controls="full-width-tabpanel-2"
          />
          <Tab
            label="Contacts"
            id="full-width-tab-3"
            aria-controls="full-width-tabpanel-3"
          />
        </Tabs>
      </div>
    );
  };

  getTabContent = (apiData, oneToOneApiAnon, currentTab, apiName) => {
    let apiType =
      this.props.store.apiInfo.data && this.props.store.apiInfo.data.kind
        ? this.props.store.apiInfo.data.kind
        : undefined;
    let descriptionContent =
      this.props.store.apiInfo.data && this.props.store.apiInfo.data.description
        ? this.props.store.apiInfo.data.description
        : undefined;

    const downloadSpecFile = specFile => {
      const doc = new YAML.Document();
      doc.contents = specFile;
      let blob = new Blob([doc], { type: 'text/plain;charset=utf-8' });
      const blobName = `${apiName}-${specFile.info.version}.yaml`;
      saveAs(blob, blobName);
    };

    return (
      <div>
        <TabPanel value={currentTab} index={0}>
          <ApiOverview
            {...this.props}
            content={apiData.description || descriptionContent}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          {apiType === API_TYPES.ASYNC ? (
            <AsyncApiExplorer
              api={apiData}
              oneToOneApiAnon={oneToOneApiAnon}
              downloadSpec={downloadSpecFile}
            />
          ) : (
            <ApiExplorer
              api={apiData}
              oneToOneApiAnon={oneToOneApiAnon}
              downloadSpec={downloadSpecFile}
              auth={this.props.auth}
            />
          )}
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          <ApiPricing
            {...this.props}
            apiType={apiType}
            changeToContactsTab={() => {
              this.setState({
                ...this.state,
                currentTab: 4
              });
            }}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={3}>
          <ApiDocumentation {...this.props} />
        </TabPanel>
        <TabPanel value={currentTab} index={4}>
          <ApiSupport
            {...this.props}
            orgName={apiData.orgName}
            apiName={this.state.apiName}
            apiType={apiType}
          />
        </TabPanel>
      </div>
    );
  };

  render() {
    const {
      store: { apiInfo, domainName }
    } = this.props;
    const apiId = this.props.match.params.id;

    let title = '';
    if (apiInfo.data) {
      title = apiInfo.data.name || '';
    }
    let domainDisplayNames;
    if (domainName && domainName.data) {
      domainDisplayNames = domainName.data;
    }

    let specError;
    if (typeof apiInfo.data === 'string') {
      try {
        let data = JSON.parse(apiInfo.data);
        if (data.length) {
          specError = data[0].error;
        } else {
          specError = data.error;
        }
      } catch {
        console.log('Failed to parse API Specification');
      }
    }

    if (apiInfo.error || specError) {
      return <div className="error-page error-title">{apiInfo.error}</div>;
    }

    return (
      <React.Fragment>
        {!apiInfo.isFetching && apiInfo.data && (
          <Page
            breadcrumbs={this.getBreadcrumbs(capitalize(title))}
            routerLink={Link}
          ></Page>
        )}
        {apiInfo.isFetching ? (
          <Flex align="center" justify="center">
            <div className="spinner">
              <CircularProgress />
            </div>
          </Flex>
        ) : apiInfo.data ? (
          <div>
            <ApiTitle
              apiId={apiId}
              orgId={apiInfo.data.orgId}
              apiName={title}
              tags={apiInfo.data.tags}
              domainDisplayNames={domainDisplayNames}
              publishedBy={apiInfo.data.orgName}
              asyncApi={apiInfo.data.kind === API_TYPES.ASYNC}
              orgLogo={apiInfo.data.orgLogo}
            />
            <div className="spec-details-tab-container">
              <div
                className={
                  this.state.currentTab === 0 ? 'overview-sticky' : null
                }
              >
                <AppBar
                  position="static"
                  className="app-bar"
                  color="white"
                  elevation={1}
                >
                  {this.getTabs(this.state.currentTab)}
                </AppBar>
              </div>
              {this.getTabContent(
                apiInfo.data,
                apiInfo.oneToOneApiAnon,
                this.state.currentTab,
                title
              )}
            </div>
          </div>
        ) : (
          <div className="error-page" />
        )}
      </React.Fragment>
    );
  }
}

const ApiTitle = ({
  apiId,
  orgId,
  apiName,
  domainDisplayNames,
  tags,
  publishedBy,
  asyncApi,
  orgLogo
}) => {
  const categories =
    domainDisplayNames &&
    domainDisplayNames.length &&
    domainDisplayNames
      .filter(approvedTag => tags.includes(approvedTag.id))
      .map(tag => {
        return {
          id: tag.id,
          name: tag.name
        };
      });
  return (
    <div className="api-description-container">
      <div className="api-header-container">
        <div className="api-header-top">
          {orgLogo ? (
            <img className="org-logo" src={orgLogo} />
          ) : (
            publishedBy && (
              <div className="org-default-logo">
                {' '}
                {getIntitials(publishedBy)}
              </div>
            )
          )}
          <div className="api-header">
            <div className="api-header-horizontal">
              <div className="api-title" data-cy="api-title">
                {apiName && capitalize(apiName)}
              </div>
            </div>

            <div className="api-subtitle-text">
              {asyncApi ? (
                <>
                  <span className="api-subtitle-text">API Type: </span>
                  <Link
                    to={{
                      pathname: '/',
                      state: {
                        selectedAPIType: API_TYPES_DROPDOWN_OPTIONS[1].value,
                        selectedDomainIds: [],
                        selectedOrgs: []
                      }
                    }}
                  >
                    <span className="published-by-text" data-cy="published-by">
                      ASYNC API
                    </span>
                  </Link>
                </>
              ) : (
                <>
                  <span className="api-subtitle-text">API Type: </span>
                  <Link
                    to={{
                      pathname: '/',
                      state: {
                        selectedAPIType: API_TYPES_DROPDOWN_OPTIONS[0].value,
                        selectedDomainIds: [],
                        selectedOrgs: []
                      }
                    }}
                  >
                    <span className="published-by-text" data-cy="published-by">
                      REST API
                    </span>
                  </Link>
                </>
              )}
              {publishedBy && (
                <>
                  <span className="separator">|</span>
                  <span className="api-subtitle-text">Publisher: </span>
                  <span className="published-by-text" data-cy="published-by">
                    {publishedBy}
                  </span>
                  {/* Below code block is being commented out to implement FORTDNP-1755 */}
                  {/* <Link
                    to={{
                      pathname: '/',
                      state: {
                        selectedAPIType: ['Async APIs', 'REST APIs'],
                        selectedDomainIds: [],
                        selectedOrgs: [orgId]
                      }
                    }}
                  >
                    <span className="published-by-text" data-cy="published-by">
                      {publishedBy}
                    </span>
                  </Link> */}
                </>
              )}
              {!!categories && !!categories.length && (
                <>
                  <span className="separator">|</span>
                  <span className="api-subtitle-text">Categories : </span>
                  {categories.map(category => (
                    <Link
                      to={{
                        pathname: '/',
                        state: {
                          selectedAPIType: ['Async APIs', 'REST APIs'],
                          selectedDomainIds: [category.id],
                          selectedOrgs: []
                        }
                      }}
                    >
                      <span className="api-domain-text" data-cy="api-domain">
                        {category.name}
                        {categories[categories.length - 1].id !== category.id &&
                          ', '}
                      </span>
                    </Link>
                  ))}
                </>
              )}
            </div>
          </div>

          <div className="api-directory__add-api-link">
            <a
              href={`${config.developer.url}developer-account/solutions/register?api=${apiId}`}
            >
              <PrimaryButton
                dataTestId="api-directory__add-api-link"
                iconColor="primary"
                text="Build with this API"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuth(ApiDetails);
