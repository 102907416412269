import React, { Component } from 'react';
import request from 'request-promise-native';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import config from '../../config/config';
import ApiDetails from './ApiDetails';
import { getAnonymousURL, getAuthHeader } from '../../utils/service-utils';
import { types, INITIAL_STATE } from './constants';
import reducer from './ApiDetailsReducer';
import { withEntityContext } from '@cdk-prod/fortellis-entity-context';
import { Flex } from '@fortellis/flex';
import { CircularProgress } from '@fortellis/circular-progress';
const REGISTERED = 'registered';

function withContainer(WrappedComponent) {
  return withEntityContext(
    class WithContainerComponent extends Component {
      constructor(props) {
        super(props);
        this.mounted = false;
        this.state = { ...INITIAL_STATE };
      }

      componentDidUpdate(prevProps, prevState) {
        const {
          auth: { loginPending }
        } = this.props;

        if (!loginPending && !this.state.isAuthenticated) {
          this.setState({ isAuthenticated: true });
          this.getApiInfo(this.props.match.params.id);
        }
      }

      componentDidMount() {
        this.mounted = true;
        setTimeout(() => {
          this.getApiInfo(this.props.match.params.id);
        }, 300);
      }

      /* STORE API FUNCTIONS */
      dispatch = action => {
        if (this.mounted) {
          this.setState(prevState => reducer(prevState, action));
        }
      };

      thunk = (options, actions, args = {}, callback) => {
        this.dispatch({ type: actions[0], args });
        request({ ...options, json: true })
          .then(response => {
            this.dispatch({ type: actions[1], response, args });
            if (callback) {
              callback(undefined, response);
            }
          })
          .catch(error => {
            const response = error.error;
            if (response) {
              error = response;
            }
            this.dispatch({ type: actions[2], error, args });
            if (callback) {
              callback(error);
            }
          });
      };
      /* END STORE API FUNCTIONS */

      getApiInfo = apiId => {
        const apiGatewayUrl = getAnonymousURL(
          this.props.auth,
          config.api.apiGatewayUrlV2
        );
        const asyncApiServiceBaseUrl = getAnonymousURL(
          this.props.auth,
          `${config.api.asyncApiServiceBaseUrl}/v2`
        );
        const url =
          this.props.match.path === '/apis/:id'
            ? `${apiGatewayUrl}/directory/apis/${apiId}`
            : `${asyncApiServiceBaseUrl}/directory/async-apis/${apiId}`;
        const options = {
          method: 'GET',
          url: url,
          headers: { ...getAuthHeader(this.props.auth) }
        };
        this.thunk(
          options,
          [
            types.API_INFO_GET_REQUEST,
            types.API_INFO_GET_RECEIVE,
            types.API_INFO_GET_ERROR
          ],
          {},
          (undefined, response) => {
            if (response) {
              this.dispatch({
                type: types.API_INFO_GET_RECEIVE,
                response,
                oneToOneApiAnon: true
              });
            }
          }
        );
      };

      getDomainDisplayName = () => {
        let domainUrl = getAnonymousURL(this.props.auth, config.api.domainUrl);
        if (this.props.auth.isAuthenticated) {
          domainUrl += '/directory/groups';
        } else {
          domainUrl += `/v1/domains`;
        }
        const options = {
          method: 'GET',
          url: `${domainUrl}`,
          headers: { ...getAuthHeader(this.props.auth) }
        };
        this.thunk(options, [
          types.DOMAIN_NAME_GET_REQUEST,
          types.DOMAIN_NAME_GET_RECEIVE,
          types.DOMAIN_NAME_GET_ERROR
        ]);
      };

      sendEmailToProvider = payload => {
        const options = {
          method: 'POST',
          url: config.api.emailDispatcherUrl,
          headers: { ...getAuthHeader(this.props.auth) },
          body: payload,
          json: true
        };
        this.thunk(options, [
          types.SEND_EMAIL_TO_PROVIDER_GET_REQUEST,
          types.SEND_EMAIL_TO_PROVIDER_GET_RECEIVE,
          types.SEND_EMAIL_TO_PROVIDER_GET_ERROR
        ]);
      };

      sendEmailToConsumer = payload => {
        const options = {
          method: 'POST',
          url: config.api.emailDispatcherUrl,
          headers: { ...getAuthHeader(this.props.auth) },
          body: payload,
          json: true
        };
        this.thunk(options, [
          types.SEND_EMAIL_TO_CONSUMER_GET_REQUEST,
          types.SEND_EMAIL_TO_CONSUMER_GET_RECEIVE,
          types.SEND_EMAIL_TO_CONSUMER_GET_ERROR
        ]);
      };

      render() {
        return (
          <React.Fragment>
            <WrappedComponent
              store={this.state}
              getApiInfo={this.getApiInfo}
              getDomainDisplayName={this.getDomainDisplayName}
              getProviders={this.getProviders}
              getProviderRatePlans={this.getProviderRatePlans}
              sendEmailToProvider={this.sendEmailToProvider}
              sendEmailToConsumer={this.sendEmailToConsumer}
              {...this.props}
            />
          </React.Fragment>
        );
      }
    }
  );
}

export default withAuth(withContainer(withEntityContext(ApiDetails)));
