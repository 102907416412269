import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { withEntityContext } from '@cdk-prod/fortellis-entity-context';
import { Header } from '@fortellis/header';
import config from '../../config/config';
import './header.scss';
import { LocaleConsumer } from '@fortellis/locale/dist/LocaleContext';
import { locationContext } from '../locationContext';

function AuthHeader({ auth, entityContext, location, mobile }) {
  const { setCurrentLocation } = useContext(locationContext);

  return (
    <LocaleConsumer>
      {localeContext => (
        <Header
          mobile={mobile}
          authenticated={auth.isAuthenticated}
          entityContext={entityContext}
          localeContext={localeContext}
          login={() => {
            setCurrentLocation();
            auth.login();
          }}
          logout={auth.logout}
          nav={config.nav}
          routerLink={Link}
          selectedKey="apis"
          signup={config.signup.url}
          user={auth.userData}
        />
      )}
    </LocaleConsumer>
  );
}

AuthHeader.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(withAuth(withEntityContext(AuthHeader)));
