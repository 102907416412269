/**
 *  Utitilies for manipulating the spec
 */

const SwaggerParser = require('swagger-parser');
/**
 * Merges any path level parameters into all the invididual operation parameters
 * @param {Object} spec
 */
const mergePathBaseParameters = spec => {
  // Parameters at the path level apply to every operation, but can be overridden at the operation level
  for (const [, pathVal] of Object.entries(spec.paths || [])) {
    for (const [operationKey, operationVal] of Object.entries(pathVal || [])) {
      // Loop through the operations (GET, POST, etc). Only "parameters" and operation keys are allowed at the path level in OpenAPI2.
      if (operationKey !== 'parameters') {
        for (const baseParameter of pathVal.parameters || []) {
          if (
            operationVal.parameters &&
            !operationVal.parameters.includes(baseParameter.name)
          ) {
            operationVal.parameters.push(baseParameter);
          }
        }
      }
    }
    delete pathVal.parameters;
  }
  return spec;
};

// Taken from: https://github.com/moll/json-stringify-safe/blob/master/stringify.js
const safeStringify = (obj, replacer, spaces, cycleReplacer) => {
  return JSON.stringify(obj, serializer(replacer, cycleReplacer), spaces);
};

function serializer(replacer, cycleReplacer) {
  var stack = [],
    keys = [];

  if (cycleReplacer == null) {
    cycleReplacer = function(key, value) {
      if (stack[0] === value) {
        return '[Circular ~]';
      }
      return (
        '[Circular ~.' + keys.slice(0, stack.indexOf(value)).join('.') + ']'
      );
    };
  }

  return function(key, value) {
    if (stack.length > 0) {
      var thisPos = stack.indexOf(this);
      ~thisPos ? stack.splice(thisPos + 1) : stack.push(this);
      ~thisPos ? keys.splice(thisPos, Infinity, key) : keys.push(key);
      if (~stack.indexOf(value)) {
        value = cycleReplacer.call(this, key, value);
      }
    } else {
      stack.push(value);
    }

    return replacer == null ? value : replacer.call(this, key, value);
  };
}
/**
 * Takes in a spec as a string and performs multiple operations for easier manipulation.
 *
 * Handles parsing JSON and YAML, dereferencing, circular references, path base parameters, merging allOfs
 *
 * @param {String | Object} spec
 */
const prepareSpec = async spec => {
  try {
    const convertCircularRefs = obj => JSON.parse(safeStringify(obj));

    let parsedSpec = spec;
    if (typeof spec === 'string') {
      parsedSpec = await SwaggerParser.YAML.parse(spec);
    }
    const dereferencedSpec = await SwaggerParser.dereference(parsedSpec);
    const processedSpec = convertCircularRefs(
      mergePathBaseParameters(dereferencedSpec)
    );
    return processedSpec;
  } catch {
    console.log('Failed to process the spec.');
    return spec;
  }
};

/**
 * Utilities for manipulating strings
 */

const capitalize = (str = '') =>
  str && str.length >= 2 && str.charAt(1) !== str.charAt(1).toUpperCase()
    ? str.charAt(0).toUpperCase() + str.slice(1)
    : str;

/**
 * Converts camel case strings to pascal case with spaces.
 * @param {String} prop
 */
const cleanName = prop => {
  let cleanedProp = prop;

  // https://stackoverflow.com/a/35362661
  let unCamelCase = camelCase => {
    if (!camelCase) {
      return '';
    }

    var pascalCase = capitalize(camelCase);
    return pascalCase
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
      .replace(/([a-u][w-z])([0-9])/gi, '$1 $2')
      .replace(/([0-9])([a-z])/gi, '$1 $2');
  };

  cleanedProp = unCamelCase(prop).replace(/_|-/g, ' - ');

  return cleanedProp;
};

/**
 * Replaces parameters in a schema by name. Adds the new parameters if they were not already there.
 *
 * @param {Array} parameters
 * @param {Array} paramsToBeReplaced
 *
 * @returns A new array of parameters with the replaced parameters
 */
const replaceParameters = (parameters = [], paramsToBeReplaced = []) => {
  if (!parameters.length || !paramsToBeReplaced.length) {
    return parameters;
  }

  const newParameters = parameters.filter(e =>
    paramsToBeReplaced.every(p => p.name.toLowerCase() !== e.name.toLowerCase())
  );
  for (const replacementParam of paramsToBeReplaced) {
    newParameters.push(replacementParam);
  }
  return newParameters;
};

const addEndpointToPaths = (paths = {}, endpoints = []) => {
  if (!Object.keys(paths).length || !endpoints.length) {
    return paths;
  }

  return {
    ...paths,
    ...endpoints.reduce((obj, e) => {
      obj[e.path] = e.value;
      return obj;
    }, {})
  };
};

const getInstanceIdVersionMap = apiInfo => {
  const specEnv = apiInfo?.data?.environments?.find(
    f => f.name === 'prod' || f.name.toLowerCase() === 'production'
  );
  let spec = [];
  if (!!specEnv?.instances && Array.isArray(specEnv.instances)) {
    specEnv.instances.forEach(f => {
      spec = [
        ...spec,
        {
          apiInstanceId: f.id,
          version: f.version
        }
      ];
    });
  }
  return spec;
};

const getPropertyType = property => {
  const proObj = {};
  if (property?.items) {
    return [getPropertyType(property.items)];
  } else if (property?.properties) {
    Object.keys(property.properties).forEach(f => {
      proObj[f] = getPropertyType(property.properties[f]);
    });
  } else {
    return property?.example || property?.type;
  }
  return proObj;
};

const getEnvironmentName = environments => {
  const envs = [];
  const envNames = environments
    .filter(f => f?.name?.toUpperCase() !== 'DEV')
    .map(m => m.name);
  ['P', 'T'].map(
    m =>
      envNames.find(f => f[0].toUpperCase().indexOf(m) > -1) &&
      envs.push(envNames.find(f => f[0].toUpperCase().indexOf(m) > -1))
  );
  return envs[0];
};

const generateRandomInteger = length => {
  let result = '';
  for (let i = 0; i < length; i++) {
    result += Math.floor(Math.random() * 10);
  }
  return result;
};

const generateRandomString = length => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const generateRandomUUID = _ => {
  return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, c => {
    const r = Math.floor(Math.random() * 16);
    return r.toString(16);
  });
};

const generators = {
  integer: () => generateRandomInteger(Math.floor(Math.random() * 6) + 1),
  boolean: () => Boolean(Math.round(Math.random())),
  number: () => Math.random() * 10000,
  string: () => generateRandomString(Math.floor(Math.random() * 10) + 1),
  uuid: () => generateRandomUUID()
};

const getRandomValueFromType = dataType => {
  return generators[dataType] ? generators[dataType]() : '';
};

const getExampleFromProperty = specResp => {
  const proObj = {};
  if (specResp?.items) {
    return [getExampleFromProperty(specResp.items)];
  } else if (specResp?.properties) {
    Object.keys(specResp.properties).forEach(f => {
      proObj[f] = getExampleFromProperty(specResp.properties[f]);
    });
  } else {
    return (
      specResp?.example ||
      specResp?.examples ||
      getRandomValueFromType(specResp?.type)
    );
  }

  return proObj;
};

const getDataAndExample = specResp => {
  let data, example;
  const retData = [];

  if (specResp?.properties || specResp?.items) {
    data = specResp?.properties || specResp?.items;
    example =
      specResp?.example ||
      specResp?.examples ||
      getExampleFromProperty(specResp);
    retData.push({ data, example });
  } else if (specResp) {
    Object.keys(specResp).forEach(rKey => {
      if (typeof specResp[rKey] === 'object') {
        const exampleData = getDataAndExample(specResp[rKey]);
        Array.isArray(exampleData) &&
          exampleData.length > 0 &&
          exampleData[0]?.['example'] &&
          retData.push(...exampleData);
      }
    });
  }

  return retData;
};

export const getRequestObjectArr = (schema, endpoint, verb) => {
  let reqObjArr = [];
  const specParam = schema.paths[endpoint][verb]['parameters'];
  const reqBody = schema.paths[endpoint][verb]['requestBody'];

  !!specParam &&
    Object.keys(specParam).forEach(reqParam => {
      reqObjArr.push({
        ...specParam[reqParam],
        example:
          specParam[reqParam]?.example ||
          specParam[reqParam]?.examples ||
          getRandomValueFromType(
            specParam[reqParam].type || specParam[reqParam]?.schema?.type
          )
      });
    });

  if (!reqObjArr.find(f => f.in === 'body') && !!reqBody) {
    let example, data;
    const exampleData = getDataAndExample(reqBody);
    if (Array.isArray(exampleData) && exampleData.length > 0) {
      ({ example, data } = exampleData[0]);
    }
    reqObjArr.push({
      schema: {
        properties: data?.properties,
        example: data?.example || data?.examples || example
      },
      in: 'body'
    });
  }

  return reqObjArr;
};

const getSpecObj = schema => {
  const specObj = [];
  Object.keys(schema.paths).forEach(ep => {
    Object.keys(schema.paths[ep]).forEach(verb => {
      const respObjArr = [];
      const reqObjArr = getRequestObjectArr(schema, ep, verb);
      const specResp = schema.paths[ep][verb]['responses'];
      const specOpId = cleanName(schema.paths[ep][verb]['operationId']);

      !!specResp &&
        Object.keys(specResp).forEach(httpCode => {
          let example, data;
          const exampleData = getDataAndExample(specResp[httpCode]);
          if (Array.isArray(exampleData) && exampleData.length > 0) {
            ({ example, data } = exampleData[0]);
          }
          respObjArr.push({
            statusCode: httpCode,
            example,
            data
          });
        });

      specObj.push({
        endpoint: ep,
        verb: verb,
        description: specOpId,
        request: reqObjArr,
        response: respObjArr
      });
    });
  });
  return specObj;
};

const getValidationStatus = (
  value,
  isRequiredStatus,
  dataType,
  dataFormat,
  dataEnum
) => {
  if (!value && !isRequiredStatus) {
    return true;
  }
  return getValidatedDataTypeStatus(value, dataType, dataFormat, dataEnum);
};

export const getValidatedDataTypeStatus = (
  value,
  dataType,
  dataFormat,
  dataEnum
) => {
  if (!value) {
    return false;
  }
  const locValue = value.toString().toLowerCase();
  let locDataType = typeof dataType === 'string' ? dataType?.toLowerCase() : '';

  if (locDataType === 'string') {
    locDataType = !!dataFormat
      ? dataFormat
      : Array.isArray(dataEnum)
      ? 'enum'
      : locDataType;
  }

  switch (locDataType.toLowerCase()) {
    case 'integer':
      return !!locValue ? Number.isInteger(Number(locValue)) : false;
    case 'number':
      return !isNaN(Number(locValue));
    case 'boolean':
      return locValue === 'true' || locValue === 'false';
    case 'date':
    case 'datetime':
    case 'date-time':
      return !isNaN(new Date(locValue));
    case 'uuid':
      return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
        locValue
      );
    case 'string':
      return !!locDataType;
    case 'enum':
      return dataEnum.includes(value);
    case 'email':
      return /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(
        locValue
      );
    default:
      return true;
  }
};

const getRequestValidationErrors = (schema, example) => {
  return getErrorsInReqBody(getSchemaDetails(schema), example).flat(Infinity);
};

export const getSchemaDetails = (specReq, key = '', reqArr = []) => {
  const proObj = {};
  if (specReq?.items) {
    let propArr = [getSchemaDetails(specReq.items)];
    if (Array.isArray(reqArr) && reqArr.length > 0 && !!key) {
      propArr = [
        ...propArr,
        { cdk_fortellis_required: reqArr.indexOf(key) > -1 }
      ];
    }
    return propArr;
  } else if (specReq?.properties) {
    Object.keys(specReq.properties).forEach(f => {
      proObj[f] = getSchemaDetails(specReq.properties[f], f, specReq?.required);
      if (
        Array.isArray(specReq?.required) &&
        specReq?.required.length > 0 &&
        !specReq.properties[f]?.items
      ) {
        proObj[f]['cdk_fortellis_required'] = specReq?.required.indexOf(f) > -1;
      }
    });
  } else {
    return specReq;
  }

  return proObj;
};

export const getErrorsInReqBody = (schema, example, schemaPath = '') => {
  let errors = [];
  const outerSchemaPath = !!schemaPath ? `${schemaPath}->` : schemaPath;

  Object.keys(schema).forEach(key => {
    if (Array.isArray(schema[key])) {
      const locRequired = schema[key].find(f =>
        f.hasOwnProperty('cdk_fortellis_required')
      );
      if (
        !!locRequired &&
        locRequired['cdk_fortellis_required'] &&
        (!example || !example.hasOwnProperty(key))
      ) {
        errors.push(`'${outerSchemaPath}${key}' is a required field`);
      } else {
        const keySchema = schema[key].filter(
          f => !f.hasOwnProperty('cdk_fortellis_required')
        );
        const locExample = !!example && example[key] ? example[key] : undefined;

        errors = [
          ...errors,
          getErrorsInReqBody(keySchema, locExample, `${outerSchemaPath}${key}`)
        ];
      }
    } else if (
      schema[key]?.['cdk_fortellis_required'] &&
      (!example || !example.hasOwnProperty(key))
    ) {
      errors.push(`'${outerSchemaPath}${key}' is a required field`);
    } else if (
      (schema[key]?.['type'] && typeof schema[key]?.['type'] === 'string') ||
      (schema[key]?.['schema']?.['type'] &&
        typeof schema[key]?.['schema']?.['type'] === 'string')
    ) {
      const locExample = !!example && example[key] ? example[key] : undefined;
      if (locExample) {
        errors = [
          ...errors,
          getErrorFromData(key, schema[key], locExample, schemaPath)
        ];
      }
    } else {
      if (Array.isArray(example) && example.length > 1) {
        example.forEach((item, index) => {
          errors = [
            ...errors,
            getErrorsInReqBody(schema[key], item, `${schemaPath}[${index}]`)
          ];
        });
      } else {
        const locSchemaPath = Array.isArray(schema)
          ? `${schemaPath}[${key}]`
          : `${outerSchemaPath}${key}`;
        const locExample = !!example && example[key] ? example[key] : undefined;

        errors = [
          ...errors,
          getErrorsInReqBody(schema[key], locExample, locSchemaPath)
        ];
      }
    }
  });
  return errors.filter(f => f);
};

export const getErrorFromData = (key, schema, example, schemaPath) => {
  let err = '';
  const outerSchemaPath = !!schemaPath ? `${schemaPath}->` : schemaPath;
  if (!example) {
    err = `'${outerSchemaPath}${key}' field cannot be empty/null value`;
  } else {
    const dataType = schema?.['type'] || schema?.['schema']?.['type'];
    const dataFormat = schema?.['format'] || schema?.['schema']?.['format'];
    const dataEnum = schema?.['enum'] || schema?.['schema']?.['enum'];

    if (!getValidatedDataTypeStatus(example, dataType, dataFormat, dataEnum)) {
      err = `'${outerSchemaPath}${key}' does not have the required format`;
    }
  }
  return err;
};

export const getResponses = (endpointContent, isExecuted) => {
  let responses = [];
  if (Array.isArray(endpointContent?.response)) {
    responses = endpointContent.response;
    if (isExecuted) {
      const minStatusCode = Math.min(
        ...endpointContent.response
          .filter(f => Number.isInteger(Number(f?.['statusCode'])))
          .map(m => +m.statusCode)
      );
      responses = endpointContent.response.filter(
        res => res.statusCode === minStatusCode.toString()
      );
    }
  }

  return responses;
};

export {
  prepareSpec,
  cleanName,
  capitalize,
  replaceParameters,
  addEndpointToPaths,
  getInstanceIdVersionMap,
  getPropertyType,
  getEnvironmentName,
  getSpecObj,
  getValidationStatus,
  getRandomValueFromType,
  generateRandomInteger,
  generateRandomString,
  generateRandomUUID,
  getExampleFromProperty,
  getDataAndExample,
  getRequestValidationErrors
};
