import React, { Component } from 'react';
import Cookies from 'universal-cookie';
// Variables
const consts = {
  COOKIE_PATH: '/',
  ENTITY_ID_KEY: 'entityId',
  COOKIE_EXP_IN_SECONDS: 86400,
  COOKIE_DOMAIN: '.fortellis.io'
};
const EntityContext = React.createContext({
  entityId: ''
});

class EntityProvider extends Component {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.state = {
      entityId: ''
    };
  }

  componentDidMount() {
    this.updateEntityId();
    this.cookies.addChangeListener(this.updateEntityId);
  }

  componentWillUnmount() {
    this.cookies.removeChangeListener(this.updateEntityId);
  }

  updateEntityId = () => {
    const entityId = this.cookies.get(consts.ENTITY_ID_KEY);
    if (!entityId) return;
    this.setState({ entityId });
  };

  setEntityId = entityId => {
    this.cookies.set(consts.ENTITY_ID_KEY, entityId, {
      secure: true,
      domain: consts.COOKIE_DOMAIN,
      maxAge: consts.COOKIE_EXP_IN_SECONDS,
      path: consts.COOKIE_PATH
    });
    if (this.cookies.get(consts.ENTITY_ID_KEY) === entityId) {
      window.location.reload();
    }
  };

  render() {
    return (
      <EntityContext.Provider
        value={{
          entityId: this.state.entityId,
          setEntityId: this.setEntityId
        }}
      >
        {this.props.children}
      </EntityContext.Provider>
    );
  }
}

function withEntityContext(WrappedComponent) {
  return props => {
    return (
      <EntityContext.Consumer>
        {value => <WrappedComponent entityContext={value} {...props} />}
      </EntityContext.Consumer>
    );
  };
}

export { EntityProvider, withEntityContext };
