import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import config from '../../config/config';

export class SpecsNotAccessible extends React.Component {
  redirect = () => {
    this.props.auth.login({ return_url: config.signup.url });
    return null;
  };

  render() {
    return this.props.auth.isAuthenticated ? (
      <div>
        <div className="max-width-resp specs-not-accessible-container">
          <div className="unauthorized-page-content-text">
            <FormattedMessage
              id="SpecsNotAccessible.message1"
              defaultMessage="Contact "
            />
            <a href={'mailto:support@fortellis.io'} className="fortellis-link">
              {'support@fortellis.io'}&nbsp;
            </a>
            <FormattedMessage
              id="SpecsNotAccessible.message2"
              defaultMessage="to access this private Spec."
            />
          </div>
        </div>
      </div>
    ) : (
      this.redirect()
    );
  }
}

SpecsNotAccessible = injectIntl(SpecsNotAccessible);

export default withAuth(SpecsNotAccessible);
