import { types, INITIAL_STATE } from '../constants';

export function combineReducers(reducers) {
  const keys = Object.keys(reducers).filter(key => {
    return typeof reducers[key] === 'function';
  });

  return function masterReducer(state = INITIAL_STATE, action) {
    if (action && action.type) {
      const newState = { ...state };
      keys.forEach(key => {
        const previousStateForKey = newState[key];
        const newStateForKey = reducers[key](previousStateForKey, action);
        if (previousStateForKey !== newStateForKey) {
          newState[key] = newStateForKey;
        }
      });
      return newState;
    }
    throw new Error('Action is invalid!');
  };
}

function domainsReducer(state = {}, action) {
  switch (action.type) {
    case types.GET_DOMAINS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_DOMAINS_RECEIVE:
      return {
        ...state,
        data: action.response,
        loading: false
      };
    case types.GET_DOMAINS_ERROR:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

function apiReducer(state = {}, action) {
  switch (action.type) {
    case types.GET_APIS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_APIS_RECEIVE:
      return {
        ...state,
        data: action.response.items,
        // orgData: action.response.orgs,
        bundles: action.response.bundles,
        loading: false
      };
    case types.GET_APIS_ERROR:
      return {
        ...state,
        loading: false
      };
    case types.GET_API_PAGES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_API_PAGES_RECEIVE:
      return {
        ...state,
        data: [...state.data, ...action.response.items],
        loading: false
      };
    case types.GET_API_PAGES_ERROR:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

function asyncApiReducer(state = {}, action) {
  switch (action.type) {
    case types.GET_ASYNC_APIS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_ASYNC_APIS_RECEIVE:
      return {
        ...state,
        data: action.response.items,
        loading: false
      };
    case types.GET_ASYNC_APIS_ERROR:
      return {
        ...state,
        loading: false
      };
    case types.GET_ASYNC_API_PAGES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_ASYNC_API_PAGES_RECEIVE:
      return {
        ...state,
        data: [...state.data, ...action.response.items],
        loading: false
      };
    case types.GET_ASYNC_API_PAGES_ERROR:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

export default combineReducers({
  domains: domainsReducer,
  apis: apiReducer,
  asyncApis: asyncApiReducer
});
