import React, { useEffect, useState } from 'react';
import { CodeBody, Subtitle } from '../styledComponents';
import SchemaStructure from './SchemaStructure';
import CodeHighlight from './CodeHighlight';
import { BODY_TYPES } from './constants';
import { getPropertyType } from '../../../utils/spec-utils';

export const RequestBodySection = ({ endpoint, schema, bodyParam }) => {
  const [requestBodyExample, setRequestBodyExample] = useState({});
  const [requestBodyStructure, setRequestBodyStructure] = useState([]);

  useEffect(() => {
    if (!!schema) {
      const isOas3Spec = !!schema?.openapi && schema.openapi.indexOf('3') > -1;

      if (isOas3Spec) {
        let reqBodyExample;
        const requestExampleParent = endpoint.schema?.requestBody?.content
          ? Object.keys(endpoint.schema?.requestBody?.content)[0]
          : [];

        const reqBodyStructure =
          endpoint.schema?.requestBody?.content[requestExampleParent]?.schema
            ?.items ||
          endpoint.schema?.requestBody?.content[requestExampleParent]?.schema;

        reqBodyExample =
          endpoint.schema?.requestBody?.content[requestExampleParent]?.schema
            ?.items?.example ||
          endpoint.schema?.requestBody?.content[requestExampleParent]?.schema
            ?.example;

        reqBodyExample =
          !!reqBodyStructure && !reqBodyExample
            ? getPropertyType(reqBodyStructure)
            : reqBodyExample;
        setRequestBodyStructure(reqBodyStructure);
        setRequestBodyExample(reqBodyExample);
      } else {
        const reqBodyStructure = bodyParam?.schema;
        const reqBodyExample = bodyParam?.schema?.example;

        setRequestBodyStructure(reqBodyStructure);
        setRequestBodyExample(reqBodyExample);
      }
    }
  }, [schema, endpoint]);

  return (
    <>
      {requestBodyStructure && Object.keys(requestBodyStructure).length > 0 && (
        <>
          <Subtitle>Request Body Structure</Subtitle>
          <SchemaStructure
            structure={'Request'}
            schema={requestBodyStructure}
            type={BODY_TYPES.request}
          />
        </>
      )}
      {requestBodyExample && (
        <>
          <Subtitle>Request Body Example</Subtitle>
          <CodeBody data-cy={`apiexplorer-param-body`}>
            <CodeHighlight language="json">
              {JSON.stringify(requestBodyExample, null, 4)}
            </CodeHighlight>
          </CodeBody>
        </>
      )}
    </>
  );
};
