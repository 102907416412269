import React, { Component } from 'react';
import request from 'request-promise-native';
import { withAuth } from '@cdk-prod/fortellis-auth-context';

import APIDirectory from './APIDirectory';
import { getAnonymousURL, getAuthHeader } from '../../../utils/service-utils';
import { types, INITIAL_STATE } from '../constants';
import reducer from './reducer';
//import { withEntityContext } from '../../entity-context/entityContext';
import { withEntityContext } from '@cdk-prod/fortellis-entity-context';
import config from '../../../config/config';
import { CircularProgress } from '@fortellis/circular-progress';
import { Flex } from '@fortellis/flex';

const APIS_PAGE_SIZE = 100;

function withContainer(WrappedComponent) {
  return withEntityContext(
    class WithContainerComponent extends Component {
      constructor(props) {
        super(props);
        this.mounted = false;
        this.state = { ...INITIAL_STATE, isAuthenticated: false };
      }

      componentDidMount() {
        this.mounted = true;
        setTimeout(() => {
          this.getApis();
          this.getAsyncApis();
          this.getDomains();
        }, 300);
      }

      componentDidUpdate() {
        const {
          auth: { loginPending }
        } = this.props;
        if (!loginPending && !this.state.isAuthenticated) {
          this.setState({ isAuthenticated: true });
        }
      }

      /* STORE API FUNCTIONS */
      dispatch = action => {
        if (this.mounted) {
          this.setState(prevState => reducer(prevState, action));
        }
      };

      thunk = (options, actions, args = {}, callback) => {
        this.dispatch({ type: actions[0], args });
        request(options)
          .then(response => {
            this.dispatch({ type: actions[1], response, args });
            if (callback) {
              callback(undefined, response);
            }
          })
          .catch(error => {
            this.dispatch({ type: actions[2], error, args });
            if (callback) {
              callback(error);
            }
          });
      };
      /* END STORE API FUNCTIONS */

      getDomains = () => {
        let domainUrl = getAnonymousURL(
          this.props.auth,
          ` ${config.api.domainUrl}`
        );
        if (this.props.auth.isAuthenticated) {
          domainUrl += '/directory/groups';
        } else {
          domainUrl += '/v1/domains';
        }
        const options = {
          method: 'GET',
          url: `${domainUrl}`,
          headers: { ...getAuthHeader(this.props.auth) },
          json: true
        };

        this.thunk(options, [
          types.GET_DOMAINS_REQUEST,
          types.GET_DOMAINS_RECEIVE,
          types.GET_DOMAINS_ERROR
        ]);
      };

      getApis = () => {
        let apiGatewayURL = getAnonymousURL(
          this.props.auth,
          config.api.apiGatewayUrlV2
        );
        const options = {
          method: 'GET',
          url: `${apiGatewayURL}/directory`,
          headers: { ...getAuthHeader(this.props.auth) },
          json: true,
          qs: {
            view: 'summary',
            skipApisWithoutViewableSpecs: true,
            pageSize: APIS_PAGE_SIZE
          }
        };
        this.thunk(
          options,
          [
            types.GET_APIS_REQUEST,
            types.GET_APIS_RECEIVE,
            types.GET_APIS_ERROR
          ],
          {},
          (_, response) => {
            const totalPages = response.totalPages;
            if (totalPages > 1) {
              this.getApiPages(totalPages, 2);
            }
          }
        );
      };

      getApiPages = (totalPages, currentPage = 1) => {
        const apiGatewayURL = getAnonymousURL(
          this.props.auth,
          config.api.apiGatewayUrlV2
        );
        for (let currPage = currentPage; currPage <= totalPages; currPage++) {
          const options = {
            method: 'GET',
            url: `${apiGatewayURL}/directory`,
            headers: { ...getAuthHeader(this.props.auth) },
            json: true,
            qs: {
              view: 'summary',
              skipApisWithoutViewableSpecs: true,
              page: currPage,
              pageSize: APIS_PAGE_SIZE
            }
          };
          this.thunk(options, [
            types.GET_API_PAGES_REQUEST,
            types.GET_API_PAGES_RECEIVE,
            types.GET_API_PAGES_ERROR
          ]);
        }
      };

      getAsyncApiPages = (totalPages, currentPage = 1) => {
        const asyncApiServiceBaseUrl = getAnonymousURL(
          this.props.auth,
          `${config.api.asyncApiServiceBaseUrl}/v2`
        );
        for (let currPage = currentPage; currPage <= totalPages; currPage++) {
          const options = {
            method: 'GET',
            url: `${asyncApiServiceBaseUrl}/directory/async-apis`,
            headers: { ...getAuthHeader(this.props.auth) },
            json: true,
            qs: {
              page: currPage,
              view: 'summary',
              pageSize: APIS_PAGE_SIZE
            }
          };
          this.thunk(options, [
            types.GET_ASYNC_API_PAGES_REQUEST,
            types.GET_ASYNC_API_PAGES_RECEIVE,
            types.GET_ASYNC_API_PAGES_ERROR
          ]);
        }
      };

      getAsyncApis = () => {
        const asyncApiServiceBaseUrl = getAnonymousURL(
          this.props.auth,
          `${config.api.asyncApiServiceBaseUrl}/v2`
        );
        const options = {
          method: 'GET',
          url: `${asyncApiServiceBaseUrl}/directory/async-apis`,
          headers: { ...getAuthHeader(this.props.auth) },
          json: true,
          qs: {
            view: 'summary',
            pageSize: APIS_PAGE_SIZE
          }
        };
        this.thunk(
          options,
          [
            types.GET_ASYNC_APIS_REQUEST,
            types.GET_ASYNC_APIS_RECEIVE,
            types.GET_ASYNC_APIS_ERROR
          ],
          {},
          (_, response) => {
            const totalPages = response.totalPages;
            if (totalPages > 1) {
              this.getAsyncApiPages(totalPages, 2);
            }
          }
        );
      };

      render() {
        return (
          <React.Fragment>
            <WrappedComponent
              store={this.state}
              getApis={this.getApis}
              getAsyncApis={this.getAsyncApis}
              getDomains={this.getDomains}
              {...this.props}
            />
          </React.Fragment>
        );
      }
    }
  );
}

export default withAuth(withContainer(APIDirectory));
